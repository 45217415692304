import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { FAQ } from '../models/faq.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class FAQService {
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getFAQs(): Observable<FAQ[]> {
    // Check if faq is cached from server
    const FAQS_KEY = makeStateKey<APIListResponse<Object>>('all-faqs');
    if (this._transferState.hasKey(FAQS_KEY)) {
      const menu = this._transferState.get<APIListResponse<Object>>(FAQS_KEY, null);
      this._transferState.remove(FAQS_KEY);
      return of(menu.results.map((s) => new FAQ(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/faqs/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genres
        if (!this._platform.isBrowser) {
          this._transferState.set(FAQS_KEY, res);
        }
      }),
      map((res) => res.results.map((s) => new FAQ(s)))
    );
  }
}
