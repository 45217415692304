import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { SWUpdateService } from './shared/services/sw-update.service';
import { PlayerService } from './shared/services/player.service';
import { ArtistService } from './shared/services/artist.service';
import { Track } from './shared/models/track.model';
import { TrackVersion } from './shared/models/trackversion.model';
import { fromEvent, Subscription } from 'rxjs';
import { User } from './shared/models/user.model';
import { UserService } from './shared/services/user.service';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { HomePageSlideService } from './shared/services/homepageslide.service';
import { DialogService } from './shared/services/dialog.service';
import { first } from 'rxjs/operators';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  isArtist = false;
  headerOpacity = 0;
  playingTrack: Track;
  playingTrackVersion: TrackVersion;
  musicPlayerCollapsed = false;
  private subscription: Subscription
  private _subscriptions: Subscription[] = [];
  constructor(
    private _ngZone: NgZone,
    private _platform: Platform,
    private _playerService: PlayerService,
    private _artistService: ArtistService,
    private userService: UserService,
    public route: ActivatedRoute,
    public _slideService: HomePageSlideService,
    public _dialogService: DialogService,
    private _swUpdateService: SWUpdateService // We have to load the update service to get it to run
  ) {}
  ngOnInit() {
    var remember = JSON.parse(localStorage.getItem('remember'))
    var status = localStorage.getItem('status')
   
    if(!status){
      if(remember === false){
        this.userService.logout()
        localStorage.clear()
        window.location.reload()
      }
     
    }
    

    var userDetails = JSON.parse(localStorage.getItem('user'))
    if(userDetails){
      let user  = new User(userDetails);
      this.userService._currentUserSubject.next(user);
    }
    
    this._subscriptions.push(
      this._artistService.currentUserArtistsStream.subscribe(a => {
        this.isArtist = !!a && !!a.length;
      })
    );
    // Listen for player changes
    this._subscriptions.push(
      this._playerService.currentlyPlaying.subscribe((p) => {
        this.playingTrack = null;
        this.playingTrackVersion = null;
        if (!p) { return; }
        this.playingTrack = p.track;
        this.playingTrackVersion = p.version;
      })
    );
    // Handle header background while scrolling page
    if (!this._platform.isBrowser) { return; }
    this._ngZone.runOutsideAngular(() => {
      fromEvent(window.document, 'scroll').subscribe(_ => {
        if (window.pageYOffset <= 0 && this.headerOpacity == 0) {
          return;
        }
        if (window.pageYOffset >= 84 && this.headerOpacity == 1) {
          return;
        }
        this._ngZone.run(() => {
          // check the offsetTop of the document element in case scrolling is disabled
          let pageY = Math.max(Math.abs(document.documentElement.offsetTop), window.pageYOffset);
          this.headerOpacity = Math.min(Math.max(pageY / 84, 0), 1);
        });
      });
    });
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  toggleMusicPlayerCollapsed() {
    this.musicPlayerCollapsed = !this.musicPlayerCollapsed;
  }
}
