import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams, HttpEventType } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model'
import { Artist } from '../models/artist.model';
import { APIListResponse } from '../models/apiresponse.model';
import { UserService } from './user.service';
import { Observable, BehaviorSubject, ReplaySubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface ImageUploadReponse {
  status: string,
  content: number|Artist
}

export interface GetArtistOptions {
  is_featured?: boolean;
  authorized_users?: string[];
  ordering?: 'name' | '-name';
  search?: string;
}

export interface FilterableArtist {
  id: number;
  name: string;
  type: string;
}

export interface FilterArtistOptions {
  search?: string;
  artist?: number;
  composer?: number;
  limit?: number;
}

@Injectable({
  providedIn: 'root'
})
export class ArtistService {

  currentlyBrowsingArtist: Observable<Artist>;
  currentUserArtistsStream: Observable<Artist[]>;
  private _currentUserArtists: Artist[];
  private _currentlyBrowsingArtistSubject: BehaviorSubject<Artist> = new BehaviorSubject<Artist>(null);
  private _currentUserArtistsSubject: ReplaySubject<Artist[]> = new ReplaySubject<Artist[]>(1);

  private _currentUser:User;

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState,
    private _userService: UserService
  ) {
    this.currentlyBrowsingArtist = this._currentlyBrowsingArtistSubject.asObservable();
    this.currentUserArtistsStream = this._currentUserArtistsSubject.asObservable();
    this._userService.currentUserStream.subscribe(u => {
      this._currentUser = u;
      this.refreshCurrentUserArtists();
    })

  }

  favoriteArtist(artist: Artist): Observable<Artist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/artists/' + artist.id + '/favorite/',
      {},
      {
        headers: headers
      }
    ).pipe(map((res) => {
      artist.loadWithJSON(res);
      return artist;
    }));
  }

  unfavoriteArtist(artist: Artist): Observable<Artist> {
    let headers = new HttpHeaders();
    return this.http.delete<Object>(
      environment.apiURL + '/api/v1/artists/' + artist.id + '/unfavorite/',
      {
        headers: headers
      }
    ).pipe(map((res) => {
      artist.loadWithJSON(res);
      return artist;
    }));
  }

  updateCurrentlyBrowsingArtist(a: Artist) {
    this._currentlyBrowsingArtistSubject.next(a);
  }

  getFeaturedArtists(search:string=null): Observable<Artist[]> {
    let params = {is_featured: 'true'}
    if(search) {
      params['search'] = search;
    }
    return this.getArtists(params);
  }

  getFavoriteArtists(search:string=null): Observable<Artist[]> {
    let params = {is_favorite: 'true'}
    if(search) {
      params['search'] = search;
    }
    return this.getArtists(params);
  }

  getArtist(artistId: number): Observable<Artist> {
    // Check if artist is cached from server
    const ARTIST_KEY = makeStateKey<Object>('artist-' + artistId);
    if (this._transferState.hasKey(ARTIST_KEY)) {
      const artist = this._transferState.get<Object>(ARTIST_KEY, null);
      this._transferState.remove(ARTIST_KEY);
      return of(new Artist(artist));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/artists/' + artistId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the artist
        if (!this._platform.isBrowser) {
          this._transferState.set(ARTIST_KEY, res);
        }
      }),
      map((res) => new Artist(res))
    );
  }

  getAllArtists(options: GetArtistOptions = null): Observable<Artist[]> {
    let params: {[param: string]: string | string[]} = {};
    if (options && options.authorized_users) {
      params.authorized_users = options.authorized_users;
    }
    if (options && (options.is_featured === true || options.is_featured === false)) {
      params.is_featured = `${options.is_featured}`;
    }
    if (options && options.ordering) {
      params.ordering = options.ordering;
    }
    if (options && options.search) {
      params.search = options.search;
    }
    return this.getArtists(params);
  }

  getAllArtistsForFilter():Observable<FilterableArtist[]> {
    // Check if artists are cached from server
   
    let headers = new HttpHeaders();
    return this.http.get<any[]>(
      environment.apiURL + '/api/v1/tracks/music-artist-dropdown/',
      {
        headers: headers,
      }
    ).pipe(
      
      map((res) => {
        let artist = res['results']
         return artist.map((a:any) => {return {id: a.id, name: a.name, type: a.type}});
      })
    );

  }

  refreshCurrentUserArtists(): Observable<Artist[]> {
    if (!this._currentUser) {
      this._currentUserArtists = [];
      this._currentUserArtistsSubject.next(this._currentUserArtists);
      return of([]);
    }
    let artistObservable = this.getArtists({authorized_users: String(this._currentUser.id)});
    artistObservable.subscribe(a => {
      this._currentUserArtists = a;
      this._currentUserArtistsSubject.next(this._currentUserArtists);
    });
    return artistObservable;
  }

  updateArtist(artist:Artist): Observable<Artist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    let artistJSON = artist.toJSON();
    delete artistJSON.thumb_image;
    delete artistJSON.marquee_image;
    return this.http.put<Object>(
      environment.apiURL + '/api/v1/artists/' + artist.id + '/',
      artistJSON,
      {
        headers: headers
      }
    ).pipe(map((res)=>{
      return new Artist(res);
    }));
  }

  updateArtistMarqueeImage(artistId:number, file:File): Observable<ImageUploadReponse> {
    let headers = new HttpHeaders();
    let data = new FormData();
    data.append('marquee_image', file);
    return this.http.post<any>(
      environment.apiURL + '/api/v1/artists/' + artistId + '/updateimages/',
      data,
      {
        headers: headers,
        observe: 'events',
        reportProgress: true,
      }
    ).pipe(map((event) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', content: progress };
        case HttpEventType.Response:
          return { status: 'complete', content: new Artist(event.body) };
        default:
          return { status: 'unhandeled_event', content: event.type};
      }

    }));
  }

  private getArtists(params: {[param: string]: string | string[]}): Observable<Artist[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if artists are cached from server
    const ARTISTS_KEY = makeStateKey<APIListResponse<Object>>('artists-' + p.toString());
    if (this._transferState.hasKey(ARTISTS_KEY)) {
      const artists = this._transferState.get<APIListResponse<Object>>(ARTISTS_KEY, null);
      this._transferState.remove(ARTISTS_KEY);
      return of(artists.results.map((s) => new Artist(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/artists/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the artists
        if (!this._platform.isBrowser) {
          this._transferState.set(ARTISTS_KEY, res);
        }
      }),
      map((res) => {
         return res.results.map((a) => new Artist(a));
      })
    );
  }
}
