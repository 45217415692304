import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlaylistService } from '../services/playlist.service';
import { DialogData } from '../share-artist/share-artist.component';
import { TrackVersion } from '../models/trackversion.model';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

@Component({
  selector: 'app-download-track',
  templateUrl: './download-track.component.html',
  styleUrls: ['./download-track.component.scss']
})
export class DownloadTrackComponent implements OnInit {
  currentUser:User
  private _subscriptions: Subscription[] = [];
  constructor(  public dialogRef: MatDialogRef<DownloadTrackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public playlistService: PlaylistService, private _userService: UserService) { }

  ngOnInit() {
    // console.log(this.data)
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
  }
  get playlist() {
    return this.data['content'] as TrackVersion;
  }
  close() {
    this.dialogRef.close(null);
  }
  downloadSingleTrack(track){
    
       let requestURL = environment.apiURL + `/api/v1/trackversions/${track.id}/downloadtrack/?id=${this.currentUser.id}&full_version_tracks=true`;
       window.open(requestURL, '_blank')
       this.close()
     }
     downloadAll(track) {
       
       let requestURL = environment.apiURL + `/api/v1/trackversions/${track.id}/downloadtrack/?id=${this.currentUser.id}`;
       window.open(requestURL, '_blank')
       this.close()
      
     
     }

}
