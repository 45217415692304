import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { PageTitleService } from '../shared/services/page-title.service';
import { environment } from '../../environments/environment';
import { SupportPageService } from '../shared/services/supportpage.service';
import { SupportPage } from '../shared/models/supportpage.model';
import { SiteConfigurationService } from '../shared/services/siteconfiguration.service';
import { SiteConfiguration } from '../shared/models/siteconfiguration.model';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';

@Component({
  selector: 'app-terms',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss']
})
export class SupportPageComponent implements OnInit, OnDestroy {

  supportPage: SupportPage;
  siteConfig: SiteConfiguration;
  loading = false;

  private _subscriptions: Subscription[] = [];
  constructor(
    private _supportPageService: SupportPageService,
    private _siteConfigService: SiteConfigurationService,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _historyService: HistoryService,
    private _activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this._siteConfigService.getSiteConfiguration().subscribe(c => {
      this.siteConfig = c;
    });
    this.loading = true;
    this._subscriptions.push(this._activatedRoute.paramMap.subscribe((m) => {
      let slug = m.get('slug');
        this.loading = true;
        this._supportPageService.getSupportPageBySlug(slug).subscribe(p => {
          this.supportPage = p;
          this._historyService.addItemToHistory(p.title);
          const title = this._pageTitleService.createPageTitle([p.title]);
          this._title.setTitle(title);
          const description = this._pageTitleService.mainSiteDescription;
          const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
          this._meta.addTags([
            {name: 'description', content: description},
            {name: 'twitter:card', content: 'summary'},
            {name: 'twitter:site', content: '@MIBE_music'},
            {name: 'twitter:title', content: `MIBE ${p.title}`},
            {name: 'twitter:description', content: description},
            {name: 'twitter:image', content: image},
            {name: 'og:type', content: 'website'},
            {name: 'og:title', content: p.title},
            {name: 'og:url', content: `${environment.protocol}://${environment.host}/support/${p.slug}`},
            {name: 'og:image', content: image},
            {name: 'og:description', content: description}
          ]);
          this._analyticsService.pageView();
          this.loading = false;
        });
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

}
