import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { ArtistComponent } from './artist.component';
import { ArtistTracksComponent } from './artisttracks/artisttracks.component';
import { ArtistAlbumsComponent } from './artist-albums/artist-albums.component';
import { ArtistAboutComponent } from './artist-about/artist-about.component';


const routes: Routes = [
  {
    path: '',
    component: ArtistComponent,
    children: [
      { path: '', redirectTo: 'track', pathMatch: 'full' },
      { path: 'track', component: ArtistTracksComponent },
      { path: 'albums', component: ArtistAlbumsComponent },
      { path: 'about', component: ArtistAboutComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ArtistRoutingModule { }
