import { Component, OnInit, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { UserService } from '../services/user.service';

export interface DialogData {
  email: string;
}

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loading = false;
  success = false;
  forgotPasswordModel = {
    email: ''
  };
  errors: string[] = [];

  constructor(
    private _userService: UserService,
    public dialogRef: MatDialogRef<ForgotPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if (this.data && this.data.email) {
      this.forgotPasswordModel.email = this.data.email;
    }
   
  }
  close(){
    this.dialogRef.close()
  }
  forgotPassword() {
    this.errors = [];
    this.loading = true;
    this._userService.forgotPassword(
      this.forgotPasswordModel.email
    ).subscribe((currentUser) => {
      this.loading = false;
      this.success = true;
    }, (err) => {
      this.loading = false;
      this.errors.push('Error resetting your password, check your email and try again!');
      console.error(err);
    });
  }
  openLoginForm(){
    
  }
}
