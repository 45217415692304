import { Component, OnInit } from '@angular/core';
import {
  trigger,
  style,
  query,
  animate,
  stagger,
  transition
} from '@angular/animations';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { environment } from '../../environments/environment';
import { FAQService } from '../shared/services/faq.service';
import { FAQ } from '../shared/models/faq.model';
import { SiteConfigurationService } from '../shared/services/siteconfiguration.service';
import { SiteConfiguration } from '../shared/models/siteconfiguration.model';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';

@Component({
  selector: 'app-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss'],
  animations: [
    trigger('faqInOut', [
      transition(':enter', [
        query('.faq', [
          style({
            opacity: '0',
            height: '0px'
          }),
          stagger(100, [
            animate('0.7s 0.3s cubic-bezier(0.19, 1, 0.22, 1)', style({
              opacity: '1',
              height: '*'
            }))
          ])
        ])
      ])
    ])
  ]
})
export class FaqsComponent implements OnInit {

  get schema(): any {
    let mainEntity = [];
    this.faqs.forEach(faq => {
      mainEntity.push({
        '@type': 'Question',
        'name': faq.question,
        'acceptedAnswer': {
          '@type': 'Answer',
          'text': faq.answer
        }
      });
    });
    return {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": mainEntity
    };
  }
  faqs: FAQ[] = [];
  siteConfig: SiteConfiguration;
  loading = false;
  constructor(
    private _faqService: FAQService,
    private _siteConfigService: SiteConfigurationService,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _historyService: HistoryService
  ) { }

  ngOnInit() {
    this._siteConfigService.getSiteConfiguration().subscribe(c => {
      this.siteConfig = c;
    });
    this.loading = true;
    this._faqService.getFAQs().subscribe(faqs => {
      this.faqs = faqs;
      this.loading = false;
    });
    this._historyService.addItemToHistory('FAQs');

    const title = this._pageTitleService.createPageTitle(['FAQs']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
    this._meta.addTags([
      {name: 'description', content: description},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: '@MIBE_music'},
      {name: 'twitter:title', content: 'MIBE FAQs'},
      {name: 'twitter:description', content: description},
      {name: 'twitter:image', content: image},
      {name: 'og:type', content: 'website'},
      {name: 'og:title', content: 'FAQs'},
      {name: 'og:url', content: `${environment.protocol}://${environment.host}/faqs`},
      {name: 'og:image', content: image},
      {name: 'og:description', content: description}
    ]);
    this._analyticsService.pageView();
  }

  faqTrackBy(_: number, f: FAQ) {
    return f.id + f.question;
  }

}
