import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  ViewChild,
  ElementRef,
  NgZone,
  Renderer,
  EventEmitter
} from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { PlayerService, PlayingItem } from '../services/player.service';
import { UserService } from '../services/user.service';
import { TrackVersionService } from '../services/trackversion.service';
import { User } from '../models/user.model';
import { DialogService } from '../services/dialog.service';
import { Subscription, fromEvent } from 'rxjs';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { error } from 'protractor';

import { WaveService } from 'angular-wavesurfer-service';
declare var wavesurfer
declare var MediaMetadata: any;

@Component({
  selector: 'app-musicplayer',
  templateUrl: './musicplayer.component.html',
  styleUrls: ['./musicplayer.component.scss'],
  providers: [WaveService]
})
export class MusicplayerComponent implements OnInit, OnDestroy {
  show = false;
  wave: WaveSurfer;
  hide: any;
  countTime:any;
  readyStatus:boolean = false;
  togglesvg() {
  this.show = !this.show


}
  @Input()
  set track(t: Track) {
    this._track = t;
    this.updateMediaSession();
  }
  get track(): Track {
    return this._track;
  }
  @Input()
  version: TrackVersion;
  @Input()
  set playbackProgress(p: number) {
  
    // console.log(p)
    this._playbackProgress = p;
    let duration = this.version.duration;
    if (!this._platform.isBrowser) { return; }
    if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
      duration = this.audioEl.nativeElement.duration;
    }
    this.audioEl.nativeElement.currentTime = duration * p;
    this.currentTime = this.audioEl.nativeElement.currentTime;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  get playbackProgress(): number {
    return this._playbackProgress;
  }

  @Output()
  collapse = new EventEmitter();

  @ViewChild('audioEl', {static: true})
  audioEl: ElementRef<HTMLAudioElement>;

  set isPlaying(p: boolean) {
    this._isPlaying = p;
    if (!this._platform.isBrowser) { return; }
    if (p && this.audioEl.nativeElement.paused) {
      setTimeout(() => {
        this._renderer.invokeElementMethod(this.audioEl.nativeElement, 'play');
        this._cancelAnimationFrame = this._ngZone.runOutsideAngular(() => requestAnimationFrame(() => {
          this.animationFrameCb()
        }))
      });
    } else if (!p && !this.audioEl.nativeElement.paused) {
      this._renderer.invokeElementMethod(this.audioEl.nativeElement, 'pause');
      this.cancelAnimationFrame();
    }
  }
  get isPlaying(): boolean {
    return this._isPlaying;
  }

  get previousItem(): PlayingItem {
    return this._playerService.previousItem;
  }
  get nextItem(): PlayingItem {
    return this._playerService.nextItem;
  }
  get isFavorited(): boolean {
    return this.currentUser && this.version && this.version.favorited_by_me;
  }
 
  currentTime: number = 0;
  currentUser: User;
  loadingPercentage:any;
  private _cancelAnimationFrame: number;
  private _track: Track;
  private _isPlaying = false;
  private _playbackProgress = 0;
  private _subscriptions: Subscription[] = [];

  constructor(
    public _playerService: PlayerService,
    private _ngZone: NgZone,
    private _platform: Platform,
    private _renderer: Renderer,
    private _userService: UserService,
    private _trackVersionService: TrackVersionService,
    private _dialogService: DialogService,
    private http: HttpClient,
    public waveService: WaveService
  ) { 
   
  }
waveStatus:boolean = false;
  ngOnInit() {
    this.wave = this.waveService.create({
      container: '#basetemplate',
      hideScrollbar: true,
      barGap: 3,
      barWidth: 1,
      waveColor: 'white',
      progressColor:'black',
      cursorColor: '#50e3c2',
      height: 65,
      backend: 'MediaElement'
    });
    this._playerService.playtrack.subscribe(x =>{
      if(x == true){
        this.wave.play()
      }
    })
    this._subscriptions.push(
      this._playerService.isPlaying.subscribe((p) => {
        // console.log(this.track)
        this.isPlaying = p;
       
      })
    );
    this._playerService.currentPause.subscribe(x=>{
      this.pause()
    })
    this._subscriptions.push(
      this._playerService.playbackProgress.subscribe((p) => {
        if (this._playbackProgress != p) {
          this.playbackProgress = p;
          
        }
      })
    );
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    if (this._platform.isBrowser) {
      this._subscriptions.push(fromEvent(window.document, 'keydown').subscribe((e: KeyboardEvent) => {
        if (e.keyCode === 32 || e.code === 'Space') {
          let activeNodeName = window.document.activeElement.nodeName.toLowerCase();
          if (activeNodeName == 'input' || activeNodeName == 'textarea' || activeNodeName == 'button' || activeNodeName == 'select') {
            return;
          }
          if (!this.track || !this.version) { return; }
          e.preventDefault();
          if (this.isPlaying) {
            this.pause();
          } else {
            this.play();
          }
        }
      }));
    }
  let aud = document.getElementById('aud')
  aud['muted'] = true;
  
  this._playerService.currentlyPlaying.subscribe(x=>{
    // console.log(x)
    if(x){
     
      let url:any = `${environment.apiURL}/api/v1/trackversions/get-version-waveform/?version_id=${x.version.id}`
      this.http.get(url).subscribe((y:any)=>{
        this.readyStatus = false;
        this.currentTime = 0
        this.wave.stop();
        let hostUrl = environment.awsHost + y.mp3_file
        let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
       if(isSafari){
      let  result = hostUrl.replace(/.mp4/g, ".mp3");
        y['waveform_file_data'] ? this.wave.load(result,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
       else{
        y['waveform_file_data'] ? this.wave.load(hostUrl,y.waveform_file_data) : this.wave.load(y.mp3_file);
       }
        
      })
    }
   
  })
  this.wave.on('ready', ()=> {
    if(!this.isPlaying)
    this._playerService._isPlayingSubject.next(true);
    this.readyStatus = true
    if(this.isPlaying){
      // this.wave.setMute(false)
      this.updateTimer()
      this.wave.play();
      this.waveStatus = true;
     
    }
    
  })
  this.wave.on('finish', ()=>{
    this.wave.stop();
    // this.wave.setMute(true)
    this.updateTimer()
    this.ended();
    this.waveStatus = false;
  })
  this.wave.on('loading', (x)=>{
    this.loadingPercentage = x;
   
    this.updateTimer();
  })
  
this.wave.on('audioprocess',()=>{
  this.updateTimer()
})
this._playerService.playtrack.subscribe(x=>{
  if(x === true){
    this.play()
  }
})
  }
  updateTimer() {
    
    var formattedTime = this.secondsToTimestamp(this.wave.getCurrentTime());
   let x =  (document.querySelector('.current-time') as HTMLSpanElement)
   x.innerText = this.waveStatus === true ?formattedTime : '00:00'
  }
   secondsToTimestamp(seconds) {
    seconds = Math.floor(seconds);
    var h = Math.floor(seconds / 3600);
    var m = Math.floor((seconds - (h * 3600)) / 60);
    var s = seconds - (h * 3600) - (m * 60);
  
    var hh = h < 10 ? '0' + h : h;
    var mm = m < 10 ? '0' + m : m;
    var ss = s < 10 ? '0' + s : s;
    return  mm + ':' + ss;
  }
  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this.cancelAnimationFrame();
  }
  ngAfterViewInit(): void {
   
  
  }
  getTime(){
    return this.wave.getCurrentTime() || 0
  }
  play() {
    if (this.isPlaying) { return; }
    this.wave.play()
    this._playerService.updateIsPlaying(true);
    this._playerService.playTrackSubject.next(true)
  }
  pause() {
    this._playerService.currentPlayingObject = this.version;
    this.wave.pause()
    this._playerService.clearPlayingSubject.next(true)
    if (!this.isPlaying) { return; }

    this._playerService.updateIsPlaying(false);
  }
  ended() {
    if (!this.nextItem) { return; }
    this._playerService.next();
    this._playerService.updateIsPlaying(true);
  }
  audioTimeUpdate() {
    if (!this._platform.isBrowser) { return; }
    let duration = this.version.duration;
    if (!isNaN(this.audioEl.nativeElement.duration) && this.audioEl.nativeElement.duration != Infinity) {
      duration = this.audioEl.nativeElement.duration;
    }
    this.currentTime = this.audioEl.nativeElement.currentTime;

    this._playbackProgress = this.currentTime / duration;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
   
  }
  // audioCanPlay() {
  //   if (!this._platform.isBrowser) { return; }
  //   if (this.isPlaying && this.audioEl.nativeElement.paused) {
  //     this._renderer.invokeElementMethod(this.audioEl.nativeElement, 'play');
      
  //   }
  // }
  skipForward() {
    if (!this.nextItem) { return; }
    this.waveStatus = false;
    this._playerService.next();
   
  }
  skipBack() {
    // if (this.currentTime > 2) {
    //   this.playbackProgress = 0;
    //   return;
    // }

    if (!this.previousItem) { return; }
    this.waveStatus = false
    this._playerService.previous();
  }

  collapsePlayer() {
    this.collapse.emit();
  }

  favoriteTrackVersion(version: TrackVersion) {
    if (this.currentUser) {
      this._trackVersionService.favoriteTrackVersion(version).subscribe();
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._trackVersionService.favoriteTrackVersion(version).subscribe();
      }
    });
  }

  unfavoriteTrackVersion(version: TrackVersion) {
    if (this.currentUser) {
      this._trackVersionService.unfavoriteTrackVersion(version).subscribe();
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._trackVersionService.unfavoriteTrackVersion(version).subscribe();
      }
    });
  }

  shareTrack(track: Track) {
    if (this.currentUser) {
      this._dialogService.displayShareTrackForm(track);
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._dialogService.displayShareTrackForm(track);
      }
    });
  }

  displayDownloadDialog(data: {track:Track, version:TrackVersion}) {
    if (this.currentUser) {
      this._dialogService.displayDownloadDialog('track', data);
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._dialogService.displayDownloadDialog('track', data);
      }
    });
  }

  amOwner(track: Track): boolean {
    if (!this.currentUser || !track) return false;
    return track.owned_by == this.currentUser.id;
  }

  artistFeatured(track: Track): boolean {
    if (!track) return false;
    return track.artist && track.artist.is_featured;
  }

  displayTrackInfo(version,track: any) {
    // console.log(track)
    let headers = new HttpHeaders();
    this.http.get<any>(
      environment.apiURL + `/api/v1/track/${version.id}/get-track-info/`,
      {
        headers: headers
      }
    ).subscribe(x=>{
      let data = track;
      data['composserData']= x;
      data['sub']= x.sub_genre;

      data['instrumentation']= x.instrumentation;
      data['sonic_references']= x.sonic_references;
      data['tv_nw_references']= x.tv_nw_references;

      this._dialogService.displayTrackInfo(data);
    },
    error =>{
this._dialogService.displaydisplayerror(error.error.Error,false)
    }
  )
    
  }

  editTrackDetail(track: Track) {
    if (!track) return;
    this._dialogService.displayCreateTrack(track);
  }

  addTrackVersion(track: Track) {
    if (!track) return;
    this._dialogService.displayUploadVersion(track);
  }

  editVersionDetail(track: Track, version: TrackVersion) {
    if (!track || !version) return;
    this._dialogService.displayUploadVersion(track, version);
  }

  private animationFrameCb() {
  if (!this._platform.isBrowser) { return; }
  if (this.audioEl.nativeElement.currentTime != this.currentTime) {

      this._ngZone.run(() => this.audioTimeUpdate());
    }
    this._cancelAnimationFrame = requestAnimationFrame(() => {
      this.animationFrameCb()
    })
  }
  private cancelAnimationFrame() {
    if (this._platform.isBrowser && this._cancelAnimationFrame) {
      cancelAnimationFrame(this._cancelAnimationFrame);
    }
  }

  private updateMediaSession() {
    if (!this._platform.isBrowser) { return; }
    if (!('mediaSession' in navigator)) { return; }
    if (!this.track) { return; }
    (<any>navigator).mediaSession.metadata = new MediaMetadata({
      title: this.track.title,
      artist: this.track.artist ? this.track.artist.name : '',
      // album: "Podcast Name",
      // artwork: [{src: "podcast.jpg"}]
    });
    (<any>navigator).mediaSession.setActionHandler('play', () => {
      this.play();
      this.wave.play();
    });
    (<any>navigator).mediaSession.setActionHandler('pause', () => {
      this.pause();
    });
    (<any>navigator).mediaSession.setActionHandler('previoustrack', () => {
      this.skipBack();
    });
    (<any>navigator).mediaSession.setActionHandler('nexttrack', () => {
      this.skipForward();
    });
  }
  albumNavigate(id){
    return `/artist/${id}/albums`
  }
  downloadMyFilew(url){
    const link = document.createElement('a');
    link.setAttribute('target', '_blank');
    link.setAttribute('href', url.wav_file);
    link.setAttribute('download', url.title);
    document.body.appendChild(link);
    link.click();
    link.remove();
}
percentDone:any
DownloadPlaylistPop(track,i){
  this.http.get(environment.apiURL+'/api/v1/user/current/').subscribe((x: User)=>{
    if(x.can_download){
     
        this.downloadSingleTrack(track)
           
    }
    else{
        this._dialogService.displaydisplayerror('Access Denied',false)
    }
  })
 
}
downloadSingleTrack(track){
    
  let requestURL = environment.apiURL + `/api/v1/trackversions/${track.id}/downloadtrack/?id=${this.currentUser.id}&full_version_tracks=true`;
  window.open(requestURL, '_blank')
}
downloadMyFile(track) {
  this.http.get(environment.apiURL + '/api/v1/user/current/').subscribe((x: User)=>{
      if(x.can_download){
       
          let requestURL = environment.apiURL + `/api/v1/trackversions/${track.id}/downloadtrack/?id=${this.currentUser.id}`;
      window.open(requestURL, '_blank')
      }
      else{
          this._dialogService.displaydisplayerror('Access Denied',false)
      }
    })
 
  // this._dialogService.displayprogressbarForm(this.percentDone)
  // let headers = new HttpHeaders();
  // this.http.get(`${requestURL}`, {
  //   responseType: 'arraybuffer', headers: headers,reportProgress: true,observe: 'events'
  // }
  // ).subscribe(response => {
   
  //   if (response.type === HttpEventType.DownloadProgress) {
     
  //     this.percentDone = Math.round(100 * response.loaded / response.total);
    
  //   }
  //   else if (response.type == HttpEventType.Response) {
  //     this.percentDone = null;
  //     this.downloadFile(response, "audio/octet-stream",track.title)
  //   }
  // },
  // error =>{
  //   if(error.status){
  //     this.percentDone = null;
  //     this._dialogService.displaydisplayerror('To download this track, please contact the MIBE Representative',false)
      
  //   }
    
  // });


}
downloadFile(data: any, type: string, filename: any) {
  let fileName = `${filename}.wav`;
  let blob = new Blob([data], { type: 'audio/wav'});
  let url = window.URL.createObjectURL(blob);
  saveAs(url, fileName);
  return
  // let pwa = window.open(url);
  // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
  //     //alert( 'Please disable your Pop-up blocker and try again.');
  // }
}
rateTrack(track){
  this._dialogService.displayratetrack(track,this.version);
}

}
