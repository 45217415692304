import { Component, OnInit, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Genre } from '../shared/models/genre.model';
import { Playlist } from '../shared/models/playlist.model';
import { Track } from '../shared/models/track.model';
import { PageTitleService } from '../shared/services/page-title.service';
import { TrackService } from '../shared/services/track.service';
import { GenreService } from '../shared/services/genre.service';
import { PlaylistService } from '../shared/services/playlist.service';
import { Observable, BehaviorSubject } from 'rxjs';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {

  featuredGenres: Genre[] = [];
  featuredPlaylists: Playlist[] = [];
  featuredTracks: Observable<ReadonlyArray<Track>>;
  loadingFeaturedTracks = true;
  private _featuredTracksSubject: BehaviorSubject<ReadonlyArray<Track>> = new BehaviorSubject<ReadonlyArray<Track>>([]);
  loadingFeaturedGenres = true;
  loadingFeaturedPlaylists = true;

  constructor(
    private _trackService: TrackService,
    private _genreService: GenreService,
    private _playlistService: PlaylistService,
    private _pageTitleService: PageTitleService,
    private _title: Title,
    private _meta: Meta,
    private _analyticsService: AnalyticsService,
    private _historyService: HistoryService
  ) {
    this.featuredTracks = this._featuredTracksSubject.asObservable();
  }

  ngOnInit() {
    // Set SEO Stuff
    const title = this._pageTitleService.createPageTitle(null);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
    this._meta.addTags([
      {name: 'description', content: description},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: '@MIBE_music'},
      {name: 'twitter:title', content: `MIBE Music`},
      {name: 'twitter:description', content: description},
      {name: 'twitter:image', content: image},
      {name: 'og:type', content: 'website'},
      {name: 'og:title', content: `MIBE Music`},
      {name: 'og:url', content: `${environment.protocol}://${environment.host}/`},
      {name: 'og:image', content: image},
      {name: 'og:description', content: description}
    ]);

    // Set Twitter Stuff

    // Set Facebook Stuff

    this.loadingFeaturedGenres = true;
    this._genreService.getFeaturedGenresOnLOad().subscribe(genres => {
     
      this.featuredGenres = genres.filter(genere => genere.is_featured);
      // console.log(this.featuredGenres)
      this.loadingFeaturedGenres = false;
    });

    this.loadingFeaturedPlaylists = true;
    this._playlistService.getFeaturedPlaylists().subscribe(playlists => {
      this.featuredPlaylists = playlists.results;
      this.loadingFeaturedPlaylists = false;
    });

    this.loadingFeaturedTracks = true;
    this._trackService.getFeaturedTracks().subscribe(tracks => {
      this._featuredTracksSubject.next(tracks);
      this.loadingFeaturedTracks = false;
    });

    this._analyticsService.pageView();

    this._historyService.addItemToHistory('Home');
  }

  ngOnDestroy() {
    this._featuredTracksSubject.complete();
    this._featuredTracksSubject = null;
    this.featuredTracks = null;
  }

  genresTrackBy(i: number, genre: Genre) {
    return genre.id + genre.name + genre.thumb_image;
  }

  playlistsTrackBy(i: number, playlist: Playlist) {
    return playlist.id + playlist.name + playlist.thumb_image;
  }

}
