import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';

import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Subscription as Sub } from '../models/subscription.model';
import { SubscriptionService } from '../services/subscription.service';
import { TrackService } from '../services/track.service';
import { TrackVersionService } from '../services/trackversion.service';
import { PlaylistService } from '../services/playlist.service';
import { SiteConfiguration } from '../models/siteconfiguration.model';
import { SiteConfigurationService } from '../services/siteconfiguration.service';
import { PaymentSourceService } from '../services/paymentsource.service';


@Component({
  selector: 'app-delete-track',
  templateUrl: './delete-track.component.html',
  styleUrls: ['./delete-track.component.scss']
})
export class DeleteTrackComponent implements OnInit {
  private _subscriptions: Subscription[] = [];

  constructor(
    private _platform: Platform,
    private _subscriptionService: SubscriptionService,
    private _paymentSourceService: PaymentSourceService,
    private _trackService: TrackService,
    private _siteConfigurationService: SiteConfigurationService,
    private _trackVersionService: TrackVersionService,
    private _playlistService: PlaylistService,
    public dialogRef: MatDialogRef<DeleteTrackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  ngOnInit() {
    console.log(this.data)
  }
  get trackDetails() {
    return this.data['content'];
  }
  deletePlaylistTrack(trackID:number, isFullVersion:boolean){
    this._playlistService.deletePlaylistTrack(trackID, isFullVersion).subscribe((res:any)=>{
      if (this._platform.isBrowser && window) {
        window.location.reload();
      }
      this.close();
    })
  }

  close() {
    this.dialogRef.close();
  }

}
