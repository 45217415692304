import {
  Component,
  OnInit,
  OnDestroy,
  Renderer2,
  Inject,
  Input
} from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { UserService } from '../services/user.service';
import { GenreService } from '../services/genre.service';
import { MoodService } from '../services/mood.service';
import { DialogService } from '../services/dialog.service';
import { User } from '../models/user.model';
import { Genre } from '../models/genre.model';
import { Mood } from '../models/mood.model';
import { PickerType, PickerOption } from '../genremoodpicker/genremoodpicker.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('contactInfoInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          transform: 'translateY(-100%)'
        }),
        animate('0.7s 0.3s cubic-bezier(0.19, 1, 0.22, 1)', style({
          opacity: '1',
          transform: 'translateY(0)'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          position: 'absolute',
          right: '15px',
        }),
        animate('0.3s ease-in-out', style({
          opacity: '0',
          transform: 'translateY(100%)'
        }))
      ])
    ]),
    trigger('headerElementInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          transform: 'translateY(-100%)'
        }),
        animate('0.7s 0.7s cubic-bezier(0.19, 1, 0.22, 1)')
      ]),
      transition(':leave', [
        animate('0.3s cubic-bezier(0.78, 0, 0.81, 0)', style({
          opacity: '0',
          transform: 'translateY(-100%)'
        }))
      ])
    ]),
    trigger('menuOverlayInOut', [
      transition(':enter', [
        style({
          opacity: '0'
        }),
        animate('0.3s 0.3s ease-in-out', style({
          opacity: '1'
        }))
      ]),
      transition(':leave', [
        animate('0.7s ease-in-out', style({
          opacity: '0'
        }))
      ])
    ])
  ]
})
export class HeaderComponent implements OnInit, OnDestroy {

  @Input()
  backgroundOpacity = 0;

  menuOpen = false;
  searchOpen = false;
  genre: Genre;
  mood: Mood;
  loadingCurrentUser = true;
  currentUser: User;
  genreMoodPickerParentGenreId: number;
  genreMoodPickerSelectedItemId: number;
  animateHeaderItems = false;

  get genreMoodPickerType(): PickerType {
    return this._genreMoodPickerType;
  }
  set genreMoodPickerType(t: PickerType) {
    this._genreMoodPickerType = t;
    if (t) {
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  get backgroundColor(): string {
    if (this.menuOpen || !this.backgroundOpacity) { return ''; }
    return 'rgba(21, 21, 28, ' + this.backgroundOpacity + ')';
  }

  private _subscriptions: Subscription[] = [];
  private _genreMoodPickerType: PickerType;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private _userService: UserService,
    private _dialogService: DialogService,
    private _genreService: GenreService,
    private _moodService: MoodService,
    private renderer: Renderer2,
    private _router: Router
  ) { }

  ngOnInit() {
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
      this.loadingCurrentUser = false;
      if (!this.animateHeaderItems) {
        setTimeout(() => {
          this.animateHeaderItems = true;
        });
      }
    }));
    this._subscriptions.push(
      this._genreService.currentlyBrowsingGenre.subscribe(g => {
        this.genre = g;
      })
    );
    this._subscriptions.push(
      this._moodService.currentlyBrowsingMood.subscribe(m => {
        this.mood = m;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this._subscriptions = [];
  }

  toggleMenu(open: boolean) {
    this.menuOpen = open;
    if (this.menuOpen) {
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  toggleSearch(open: boolean) {
    this.searchOpen = open;
    if (this.searchOpen) {
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  openLogin() {
    this._dialogService.displayLoginForm()
    .subscribe((u) => {
      // this._router.navigate(['/']);
    });
  }

  openRegister() {
    this._dialogService.displayRegisterForm();
  }

  openGenrePicker() {
    this.genreMoodPickerType = PickerType.Genre;
    if (this.genre && this.genre.parent) {
      this.genreMoodPickerSelectedItemId = this.genre.parent.id;
    } else if (this.genre) {
      this.genreMoodPickerSelectedItemId = this.genre.id;
    }
  }
  openSubgenrePicker() {
    this.genreMoodPickerType = PickerType.SubGenre;
    if (this.genre && this.genre.parent) {
      this.genreMoodPickerParentGenreId = this.genre.parent.id;
      this.genreMoodPickerSelectedItemId = this.genre.id;
    } else if (this.genre) {
      this.genreMoodPickerParentGenreId = this.genre.id;
    }
  }
  genreMoodPickerSelected(option: PickerOption) {
    if (!option) {
      this.genreMoodPickerType = null;
      this.genreMoodPickerParentGenreId = null;
      this.genreMoodPickerSelectedItemId = null;
      return;
    }
    switch (this.genreMoodPickerType) {
      case PickerType.Genre:
      case PickerType.SubGenre:
        let genre = option.value as Genre;
        this.setGenre(genre);
        this.genreMoodPickerType = null;
        break;
      case PickerType.Mood:
        let mood = option.value as Mood;
        this.setMood(mood);
        this.genreMoodPickerType = null;
        break;
    }
  }

  setGenre(genre: Genre) {
    if (genre) {
      this._router.navigate(
        ['/genre', genre.id],
        {state: {data: genre.toJSON()}}
      );
    } else {
      this._router.navigate(['/browse']);
    }
  }

  setMood(mood: Mood) {
    if (mood) {
      if (this.genre) {
        this._router.navigate(
          ['/genre', this.genre.id],
          {
            state: {data: this.genre.toJSON()},
            queryParams: {mood: mood.id}
          }
        );
      } else {
        this._router.navigate(
          ['/mood', mood.id],
          {state: {data: mood.toJSON()}}
        );
      }
    } else {
      if (this.genre) {
        this._router.navigate(
          ['/genre', this.genre.id],
          {
            state: {data: this.genre.toJSON()}
          }
        );
      } else {
        this._router.navigate(['/browse']);
      }
    }
  }

  openMoodPicker() {
    if (this.genre) {
      this.genreMoodPickerParentGenreId = this.genre.id;
    }
    this.genreMoodPickerType = PickerType.Mood;
    if (this.mood) {
      this.genreMoodPickerSelectedItemId = this.mood.id;
    }
  }

  logout() {
    this._userService.logout().subscribe((_) => {});
    localStorage.clear()
    this._router.navigate(['/']);
  }

  allTracks() {
    this._router.navigate(['/browse']);
  }

}
