import { Component, OnInit, OnDestroy, Inject, ElementRef, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription, Observable, combineLatest } from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {FormControl} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatChipInputEvent} from '@angular/material/chips';

import { TrackService } from '../services/track.service';
import { Track } from '../models/track.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';
import { ArtistService } from '../services/artist.service';
import { Artist } from '../models/artist.model';
import { GenreService } from '../services/genre.service';
import { Genre } from '../models/genre.model';
import { MoodService } from '../services/mood.service';
import { Mood } from '../models/mood.model';
import { TrackType } from '../models/tracktype.model';
import { TrackTypeService } from '../services/tracktype.service';
import { SiteConfigurationService } from '../services/siteconfiguration.service';
import { SiteConfiguration } from '../models/siteconfiguration.model';

export interface DialogData {
  track: Track;
}

@Component({
  selector: 'app-create-track',
  templateUrl: './create-track.component.html',
  styleUrls: ['./create-track.component.scss']
})
export class CreateTrackComponent implements OnInit, OnDestroy {

  loading = true;
  saving = false;
  isEdit = false;
  cowriterError = false;
  loadingCowriter = false;
  currentUser:User;
  currentArtists:Artist[] = [];
  genres:Genre[] = [];
  moods: Mood[] = [];
  trackTypes: TrackType[] = [];
  cowriterEmail = '';

  separatorKeysCodes: number[] = [ENTER, COMMA];
  moodCtrl = new FormControl();
  filteredMoods: Observable<Mood[]>;
  siteConfig: SiteConfiguration;

  get trackModel() {
    return this._trackModel;
  }

  set trackModel(t) {
    this._trackModel = t;
  }

  get cowriters(): TrackComposer[] {
    if (!this._trackModel || !this._trackModel.composers) {
      return [];
    }
    return this._trackModel.composers.filter(c => c.composer_id != this._trackModel.owned_by);
  }

  @ViewChild('moodInput', {static: false}) moodInput: ElementRef<HTMLInputElement>;
  @ViewChild('autoMoods', {static: false}) matAutocompleteMood: MatAutocomplete;
  @ViewChild('autoMoodsTrigger', {static: false}) matAutocompleteMoodTrigger: MatAutocompleteTrigger;

  private _trackModel: Track;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _trackService: TrackService,
    private _userService: UserService,
    private _artistService: ArtistService,
    private _genreService: GenreService,
    private _moodService: MoodService,
    private _siteConfigService: SiteConfigurationService,
    private _trackTypeService: TrackTypeService,
    public dialogRef: MatDialogRef<CreateTrackComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.filteredMoods = this.moodCtrl.valueChanges.pipe(
        startWith(null),
        map((mood: string | null) => mood ? this._filterMood(mood) : this.moods.filter(mood => !this._moodIn(mood, this.trackModel.moods)).slice()));
  }

  ngOnInit() {
    if (this.data.track) {
      this.isEdit = true;
      this.trackModel = this.data.track;
    } else {
      this.trackModel = new Track(null);
    }
    this._subscriptions.push(
      combineLatest(
        this._userService.currentUserStream,
        this._artistService.currentUserArtistsStream,
        this._genreService.getAllGenres(),
        this._moodService.getAllMoods(),
        this._trackTypeService.getTrackTypes(),
        this._siteConfigService.getSiteConfiguration()
      ).subscribe(([u, a, g, m, tt, c]:any) => {
        this.currentUser = u;
        this.currentArtists = a;
        this.genres = g;
        this.moods = m;
        this.trackTypes = tt;
        this.siteConfig = c;
        if (!this.trackModel.artist && a.length > 0) {
          this.trackModel.artist = a[0];
        }

        this.loading = false;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  close(uploadVersion:boolean = false) {
    this.dialogRef.close({track:this.trackModel, uploadVersion:uploadVersion});
  }

  onSubmit() {
    this.saving = true;
    if (this.isEdit) {
      this._trackService.updateTrack(this.trackModel).subscribe((t)=> {
        this.trackModel = t;
        this.close();
        this.saving = false;
      });
      return;
    }
    this._trackService.createTrack(this.trackModel).subscribe(t => {
      this.trackModel = t;
      this.close(true);
      this.saving = false;
    })
  }

  addMood(event: MatChipInputEvent) {
    if (!this.matAutocompleteMood.isOpen) {
      const value = event.value;

      // Add our mood
      if ((value || '').trim()) {
        let mood = this.getMood(value.trim());
        if (mood) {
          this.trackModel.moods.push(mood);
          this.moodCtrl.patchValue('');
        }
      }
    }
  }

  addCoWriter() {
    if (this.loadingCowriter) { return; }
    this.cowriterError = false;
    this.loadingCowriter = true;
    this._userService.getCoWriter(this.cowriterEmail)
      .subscribe((c) => {
        this.loadingCowriter = false;
        c.composer_percentage = 0;
        this.trackModel.composers.unshift(c);
        this.cowriterEmail = '';
      }, (_) => {
        this.cowriterError = true;
        this.loadingCowriter = false;
      });
  }

  removeCoWriter(id: number) {
    this.trackModel.composers = this.trackModel.composers.filter(c => c.composer_id != id);
  }

  selectedMood(event: MatAutocompleteSelectedEvent) {
    let mood = this.getMood(event.option.viewValue);
    if (mood) {
      this.trackModel.moods.push(mood);
    }
    this.moodCtrl.patchValue('');
    setTimeout(() => {
      this.matAutocompleteMoodTrigger.openPanel();
    });
  }

  removeMood(i:number) {
    this.trackModel.moods.splice(i, 1);
  }

  getMood(value: string): Mood {
    for (let mood of this.moods.filter(mood => !this._moodIn(mood, this.trackModel.moods))) {
      if (mood.name.toLowerCase() == value.toLowerCase()) return mood;
    }
    return null
  }

  artistChanged(id:number) {
    for (let artist of this.currentArtists) {
      if (artist.id == id) {
        this.trackModel.artist = artist;
        return;
      }
    }
  }

  genreChanged(id:number) {
    for (let genre of this.genres) {
      if (genre.id == id) {
        this.trackModel.genre = genre;
        return;
      }
    }
  }

  trackTypeChanged(id:number) {
    for (let type of this.trackTypes) {
      if (type.id == id) {
        this.trackModel.type = type;
        return;
      }
    }
  }

  moodTrackBy(_:number, mood: Mood) {
    return mood.id + '-' + mood.name;
  }

  genreTrackBy(_:number, genre: Genre) {
    return genre.id+genre.name;
  }

  artistTrackBy(_:number, artist: Artist) {
    return artist.id + '-' + artist.name;
  }

  trackTypeTrackBy(_:number, type: TrackType) {
    return type.id + '-' + type.name;
  }

  cowriterTrackBy(_:number, writer: TrackComposer) {
    return writer.composer_id + writer.composer_name;
  }

  private _filterMood(value: string): Mood[] {
    const filterValue = value.toLowerCase();

    return this.moods.filter(mood => mood.name.toLowerCase().indexOf(filterValue) === 0 && !this._moodIn(mood, this.trackModel.moods));
  }

  private _moodIn(mood:Mood, moods:Mood[]) {
    for (let m of moods) {
      if (m.id == mood.id) return true;
    }
    return false;
  }

}
