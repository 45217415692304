import { MibeModel } from './mibemodel.model';
import { TrackVersion } from './trackversion.model';
import { TrackType } from './tracktype.model';
import { TrackComposer } from './trackcomposer.model';
import { Mood } from './mood.model';
import { MetaData } from './metadata.model';
import { Genre } from './genre.model';
import { Artist } from './artist.model';

export class Track implements MibeModel {
  id: number;
  title: string;
  name:string;
  description: string;
  owned_by: number;
  is_featured: boolean;
  type: TrackType;
  artist: Artist;
  genre: Genre;
  moods: Mood[];
  metadata: MetaData[];
  composers: TrackComposer[];
  versions: TrackVersion[];
  created_at: Date;
  updated_at: Date;
  composserData:any;
  sub_genre:Genre;
  client_top_version:number;
  tv_nw_references:any[];
  sonic_references:any[];
  instrumentation:any[];
  support_tv_nw_references:any[];
  support_sonic_band_references:any[];
  version_instrumentation:any[]
sub: any;
count:number;
  /**
   * Create a Track.
   * @param {any} json - The JSON object with which to construct our Track.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Track.
   * @param {any} json - The JSON object with which to load our Track.
   */
  loadWithJSON(json: any) {
    if (!json) {
      this.moods = [];
      this.versions = [];
      return;
    }
    this.id = json.id;
    this.title = json.title;
    this.sub_genre = json.sub_genre;
    this.sonic_references = json.sonic_references;
    this.instrumentation = json.instrumentation;
    this.support_sonic_band_references = json.support_sonic_band_references;
    this.support_tv_nw_references = json.support_tv_nw_references
    this.tv_nw_references = json.tv_nw_references;
    this.description = json.description;
    this.owned_by = json.owned_by;
    this.client_top_version =json.client_top_version;
    this.is_featured = !!json.is_featured;
    this.version_instrumentation = json.version_instrumentation
    this.count = json.count;
    if (json.type) {
      this.type = new TrackType(json.type);
    } else {
      this.type = null;
    }
    if (json.artist) {
      this.artist = new Artist(json.artist);
    } else {
      this.artist = null;
    }

    if (json.genre) {
      this.genre = new Genre(json.genre);
    } else {
      this.genre = null;
    }
    if (json.moods) {
      this.moods = json.moods.map((m) => new Mood(m));
    } else {
      this.moods = [];
    }
    if (json.metadata) {
      this.metadata = json.metadata.map((m) => new MetaData(m));
    } else {
      this.metadata = [];
    }
    if (json.versions) {
      this.versions = json.versions.map((v) => new TrackVersion(v));
    } else {
      this.versions = [];
    }
    if (json.composers) {
      this.composers = json.composers.map((c) => new TrackComposer(c));
    } else {
      this.composers = [];
    }
    if (json.created_at) {
      this.created_at = new Date(json.created_at);
    } else {
      this.created_at = null;
    }
    if (json.updated_at) {
      this.updated_at = new Date(json.updated_at);
    } else {
      this.updated_at = null;
    }
  }
  /**
   * Returns a JSON representation of our Track
   * @return {any} - The JSON representation of our Track
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'title': this.title,
      'description': this.description,
      'owned_by': this.owned_by,
      'type': this.type ? this.type.toJSON() : null,
      'moods': !this.moods ? [] : this.moods.map(m => m.toJSON()),
      'metadata': !this.metadata ? [] : this.metadata.map(m => m.toJSON()),
      'versions': !this.versions ? [] : this.versions.map(v => v.toJSON()),
      'composers': !this.composers ? [] : this.composers.map(c => c.toJSON()),
      'sub_genre': this.sub_genre,
      'client_top_version': this.client_top_version,
      'tv_nw_references': this.tv_nw_references,
      'sonic_references': this.sonic_references,
      'instrumentation': this.instrumentation,
      'support_sonic_band_references': this.support_sonic_band_references,
      'support_tv_nw_references': this.support_tv_nw_references,
      'version_instrumentation': this.version_instrumentation,
      'count':this.count

    };
    if (this.artist) {
      json['artist'] = this.artist.toJSON();
    }
    if (this.genre) {
      json['genre'] = this.genre.toJSON();
    }
    if (this.created_at) {
      json['created_at'] = this.created_at.toJSON();
    }
    if (this.updated_at) {
      json['updated_at'] = this.updated_at.toJSON();
    }
    return json;
  }
}
