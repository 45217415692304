import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../../shared/services/page-title.service';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms'
import { ErrorStateMatcher } from '@angular/material/core';
import { Subscription, ReplaySubject, of } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map
} from 'rxjs/operators';
import { UserService } from '../../shared/services/user.service';
import { User } from '../../shared/models/user.model';
import { ShowService } from '../../shared/services/show.service';
import { Show } from '../../shared/models/show.model';
import { usStates } from '../../shared/models/states.model';
import { AnalyticsService } from '../../shared/services/gtm/analytics.service';

interface ProfileModel {
  password: string;
  confirmPassword: string;
}

export class ConfirmPasswordStateMatcher implements ErrorStateMatcher {
  constructor(
    private _model: ProfileModel
  ) {}
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && this._model.password && this._model.confirmPassword != this._model.password;
  }
}

@Component({
  selector: 'app-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
  animations: [
    trigger('errorSuccessInOut', [
      transition(':enter', [
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: '0'
        }),
        animate('0.4s ease-in-out', style({
          opacity: '1',
          overflow: 'hidden',
          height: '*'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          overflow: 'hidden',
          height: '*'
        }),
        animate('0.4s ease-in-out', style({
          opacity: '0',
          height: '0px'
        }))
      ])
    ])
  ]
})
export class AccountProfileComponent implements OnInit, OnDestroy {
  maxDate = new Date();

  @ViewChild('autoShows', {static: false}) matAutocompleteShow: MatAutocomplete;
  @ViewChild('showInput', {static: false}) showInput: ElementRef<HTMLInputElement>;
  showCtrl = new FormControl();
  searchingShows = false;
  showsChanged = false;
  filteredShowOptions: ReplaySubject<Show[]> = new ReplaySubject<Show[]>(1);

  separatorKeysCodes: number[] = [ENTER, COMMA];

  usStates = usStates;
  passwordInputType = 'password';
  confirmPasswordInputType = 'password';
  confirmPasswordMatcher: ConfirmPasswordStateMatcher;
  get currentUser(): User {
    return this._currentUser;
  }
  set currentUser(u: User) {
    this._currentUser = u;
  }
  get userModel(): User {
    return this._userModel;
  }
  set userModel(u: User) {
    this._userModel = u;
  }
  get profileModel(): ProfileModel {
    return this._profileModel;
  }
  set profileModel(m: ProfileModel) {
    this._profileModel = m;
  }
  get errors(): string[] {
    return this._errors;
  }
  loading = true;
  saving = false;
  success = false;
  private _profileModel: ProfileModel = {
    password: '',
    confirmPassword: ''
  };
  private userDetails = JSON.parse(localStorage.getItem('user'))
  private _userModel = new User(this.userDetails ? this.userDetails : null);
  private _currentUser: User;
  private _subscriptions: Subscription[] = [];
  private _errors: string[] = [];
  constructor(
    private _userService: UserService,
    private _showService: ShowService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _analyticsService: AnalyticsService
  ) {
    this.confirmPasswordMatcher = new ConfirmPasswordStateMatcher(this._profileModel);
  }

  ngOnInit() {
    this._userService.getAccount().subscribe(x=>{
      this.userModel.loadWithJSON(x);
    })
    this._subscriptions.push(this.showCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingShows = true;
      }),
      switchMap((v) => {
        return this._showService.getAllShows(v).pipe(
          map(s => {
            s = s.filter(
              show => this.userModel.shows.findIndex(
                innerShow => innerShow.name.toLowerCase() == show.name.toLowerCase()
              ) == -1
            );
            return s;
          })
        );
      }),
      tap(() => {
        this.searchingShows = false;
      })
    ).subscribe(a => {
      this.filteredShowOptions.next(a);
    }));
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u => {
        
        // console.log(u)
        this.currentUser = new User(u);
        this.loading = false;
        // console.log(u.toJSON())
        if (u) {
         
          // this.userModel.loadWithJSON(this.currentUser.toJSON());
        }
      })
    );
    const title = this._pageTitleService.createPageTitle(['Account', 'Profile']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    this._meta.addTags([
      {name: 'description', content: description}
    ]);
    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }
get disabled(){
  
  let data = false;
  if(this.userModel.first_name == ''){
    data = true
  }
  if(this.userModel.last_name === ''){
    data = true
  }
  if(!this.userModel.birth_date){
    data = true
  }
  if(this.userModel.email === ''){
    data = true
  }
  if(!this.userModel.phone_number){
    data = true
  }

  if(this.profileModel.password && this.profileModel.password.length < 4){
    data = true
  }
  if(this.userModel.phone_number && this.userModel.phone_number.length < 10){
    data = true
  }
  
  return data
    
}
  saveProfile() {
    if (this.profileModel.password && this.profileModel.password != this.profileModel.confirmPassword) {
      return;
    }
    


    this._errors = [];
    this.success = false;
    this.saving = true;
    this._userService.updateCurrentUserAccount(this.userModel, this.profileModel.password).subscribe(_ => {
      this.saving = false;
      this.success = true;
      this._userService.getAccount().subscribe(x=>{
        this.userModel.loadWithJSON(x);
      })
      setTimeout(() => {
        this.success = false;
      }, 5000);
    }, () => {
      this._errors = [
        'There was an error saving your profile, please try again later!'
      ];
      this.saving = false;
    });
  }

  togglePasswordInputType() {
    if (this.passwordInputType == 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }

  toggleConfirmPasswordInputType() {
    if (this.confirmPasswordInputType == 'password') {
      this.confirmPasswordInputType = 'text';
    } else {
      this.confirmPasswordInputType = 'password';
    }
  }

  addShow(event: MatChipInputEvent) {
    if (!this.matAutocompleteShow.isOpen) {
      const value = event.value;

      // Add our show
      if ((value || '').trim()) {
        let show = new Show({name: value.trim()});
        this.userModel.shows.push(show);
        this.showCtrl.patchValue(null);
        this.showsChanged = true;
        event.input.value = '';
      }
    }
  }

  removeShow(i:number) {
    this.userModel.shows.splice(i, 1);
    this.showsChanged = true;
  }

  selectedShow(event: MatAutocompleteSelectedEvent) {
    let show = new Show({name: event.option.viewValue.trim()});
    this.userModel.shows.push(show);
    this.showsChanged = true;
    setTimeout(() => {
      this.showCtrl.patchValue(null);
      this.showInput.nativeElement.value = '';
    });
  }

  showTrackBy(_: number, show: Show) {
    return show.name;
  }
  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode))))) {
      event.preventDefault();
    }
  }
}
