import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer, Renderer2, Input, Inject, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { Subscription, ReplaySubject, Subject, Observable } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  startWith,
  takeUntil
} from 'rxjs/operators';
import { GetTracksOptions, TrackService } from '../shared/services/track.service';
import { Genre } from '../shared/models/genre.model';
import { Mood } from '../shared/models/mood.model';
import { User } from '../shared/models/user.model';
import { DOCUMENT } from '@angular/common';
import { PageTitleService } from '../shared/services/page-title.service';
import { UserService } from '../shared/services/user.service';
import { GenreService } from '../shared/services/genre.service';
import { MoodService } from '../shared/services/mood.service';
import { DialogService } from '../shared/services/dialog.service';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';
import { PickerType, PickerOption } from '../shared/genremoodpicker/genremoodpicker.component';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import { MatSelect } from '@angular/material';
import { Track } from '../shared/models/track.model';
import { TrackVersion } from '../shared/models/trackversion.model';
import { PageFiltersComponent } from '../shared/pagefilters/pagefilters.component';
import { TracksPageComponent } from '../shared/trackspage/trackspage.component';

interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-genre',
  templateUrl: './genre.component.html',
  styleUrls: ['./genre.component.scss'],
  animations: [
    trigger('headerElementInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          transform: 'translateY(-100%)'
        }),
        animate('0.7s 0.7s cubic-bezier(0.19, 1, 0.22, 1)')
      ]),
      transition(':leave', [
        animate('0.3s cubic-bezier(0.78, 0, 0.81, 0)', style({
          opacity: '0',
          transform: 'translateY(-100%)'
        }))
      ])
    ])
  ]
})
export class GenreComponent implements OnInit, OnDestroy {
subscription:boolean = false;
  public websiteFilterCtrl: FormControl = new FormControl();
  selected: any[] = []
  /** list of banks */
  public banks: Genre[] = [];

  /** control for the selected bank */
  public bankCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  // filteredBanks is... artists?
  public filteredBanks: ReplaySubject<Genre[]> = new ReplaySubject<Genre[]>(1);

  @ViewChild(TracksPageComponent, { static: false } ) tracksPageComponent: TracksPageComponent;

  @ViewChild('singleSelect', { static: true }) singleSelect: MatSelect;

getsubgenreValue:any;
  /** list of banks */
  public banks2: Mood[] = [];

  /** control for the selected bank */
  public bankCtrl2: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl2: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredBanks2: ReplaySubject<Mood[]> = new ReplaySubject<Mood[]>(1);

  @ViewChild('singleSelect2', { static: true }) singleSelect2: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  @ViewChild('searchActionField', { static: false })
  searchActionField: ElementRef;
  searchControl = new FormControl();
  loading = false;
  similarGenres: Genre[] = [];
  @Input()
  backgroundOpacity = 0;
  loadingCurrentUser = true;
  currentUser: User;
  genreMoodPickerParentGenreId: number;
  genreMoodPickerSelectedItemId: number;
  animateHeaderItems = false;
  tempStorage: any;

  tmpBanks: Genre[];
  tmpBanks2: Mood[];
  public searchTextboxControl: FormControl = new FormControl();
  // searchTextboxControl = new FormControl();
  selectedValues = [];
  filteredOptions: Observable<any[]>;
  @ViewChild('searchmood', { static: false }) searchTextBox: ElementRef;
  
  set genre(g: Genre) {
    this._genre = g;
    this._genreService.updateCurrentlyBrowsingGenre(g);
    this.getTracksOptions = {
      genreId: g ? g.id : undefined
    };
    this.similarGenres = [];
    if (g) {
      // Set SEO Stuff
      const title = this._pageTitleService.createPageTitle(['Genre', g.name]);
      this._title.setTitle(title);
      const description = g.description;
      const image = g.thumb_image;
      this._meta.addTags([
        { name: 'description', content: description },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@MIBE_music' },
        { name: 'twitter:title', content: `${g.name} Genre on Mibe` },
        { name: 'twitter:description', content: description },
        { name: 'twitter:image', content: image },
        { name: 'og:type', content: 'website' },
        { name: 'og:title', content: `${g.name} Genre` },
        { name: 'og:url', content: `${environment.protocol}://${environment.host}/genre/${g.id}` },
        { name: 'og:image', content: image },
        { name: 'og:description', content: description }
      ]);
      this.loadSimilarGenres();
      this._historyService.addItemToHistory('Genre');
      if(g.parent==null){
        this.setmoodbg = false;
      }
    }
  }
  get genre(): Genre {
    return this._genre;
  }
  set mood(m: Mood) {
    this._mood = m;
    this._moodService.updateCurrentlyBrowsingMood(m);
  }
  get mood(): Mood {
    return this._mood;
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      if (this.searchControl.value != s) {
        this.searchControl.setValue(s);
      }
      this._router.navigate([], {
        relativeTo: this._activatedRoute, queryParams: {
          'search': s,
          'page': 1
        }, queryParamsHandling: 'merge'
      });
    }
  }

  get getTracksOptions(): GetTracksOptions {
    return this._getTracksOptions;
  }
  set getTracksOptions(o: GetTracksOptions) {
    this._getTracksOptions = o;
  }

  //If current genre marquee_image is null, get parents
  get marqueeImage() {
    // debugger
    if (this.genre.banner_image) return this.genre.banner_image;
    if (this.genre.parent && this.genre.parent.banner_image) return this.genre.parent.banner_image;
    return null;
  }

  get prevGenre(): Genre {
    if (!this.genre || !this._allGenres || this._allGenres.length == 0) return null;
    let i = this._allGenres.findIndex(g => g.id == this.genre.id)
    i = i == 0 ? this._allGenres.length - 1 : i - 1;
    return this._allGenres[i];
  }

  get nextGenre(): Genre {
    if(this.bankCtrl.value){
      let i = this.banks.findIndex(g => g.id == this.bankCtrl.value.id)
      i = (i + 1) % this.banks.length;
      return this.banks[i];
    }
    else{
      if (!this.genre || !this._allGenres || this._allGenres.length == 0) return null;
      let i = this._allGenres.findIndex(g => g.id == this.genre.id)
      i = (i + 1) % this._allGenres.length;
      return this._allGenres[i];
    }
   
  }

  get genreMoodPickerType(): PickerType {
    return this._genreMoodPickerType;
  }
  set genreMoodPickerType(t: PickerType) {
    this._genreMoodPickerType = t;
    if (t) {
      this.renderer.addClass(this.document.body, 'no-scroll');
    } else {
      this.renderer.removeClass(this.document.body, 'no-scroll');
    }
  }

  private _genre: Genre;
  private _mood: Mood;
  private _searchString = '';
  private _allGenres: Genre[] = [];
  private _subscriptions: Subscription[] = [];
  private _getGenreSubscription: Subscription;
  private _getAllGenreSubscription: Subscription;
  private _getTracksOptions: GetTracksOptions;
  private _genreMoodPickerType: PickerType;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _platform: Platform,
    private _genreService: GenreService,
    private _moodService: MoodService,
    private _renderer: Renderer,
    private _pageTitleService: PageTitleService,
    private _title: Title,
    private _meta: Meta,
    private _analyticsService: AnalyticsService,
    private _historyService: HistoryService,
    private TrackService: TrackService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit() {
    this.filteredOptions = this.searchTextboxControl.valueChanges
      .pipe(
        startWith<string>(''),
        map(name => this._filter(name))
      );
    // set initial selection
   
    var gnId;
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params => {
        // Load Filters from URL
        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }

        let mood = params.get('mood');
        if (mood) {
          let moodId = parseInt(mood);
          if (!this.mood || this.mood.id != moodId) {
            this.loadMood(moodId);
          }
        } else {
          this.mood = null;
        }
        this._analyticsService.pageView();
      })
    );
    this._subscriptions.push(
      this._activatedRoute.paramMap.subscribe(params => {
        // Load genre from routing data or URL
        if (!this._allGenres || this._allGenres.length <= 0) {
          this.loadAllGenres();
        }
        let genre: Genre;
        if (this._platform.isBrowser && window.history.state && window.history.state.data) {
          // console.log(new TrackVersion(window.history.state.data))
          genre = new Genre(window.history.state.data);
          if (!genre.id) { genre = null; }
          this.genre = genre;
        }
        let genreId = params.get('genreId');
        let genreIdN: number;
        if (genreId) {
          this.loadSubGnere(genreId)
          this.loadSubMoods(genreId)
          genreIdN = parseInt(genreId);
        }
        this.loadGenre(genreIdN);
        if (this.genre && genreIdN == this.genre.id) {
          this.loading = false;
          return;
        }
        this.loadGenre(genreIdN);

      })
    );
    this._subscriptions.push(
      this.searchControl.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
        .subscribe(v => {
          if(v.length > 2){
            this.searchString = v;
          }
          else{
            this.searchString = v
          }
          
        })
    );
    this.bankCtrl.setValue(this.banks[10]);

    // load the initial bank list
    this.filteredBanks.next(this.banks.slice());

    // listen for search field value changes
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });

    this.bankCtrl2.setValue(this.banks2[10]);

    // load the initial bank list
    this.filteredBanks2.next(this.banks2.slice());

    // listen for search field value changes
    this.bankFilterCtrl2.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks2();
      });
      this.TrackService.currentSub.subscribe(x => {
        if (x) {
          // console.log(x)
          this.getsubgenreValue = x;
          this.setGenre(x)
        }
      })
      this.TrackService.currentpopularSub.subscribe(x => {
        if (x) {
          this.setGenre(x)
        }
      })
  }

  loadSubGnere(genreIdN) {
    this._genreService.getSubGenres(genreIdN).subscribe(x => {
      this.setgenre = false;
      this.tmpBanks = x;
      this.banks = x;
      if(this.getsubgenreValue){
        let getSub = x.filter(s => s.id === this.getsubgenreValue.id)
        if(getSub.length !== 0){
          this.subscription = true
          // console.log(getSub[0])
          this.setGenre(getSub[0])
        }
       
      }
    })
  }
  loadSubMoods(genreIdN) {
    this._moodService.getAvailableMoodsForGenre(genreIdN).subscribe(x => {
      this.banks2 = x;
      this.tmpBanks2 = x;

    });

  }
  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks.next(this.banks.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

  protected filterBanks2() {
    if (!this.banks2) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search) {
      this.filteredBanks2.next(this.banks2.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks2.next(
      this.banks2.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }


  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
    this._genreService.updateCurrentlyBrowsingGenre(null);
    this.mood = null;
    this.TrackService.CURRENTSubGenreSubject.next(null)
    this.getsubgenreValue = null;
  }

  loadGenre(genreId: number) {
    this.loading = true;
    if (this._getGenreSubscription && !this._getGenreSubscription.closed) {
      this._getGenreSubscription.unsubscribe();
    }
    this._getGenreSubscription = this._genreService.getGenre(genreId).subscribe(g => {
      this.genre = g;
      this.loading = false;
    }, (error)=>{
      if(error.status===404){
        this._router.navigate(['']);
      }
    });
  }

  async loadAllGenres() {
    if (this._getAllGenreSubscription && !this._getAllGenreSubscription.closed) {
      this._getAllGenreSubscription.unsubscribe();
    }
    this._getAllGenreSubscription = this._genreService.getParentGenres().subscribe(genres => {
      this._allGenres = genres;
    })
  }

  loadSimilarGenres() {
    this._genreService.getSimilarGenres(this.genre.id, 6)
      .subscribe(gs => {
        this.similarGenres = gs;
      });
  }

  loadMood(moodId: number) {
    this._moodService.getMood(moodId).subscribe(m => {
      this.mood = m;
    });
  }

  searchAction() {
    if (this.searchActionField) {
      this._renderer.invokeElementMethod(this.searchActionField.nativeElement, 'blur');
    }
  }

  genresTrackBy(_: number, g: Genre) {
    return g.id;
  }

  openSubgenrePicker() {
    this.genreMoodPickerType = PickerType.SubGenre;
    if (this.genre && this.genre.parent) {
      this.genreMoodPickerParentGenreId = this.genre.parent.id;
      this.genreMoodPickerSelectedItemId = this.genre.id;
    } else if (this.genre) {
      this.genreMoodPickerParentGenreId = this.genre.id;
    }
  }
  genreMoodPickerSelected(option: PickerOption) {
    if (!option) {
      this.genreMoodPickerType = null;
      this.genreMoodPickerParentGenreId = null;
      this.genreMoodPickerSelectedItemId = null;
      return;
    }
    switch (this.genreMoodPickerType) {
      case PickerType.Genre:
      case PickerType.SubGenre:
        let genre = option.value as Genre;
        this.setGenre(genre);
        this.genreMoodPickerType = null;
        break;
      case PickerType.Mood:
        let mood = option.value as Mood;
        this.setMood(mood);
        this.genreMoodPickerType = null;
        break;
    }
  }
  setgenre: boolean = false;
  setGenre(genre: Genre) {
    // debugger
    this.tracksPageComponent.resetFilters();
    if(genre.parent == null){
      this.loadAllGenres();
      // this.clearMood()
      this._genre = genre
      this.getTracksOptions = {
        genreId: this.genre.id,
        sub_genre: undefined,
        moodIDs:this.selectedValues.length>0?this.selectedValues.map(obj => obj.id):undefined
      };
      this.setgenre = false
      // this.setmoodbg = false
      // this.clearMood()
      this._router.navigate(
        // ['/genre', this.genre.parent.id],
        ['/genre', this.genre.id],
        { state: { data: this.genre.toJSON() } }
      );
     //return
    }
    else{
      this._allGenres = this.banks
      let sub = this.banks.filter(x => x.id == genre.id)
      this.bankCtrl.patchValue(sub[0])
      this.genre = genre;
      this.setgenre = true;
      if (genre.parent) {
        this._router.navigate(
          ['/genre', genre.parent.id],
          { state: { data: genre.toJSON() } }
        );
      }
      else if (genre.id) {
        this._router.navigate(
          ['/genre', genre.id],
          { state: { data: genre.toJSON() } }
        );
      } else {
        this._router.navigate(['/browse']);
      }
  
      if (genre.parent.id && genre.id) {
        this.getTracksOptions = {
          genreId: genre.parent.id ? genre.parent.id : undefined,
          sub_genre: genre.id ? genre.id : undefined,
          moodIDs:this.selectedValues?this.selectedValues.map(obj => obj.id):undefined
        };
      }
      else {
        this.getTracksOptions.genreId = genre.id ? genre.id : undefined;
      }
    }
    

  }
  isOptionDisabled(opt: any): boolean {
    if (this.bankCtrl2.value)
      return this.bankCtrl2.value.length >= 3 && !this.bankCtrl2.value.find(el => el.id == opt);
    else
      return false;
  }
  setmoodbg: boolean = false;
  setMood(mood: any) {
    this.setmoodbg = true;
    if(!mood){
      this.selectedValues = [];
    }
    // Remove duplicate values from already selected values
    const selectedMoodsValues = new Set();
        this.selectedValues = this.selectedValues.filter(el => {
          const duplicate = selectedMoodsValues.has(el.id);
          selectedMoodsValues.add(el.id);
          return !duplicate;
        });
    let selectedMoods:any = this.selectedValues.map(obj => obj.id);

    if (selectedMoods.length > 0) {
      this.tempStorage = this.getTracksOptions;;
      this.getTracksOptions = {
        moodIDs: selectedMoods.toString(),
        genreId: this.tempStorage.genreId,
        sub_genre: this.tempStorage.sub_genre
      }
    }
    else {
      this.setmoodbg = false;
      this.getTracksOptions = {
        genreId: this.tempStorage.genreId,
        sub_genre: this.tempStorage.sub_genre
      }
    }
    // this.loading = true;
    // setTimeout(() => {
    //   this.loading = false;
    // }, 500);
    // this.getTracksOptions.moodIDs = mood.id ? mood.id : [];
  }

  openedChange(opened: boolean) {
    this.searchTextboxControl.reset();
    this.selected=this.bankCtrl2.value;
    this.onSearchMood('');
    // this.setMood(this.selectedValues);
}

  openMoodPicker() {
    if (this.genre) {
      this.genreMoodPickerParentGenreId = this.genre.id;
    }
    this.genreMoodPickerType = PickerType.Mood;
    if (this.mood) {
      this.genreMoodPickerSelectedItemId = this.mood.id;
    }
  }

  onSearchBank(value) {
    this.banks = this.searchBank(value);
  }

  searchBank(value: string) {
    let filter = value.toLowerCase();
    return this.tmpBanks.filter(option => option.name.toLowerCase().includes(filter));
  }


  onSearchMood(value) {
    this.banks2 = this._filter(value);
  }

  searchMood(value: string) {
    let filter = value.toLowerCase();
    return this.tmpBanks2.filter(option => option.name.toLowerCase().includes(filter));
  }

  public _filter(value: string): any[] {
    let filterValue = value.toLowerCase();
    // Set selected values to retain the selected checkbox state 
    this.setSelectedValues();
    this.bankCtrl2.patchValue(this.selectedValues);
    let filteredList = this.tmpBanks2.filter(option => option.name.toLowerCase().indexOf(filterValue) === 0);
    return filteredList;
  }

  /**
   * Remove from selected values based on uncheck
   */
  selectionChange(event) {
    if (event.isUserInput && event.source.selected == false) {
      let index = this.selectedValues.indexOf(event.source.value);
      this.selectedValues.splice(index, 1)
    }
    else if (event.isUserInput && event.source.selected == true) {
      let index = this.selectedValues.indexOf(event.source.value);

      if(index==-1)
      this.selectedValues.push(event.source.value)

    }
    

  }

  /**
   * Set selected values to retain the state 
   */
  setSelectedValues() {

    if (this.bankCtrl2.value && this.bankCtrl2.value.length > 0) {
      this.bankCtrl2.value.forEach((e) => {
        if (this.selectedValues.indexOf(e) == -1) {
          this.selectedValues.push(e);
        }
      });
    }
  }
  clear(){
    // debugger

    // this.genre = this.genre.parent
    this.bankCtrl.setValue(null)
    this.setGenre(this.genre.parent)
    this.setgenre = false;
    this.loadSimilarGenres();
  }
  clearMood(){
    // debugger
    this.bankCtrl2.setValue(null)
    this.selectedValues = [];
    this.setMood(null)
    this.setmoodbg = false
    let data = {event : {
      source: {selected: false}}}
    this.selectionChange(data)
  }
}
