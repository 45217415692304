import { Pipe, PipeTransform } from '@angular/core';
/*
 * Formats number in seconds to a pretty duration.
 * Usage:
 *   value | duration
 * Example:
 *   {{ 96 |  duration }}
 *   formats to: 01:36
*/
@Pipe({name: 'duration'})
export class DurationPipe implements PipeTransform {
  transform(value: number): string {
    let numhours = Math.floor(value / 3600);
    let numminutes = Math.floor((value % 3600) / 60);
    let numseconds = Math.round((value % 3600) % 60);
    let str = '';
    if (numhours) {
      str = this.zeroPad(numhours, 2) + ':' + this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    } else {
      str = this.zeroPad(numminutes, 2) + ':' + this.zeroPad(numseconds, 2);
    }
    return str;
  }
  private zeroPad(num, minLength): string {
    let numStr = '' + num;
    while (numStr.length < minLength) {
      numStr = '0' + numStr;
    }
    return numStr;
  }
}

// Text truncate pipe
@Pipe({ name: 'truncate' })
export class TruncatePipe implements PipeTransform {
  transform(value: string, limit: number): string {
    return value.length < limit
      ? value
      : value.slice(0, limit) + '...';
  }
}
