import { Component, OnInit, OnDestroy, HostBinding, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  style,
  query,
  animate,
  stagger,
  transition,
  group
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { Menu } from '../models/menu.model';
import { MenuItem } from '../models/menuitem.model';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  animations: [
    trigger('menuInOut', [
      transition(':leave', [
        style({
          opacity: '1',
          overflow: 'hidden'
        }),
        query('li', [
          style({
            opacity: '1',
            transform: 'translateX(0)'
          })
        ]),
        group([
          animate('0.7s cubic-bezier(0.78, 0, 0.81, 0)', style({
            opacity: '0'
          })),
          query('li', [
            animate('0.7s cubic-bezier(0.78, 0, 0.81, 0)', style({
              opacity: '0',
              transform: 'translateX(-100%)'
            }))
          ])
        ])
      ])
    ]),
    trigger('menuItemInOut', [
      transition(':enter', [
        query('li', [
          style({
            opacity: '0',
            transform: 'translateX(-100%)'
          }),
          stagger(100, [
            animate('0.7s 0.3s cubic-bezier(0.19, 1, 0.22, 1)', style({
              opacity: '1',
              transform: 'translateX(0)'
            }))
          ])
        ])
      ])
    ])
  ]
})
export class MenuComponent implements OnInit, OnDestroy {

  loadingMainMenu = true;
  loadingSubMenu = true;

  get loading(): boolean {
    return this.loadingMainMenu || this.loadingSubMenu;
  }

  @HostBinding('@menuInOut')
  public animateMenu = true;

  @Output()
  closeMenu = new EventEmitter();

  currentUser: User;

  mainMenu: Menu;
  subMenu: Menu;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _userService: UserService,
    private _menuService: MenuService
  ) { }

  ngOnInit() {
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    this.loadingMainMenu = true;
    this._menuService.getMainSlideOutMenu()
      .subscribe(m => {
        this.loadingMainMenu = false;
        this.mainMenu = m;
      });
    this.loadingSubMenu = true;
    this._menuService.getSubSlideOutMenu()
      .subscribe(m => {
        this.loadingSubMenu = false;
        this.subMenu = m;
      });
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  close() {
    this.closeMenu.emit();
  }

  menuTrackBy(i: number, m: MenuItem) {
    return m.id + m.url;
  }

}
