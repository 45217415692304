import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Product } from '../models/product.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }

  getAllProducts(): Observable<Product[]> {
    return this.getProducts({});
  }

  private getProducts(params: {[param: string]: string | string[]}): Observable<Product[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if products are cached from server
    const PRODUCTS_KEY = makeStateKey<APIListResponse<Object>>('paymentsources-' + p.toString());
    if (this._transferState.hasKey(PRODUCTS_KEY)) {
      const products = this._transferState.get<APIListResponse<Object>>(PRODUCTS_KEY, null);
      this._transferState.remove(PRODUCTS_KEY);
      return of(products.results.map((s) => new Product(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/products/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the products
        if (!this._platform.isBrowser) {
          this._transferState.set(PRODUCTS_KEY, res);
        }
      }),
      map((res) => res.results.map((s) => new Product(s)))
    );
  }
}
