import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { GenreComponent } from './genre/genre.component';
import { MoodComponent } from './mood/mood.component';
import { BrowseComponent } from './browse/browse.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivityComponent } from './activity/activity.component';
import { MessagesComponent } from './messages/messages.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SupportPageComponent } from './support-page/support-page.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';

import { AuthGuard } from './shared/guards/auth.guard';
import { NotArtistGuard } from './shared/guards/not-artist.guard';

const routes: Routes = [
  { path: '', component: HomeComponent, canActivate: [ NotArtistGuard ] },
  { path: 'browse', component: BrowseComponent, canActivate: [AuthGuard, NotArtistGuard ] },
  { path: 'browse/:trackId', component: BrowseComponent },
  { path: 'favorites', loadChildren: './favorites/favorites.module#FavoritesModule', canActivate: [ AuthGuard, NotArtistGuard ] },
  { path: 'genre/:genreId', component: GenreComponent, canActivate: [AuthGuard, NotArtistGuard ] },
  { path: 'mood/:moodId', component: MoodComponent, canActivate: [AuthGuard, NotArtistGuard ] },
  { path: 'playlists', loadChildren: './playlists/playlists.module#PlaylistsModule',canActivate: [ AuthGuard, NotArtistGuard ] },
  { path: 'playlist/:playlistId', component: PlaylistComponent,canActivate: [ AuthGuard, NotArtistGuard ] },
  { path: 'shared-playlist/:playlistId', component: PlaylistComponent,canActivate: [ NotArtistGuard ] },
  { path: 'artist/:artistId', loadChildren: './artist/artist.module#ArtistModule', canActivate: [ AuthGuard, NotArtistGuard ] },
  { path: 'account', loadChildren: './account/account.module#AccountModule', canActivate: [ AuthGuard,NotArtistGuard ] },
  { path: 'spotlight', loadChildren: './spotlight/spotlight.module#SpotlightModule', canActivate: [ AuthGuard,NotArtistGuard ] },
  { path: 'activity', component: ActivityComponent, canActivate: [ AuthGuard, NotArtistGuard ] },
  { path: 'messages', component: MessagesComponent, canActivate: [ AuthGuard, NotArtistGuard ] },
  { path: 'downloads', component: DownloadsComponent, canActivate: [ AuthGuard, NotArtistGuard ]},
  { path: 'downloads/:trackId', component: DownloadsComponent, canActivate: [ AuthGuard, NotArtistGuard ]},
  { path: 'faqs', component: FaqsComponent,canActivate: [ NotArtistGuard ] },
  { path: 'support', component: SupportPageComponent,canActivate: [ NotArtistGuard ] },
  { path: 'reset-password', component: ResetPasswordComponent, canActivate: [ NotArtistGuard ] },
  { path: 'about', component: AboutComponent, canActivate: [ NotArtistGuard ] },
  { path: 'contact', component: ContactComponent,canActivate: [ NotArtistGuard ] },
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, {
    initialNavigation: 'enabled',
    scrollPositionRestoration: 'enabled'
  }),],
  exports: [RouterModule]
})
export class AppRoutingModule { }
