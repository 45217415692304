import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { environment } from '../../environments/environment';
import { ContactRequest } from '../shared/models/contactrequest.model';
import { ContactRequestService } from '../shared/services/contactrequest.service';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contact = new ContactRequest(null);
  loading = false;
  success = false;
  errors = [];
  constructor(
    private _contactRequestService: ContactRequestService,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _historyService: HistoryService
  ) { }

  ngOnInit() {

    const title = this._pageTitleService.createPageTitle(['Contact']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
    this._meta.addTags([
      {name: 'description', content: description},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: '@MIBE_music'},
      {name: 'twitter:title', content: `Contact MIBE`},
      {name: 'twitter:description', content: description},
      {name: 'twitter:image', content: image},
      {name: 'og:type', content: 'website'},
      {name: 'og:title', content: `Contact`},
      {name: 'og:url', content: `${environment.protocol}://${environment.host}/contact`},
      {name: 'og:image', content: image},
      {name: 'og:description', content: description}
    ]);
    this._analyticsService.pageView();

    this._historyService.addItemToHistory('Contact');
  }
submited:boolean = false
  submit() {
    this.submited = true;
    if (!this.contact.phone_number.startsWith('+')) {
      this.contact.phone_number = `+1${this.contact.phone_number}`;
    }
    this.loading = true;
    this._contactRequestService.createContactRequest(this.contact)
      .subscribe(
        _ => {
          this.submited = true;
          this.loading = false;
          this.success = true;
        },
        err => {
          // console.log(err.error);
          
          this.errors = ['There was an error submitting your request, please try again later.'];
          this.loading = false;
        }
      )
  }
  validateNumber(event) {
    const keyCode = event.keyCode;
    const excludedKeys = [8, 37, 39, 46];
    if ((event.key === ' ' || isNaN(Number(event.key)))&&!((
      (keyCode >= 96 && keyCode <= 105) ||
      (excludedKeys.includes(keyCode))))) {
      event.preventDefault();
    }
  }
}
