import { Component, OnInit, Input } from '@angular/core';
import { Playlist } from '../../shared/models/playlist.model';

@Component({
  selector: 'app-playlistcover',
  templateUrl: './playlistcover.component.html',
  styleUrls: ['./playlistcover.component.scss']
})
export class PlaylistcoverComponent implements OnInit {

  @Input()
  playlist: Playlist;

  get backgroundImage(): string {
    let backgroundImage = '';
    if (this.playlist && this.playlist.thumb_image) {
      backgroundImage = `url(${this.playlist.thumb_image})`;
    } else if (this.playlist && this.playlist.marquee_image) {
      backgroundImage = `url(${this.playlist.marquee_image})`;
    }
    return backgroundImage;
  }

  constructor() { }

  ngOnInit() {
  }

}
