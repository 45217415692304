import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Meta, Title } from '@angular/platform-browser';
import { environment } from '../../environments/environment';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { GetTracksOptions } from '../shared/services/track.service';
import { PageTitleService } from '../shared/services/page-title.service';
import { Mood } from '../shared/models/mood.model';
import { MoodService } from '../shared/services/mood.service';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';

@Component({
  selector: 'app-mood',
  templateUrl: './mood.component.html',
  styleUrls: ['./mood.component.scss']
})
export class MoodComponent implements OnInit, OnDestroy {

  @ViewChild('searchActionField', {static: false})
  searchActionField: ElementRef;
  searchControl = new FormControl();
  loading = false;
  set mood(m: Mood) {
    this._mood = m;
    this._moodService.updateCurrentlyBrowsingMood(m);
    this.getTracksOptions = {
      moodId: m ? m.id : undefined
    };
    if (m) {
      // Set SEO Stuff
      const title = this._pageTitleService.createPageTitle(['Mood', m.name]);
      this._title.setTitle(title);
      const description = m.description;
      const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
      this._meta.addTags([
        {name: 'description', content: description},
        {name: 'twitter:card', content: 'summary'},
        {name: 'twitter:site', content: '@MIBE_music'},
        {name: 'twitter:title', content: `${m.name} Mood on Mibe`},
        {name: 'twitter:description', content: description},
        {name: 'twitter:image', content: image},
        {name: 'og:type', content: 'website'},
        {name: 'og:title', content: `${m.name} Mood`},
        {name: 'og:url', content: `${environment.protocol}://${environment.host}/mood/${m.id}`},
        {name: 'og:image', content: image},
        {name: 'og:description', content: description}
      ]);
    }
  }
  get mood(): Mood {
    return this._mood;
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      if (this.searchControl.value != s) {
        this.searchControl.setValue(s);
      }
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: {
        'search': s
      }, queryParamsHandling: 'merge'});
    }
  }

  get getTracksOptions(): GetTracksOptions {
    return this._getTracksOptions;
  }
  set getTracksOptions(o: GetTracksOptions) {
    this._getTracksOptions = o;
  }

  private _mood: Mood;
  private _searchString = '';
  private _subscriptions: Subscription[] = [];
  private _getMoodSubscription: Subscription;
  private _getTracksOptions: GetTracksOptions;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _platform: Platform,
    private _moodService: MoodService,
    private _renderer: Renderer,
    private _pageTitleService: PageTitleService,
    private _title: Title,
    private _meta: Meta,
    private _analyticsService: AnalyticsService,
    private _historyService: HistoryService
  ) {
  }

  ngOnInit() {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params=> {
        // Load Filters from URL
        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }

        this._historyService.addItemToHistory('Mood');
      })
    );
    this._subscriptions.push(
      this._activatedRoute.paramMap.subscribe(params => {
        // Load mood from routing data or URL
        let mood: Mood;
        if (this._platform.isBrowser && window.history.state && window.history.state.data) {
          mood = new Mood(window.history.state.data);
          if (!mood.id) { mood = null; }
          this.mood = mood;
        }
        let moodId = params.get('moodId');
        let moodIdN: number;
        if (moodId) {
          moodIdN = parseInt(moodId);
        }
        if (this.mood && moodIdN == this.mood.id) {
          this.loading = false;
          return;
        }
        this.loadMood(moodIdN);
      })
    );
    this._subscriptions.push(
      this.searchControl.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(v => {
        this.searchString = v;
      })
    );
    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
    this._moodService.updateCurrentlyBrowsingMood(null);
  }

  loadMood(moodId: number) {
    this.loading = true;
    if (this._getMoodSubscription && !this._getMoodSubscription.closed) {
      this._getMoodSubscription.unsubscribe();
    }
    this._getMoodSubscription = this._moodService.getMood(moodId).subscribe(m => {
      this.mood = m;
      this.loading = false;
    });
  }

  searchAction() {
    if (this.searchActionField) {
      this._renderer.invokeElementMethod(this.searchActionField.nativeElement, 'blur');
    }
  }

}
