import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription } from 'rxjs';

import { TrackService } from '../services/track.service';
import { Track } from '../models/track.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { Mood } from '../models/mood.model';
import { MetaData } from '../models/metadata.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { GenreService } from '../services/genre.service';

export interface DialogData {
  track: Track;
}

@Component({
  selector: 'app-trackinfo',
  templateUrl: './trackinfo.component.html',
  styleUrls: ['./trackinfo.component.scss']
})
export class TrackInfoComponent implements OnInit, OnDestroy {

  loading = false;

  private _subscriptions: Subscription[] = [];

  constructor(
    private _trackService: TrackService,
    private http: HttpClient,
    private _router: Router,
    public _genreService: GenreService,
    public dialogRef: MatDialogRef<TrackInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
  //  console.log(this.data)
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close();
  }

  moodsTrackBy(i: number, mood: Mood) {
    return mood.id + mood.name;
  }

  metadataTrackBy(_: number, metaData: MetaData) {
    return metaData.id + metaData.keyword;
  }

  composerTrackBy(i: number, composer: TrackComposer) {
    return composer.composer_name + composer.composer_percentage;
  }
  getTags(track: Track){
    var instrument = [];
    var tv = [];
    var sonic = [];
    var supportTvNw = [];
    var supportSovic = [];
    var ins = []
    if(track.sonic_references.length !== 0){
      sonic = track.sonic_references.map(x => x.name)
    }
    if(track.instrumentation.length !== 0){
      instrument = track.instrumentation.map(x => x.name)
    }
    if(track.tv_nw_references.length !== 0){
      tv = track.tv_nw_references.map(x => x.name)
    }
    if(track['support_tv_nw_references'].length !== 0){
      supportTvNw = track['support_tv_nw_references'].map(x => x.name)
    }
    if(track['support_sonic_band_references'].length !== 0){
      supportSovic = track['support_sonic_band_references'].map(x => x.name)
    }
    if(track['version_instrumentation'].length !== 0){
      ins = track['version_instrumentation'].map(x => x.name)
    }
    
    return [...new Set([...instrument, ...tv, ...sonic, ...supportSovic, ...supportTvNw, ...ins])]
  }
  gotoSubGenere(perm){
    this._genreService.getSubGenres(this.data.track.genre.id).subscribe(x => {
     
        let getSub = x.filter(s => s.id === perm.id)
        if(getSub.length !== 0){
          // console.log(getSub[0])  
          this._trackService.CURRENTSubGenreSubject.next(getSub[0]) 
          this.close()    
      }
    })
    // console.log(perm)
    // this._trackService.CURRENTSubGenreSubject.next(perm)
    // if(perm.parent_genre){
    //   perm['parent'] = perm.parent_genre
    // }
   
    // this._router.navigate(
    //   ['/genre', this.data.track.genre.id],
    //   {state: {data: this.data.track.genre.toJSON()},queryParams:{sub : perm.parent_genre.id} }
    // );
   
   
  }

  
}
