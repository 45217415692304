import { MibeModel } from './mibemodel.model';

export class TrackType implements MibeModel {
  id: number;
  name: string;

  /**
   * Create a TrackType.
   * @param {any} json - The JSON object with which to construct our TrackType.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackType.
   * @param {any} json - The JSON object with which to load our TrackType.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
  }
  /**
   * Returns a JSON representation of our TrackType
   * @return {any} - The JSON representation of our TrackType
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name
    };
    return json;
  }
}
