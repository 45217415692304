import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { PaymentSource } from '../models/paymentsource.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class PaymentSourceService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }

  getAllPaymentSources(): Observable<PaymentSource[]> {
    return this.getPaymentSources({});
  }

  createPaymentSource(token: string): Observable<PaymentSource> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/paymentsources/',
      {
        "stripe_token": token
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      let paymentSource = new PaymentSource(res);
      return paymentSource;
    }));
  }

  updatePaymentSource(paymentSource: PaymentSource): Observable<PaymentSource> {
    let payload = paymentSource.toJSON();
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<Object>(
      environment.apiURL + '/api/v1/paymentsources/' + paymentSource.id + '/',
      payload,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      paymentSource.loadWithJSON(res);
      return paymentSource;
    }));
  }

  deletePaymentSource(paymentSource: PaymentSource): Observable<PaymentSource> {
    let headers = new HttpHeaders();
    return this.http.delete<Object>(
      environment.apiURL + '/api/v1/paymentsources/' + paymentSource.id + '/',
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return new PaymentSource(res);
    }));
  }

  private getPaymentSources(params: {[param: string]: string | string[]}): Observable<PaymentSource[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if payment sources are cached from server
    const PAYMENT_SOURCES_KEY = makeStateKey<APIListResponse<Object>>('paymentsources-' + p.toString());
    if (this._transferState.hasKey(PAYMENT_SOURCES_KEY)) {
      const paymentSources = this._transferState.get<APIListResponse<Object>>(PAYMENT_SOURCES_KEY, null);
      this._transferState.remove(PAYMENT_SOURCES_KEY);
      return of(paymentSources.results.map((s) => new PaymentSource(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/paymentsources/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the payment sources
        if (!this._platform.isBrowser) {
          this._transferState.set(PAYMENT_SOURCES_KEY, res);
        }
      }),
      map((res) => res.results.map((s) => new PaymentSource(s)))
    );
  }
}
