import {
  Directive,
  HostListener,
  Input,
  Injector,
  OnDestroy
} from '@angular/core';
import {
  Overlay,
  OverlayRef,
  PositionStrategy
} from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { Track } from '../../models/track.model';
import { User } from '../../models/user.model';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';
import { AlbumPopoverComponent } from '../../albumpopover/albumpopover.component';
import { ALBUM_POPOVER_DIALOG_DATA } from '../../tokens/albumpopover.tokens';


@Directive({
  selector: '[appAlbumPopoverTriggerFor]'
})
export class AlbumPopoverTriggerForDirective implements OnDestroy {

  @Input('appAlbumPopoverTriggerFor')
  set track(v: Track) {
    if ((!v && !this._track) || (v && this._track && v.id == this._track.id)) {
      return;
    }
    // TODO Close any open overlay
    this._track = v;
  }
  get track(): Track {
    return this._track;
  }

  currentUser: User;

  private _track: Track;
  private _overlayRef: OverlayRef;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    private _userService: UserService,
    private _dialogService: DialogService,
  ) {
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  @HostListener('click', [])
  onClick(){
    if (!this.track) { return; }
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
      return;
    }

    if (!this.currentUser) {
      this._dialogService.displayLoginForm().subscribe(u => {
        if (u) { this.onClick(); }
      });
      return;
    }

    let position: PositionStrategy;
    position = this._overlay.position()
      .global()
      .centerVertically()
      .centerHorizontally();
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this._overlay.create({
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: 'albumpopover-backdrop',
      panelClass: 'albumpopover',
      scrollStrategy: this._overlay.scrollStrategies.block(),
      positionStrategy: position
    });

    // Create Injector for Component
    const injectionTokens = new WeakMap();

    injectionTokens.set(ALBUM_POPOVER_DIALOG_DATA, this.track);
    injectionTokens.set(OverlayRef, overlayRef);

    const injector = new PortalInjector(this._injector, injectionTokens);

    // Create ComponentPortal that can be attached to a PortalHost
    const albumPopoverPortal = new ComponentPortal(AlbumPopoverComponent, null, injector);

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(albumPopoverPortal);

    this._subscriptions.push(overlayRef.backdropClick().subscribe(_ => {
      overlayRef.dispose();
      this._overlayRef = null;
    }));
    this._overlayRef = overlayRef;
  }

}
