import { Component, OnInit, Input } from '@angular/core';
import { Genre } from '../models/genre.model';

@Component({
  selector: 'app-pagemarquee',
  templateUrl: './pagemarquee.component.html',
  styleUrls: ['./pagemarquee.component.scss']
})
export class PageMarqueeComponent implements OnInit {

  @Input()
  set image(i: string) {
    if (!i) {
      i = '/assets/img/soundboard-page-marquee.jpg';
    }
    this._image = i;
  }
  get image(): string {
    return this._image;
  }

  @Input()
  subTitle: string;

  @Input()
  title: string;

  @Input()
  prev: Genre;

  @Input()
  next: Genre;

  private _image: string;

  constructor() { }

  ngOnInit() {
    // console.log(this.title)

  }

}
