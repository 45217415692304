import { Component, OnInit, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { User } from '../shared/models/user.model';
import { HistoryService } from '../shared/services/history.service';
import { UserService } from '../shared/services/user.service';
import { DialogService } from '../shared/services/dialog.service';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';

export const featuredOnIcons = [
  '/assets/svg/featured-on/ABC.svg',
  '/assets/svg/featured-on/A&E.svg',
  '/assets/svg/featured-on/AMC.svg',
  '/assets/svg/featured-on/Bravo.svg',
  '/assets/svg/featured-on/CBS.svg',
  '/assets/svg/featured-on/Discovery.svg',
  '/assets/svg/featured-on/E!.svg',
  '/assets/svg/featured-on/ESPN.svg',
  '/assets/svg/featured-on/FOX.svg',
  '/assets/svg/featured-on/HBO.svg',
  '/assets/svg/featured-on/MTV.svg',
  '/assets/svg/featured-on/Nat Geo.svg',
  '/assets/svg/featured-on/NBC.svg',
  '/assets/svg/featured-on/Nickelodean.svg',
  '/assets/svg/featured-on/Showtime.svg',
  '/assets/svg/featured-on/Spike.svg',
  '/assets/svg/featured-on/Tru TV.svg',
  '/assets/svg/featured-on/VH1.svg',
  '/assets/svg/featured-on/Vice.svg',
]
@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit, OnDestroy {

  featuredOnIcons = featuredOnIcons;
  currentUser: User;
  loadingCurrentUser = true;
aboutdetails:any;
  private _subscriptions:Subscription[] = [];
  constructor(
    private _dialogService:DialogService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _userService:UserService,
    private _analyticsService: AnalyticsService,
    private _historyService: HistoryService
  ) { }

  ngOnInit() {
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u =>{
        this.currentUser = u;
        this.loadingCurrentUser = false;
      })
    );
    this._analyticsService.pageView();

    const title = this._pageTitleService.createPageTitle(['About']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    const image = `${environment.protocol}://${environment.host}/assets/img/about-features.png`;
    this._meta.addTags([
      {name: 'description', content: description},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: '@MIBE_music'},
      {name: 'twitter:title', content: 'About MIBE'},
      {name: 'twitter:description', content: description},
      {name: 'twitter:image', content: image},
      {name: 'og:type', content: 'website'},
      {name: 'og:title', content: 'About'},
      {name: 'og:url', content: `${environment.protocol}://${environment.host}/about`},
      {name: 'og:image', content: image},
      {name: 'og:description', content: description}
    ]);

    this._historyService.addItemToHistory('About');
    this._userService.getAboutDetails().subscribe(about =>{
      this.aboutdetails = about;
    })
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  showVideo() {
    this._dialogService.displayVideo('/path/to/video');
  }

  openRegister() {
    this._dialogService.displayRegisterForm();
  }

}
