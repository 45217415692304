import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgScrollbarModule } from 'ngx-scrollbar';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxStripeModule } from 'ngx-stripe';

import { NgxJsonLdModule } from '@ngx-lite/json-ld';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './home/home.component';
import { GenreComponent } from './genre/genre.component';
import { MoodComponent } from './mood/mood.component';
import { BrowseComponent } from './browse/browse.component';
import { PlaylistComponent } from './playlist/playlist.component';
import { ArtistModule } from './artist/artist.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ActivityComponent } from './activity/activity.component';
import { MessagesComponent } from './messages/messages.component';
import { DownloadsComponent } from './downloads/downloads.component';
import { AboutComponent } from './about/about.component';
import { ContactComponent } from './contact/contact.component';
import { FaqsComponent } from './faqs/faqs.component';
import { SupportPageComponent } from './support-page/support-page.component';
import { MatIconModule } from '@angular/material';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    GenreComponent,
    MoodComponent,
    BrowseComponent,
    PlaylistComponent,
    ResetPasswordComponent,
    ActivityComponent,
    MessagesComponent,
    DownloadsComponent,
    AboutComponent,
    ContactComponent,
    FaqsComponent,
    SupportPageComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    NgxStripeModule.forRoot(environment.stripe_key),
    NgxJsonLdModule,
    HttpClientModule,
    BrowserTransferStateModule,
    AppRoutingModule,
    SharedModule,
    MatDialogModule,
    DragDropModule,
    BrowserAnimationsModule,
    NgScrollbarModule,
    ArtistModule,
    MatIconModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production && environment.enableSW })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
