import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { DialogService } from '../services/dialog.service';
import { ConfirmPasswordStateMatcher } from '../../account/account-profile/account-profile.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
loginButtonEnable:boolean = false;
  @Output()
  openRegister = new EventEmitter();
  success:boolean = false
  @Output()
  openForgotPassword = new EventEmitter();
  confirmPasswordMatcher: ConfirmPasswordStateMatcher;
  loading = false;
  passwordInputType = 'password';
  loginModel = {
    email: '',
    password: '',
    rememberMe: false
  };
  loginShow:boolean = true;
  errors: string[] = [];
  confirmPasswordInputType = 'password';

  constructor(
    private _userService: UserService,
    public _dialogService: DialogService,
    public dialogRef: MatDialogRef<LoginComponent>
  ) { 
    this.confirmPasswordMatcher = new ConfirmPasswordStateMatcher(this.registerExtras);
  }

  ngOnInit() {
    // console.log(this._userService.nextState)
  }
  registerExtras = {
    password: '',
    confirmPassword: '',
    newsletterSignup: false,
    notes: '',
    portfolio_link: ''
  };
  toggleConfirmPasswordInputType() {
    if (this.confirmPasswordInputType == 'password') {
      this.confirmPasswordInputType = 'text';
    } else {
      this.confirmPasswordInputType = 'password';
    }
  }
  togglePasswordInputType() {
    if (this.passwordInputType == 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }
  loginCheck(){
    if(this.loginModel.email != ""){
      this.errors = []
      this._userService.loginCheck(
        this.loginModel.email,
        this.loginModel.password,
        this.loginModel.rememberMe
      ).subscribe(x=>{
        this.loginButtonEnable = true;
      },(error)=>{
       this.loginButtonEnable = false;
        if(error.error.is_available == 'false'){
          this.errors.push(error.error.error);
        }
        else{
          if(error.error.email){
            this.forgotPassword(error.error.email)
          }
          // this.loginShow = false;
        }
       
      })
    }
    
  }
  setPassword(){
    this.errors = []
    this._userService.setPassword(
     this.loginModel.email,
      this.registerExtras.confirmPassword,
    ).subscribe(x=>{
      this.login(2)
    },(error)=>{
      // console.log(error)
      ;
      this.loginShow = false;
      this.errors.push(error.error.error);
    })
  }
  login(type) {
    this.errors = [];
    this.loading = true;
    ;
    let pass = type == '2' ? this.registerExtras.confirmPassword : this.loginModel.password
    this._userService.loginUser(
      this.loginModel.email,
     pass,
      this.loginModel.rememberMe
    ).subscribe((currentUser) => {
      localStorage.setItem('remember',JSON.stringify(this.loginModel.rememberMe))
      localStorage.setItem('status','status')
      if(!currentUser){
        this.loading = false;
        this.errors.push('Invalid User Please Contact Admin');
        return false
      }
      this.loading = false;
      this.dialogRef.close(currentUser);

      this._userService.getAuthToken(
      
        currentUser.email,
        pass,
        this.loginModel.rememberMe
      )
      .subscribe(x=>{
        ;
        if(x.tok.is_client){
          window.location.reload();
        }
      
      })
    },error =>{
      this.loading = false;
      this.errors.push(error.error.error);
      // console.log(error)
    });
  }

  openRegisterForm() {
    this.openRegister.emit();
  }

  openForgotPasswordForm() {
    this.openForgotPassword.emit();
  }

  forgotPassword(email?) {
    this.errors = [];
    this.loading = true;
    this._userService.forgotPassword(
      email? email :this.loginModel.email
    ).subscribe((currentUser) => {
      this.loading = false;
      this.success = true;
    }, (err) => {
      this.loading = false;
      this.errors.push('Error resetting your password, check your email and try again!');
      console.error(err);
    });
  }
  close(){
    this.success = false;
    this.loginShow = true;
  }
}
