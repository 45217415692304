import { MibeModel } from './mibemodel.model';

export class UserPRO implements MibeModel {
  id: number;
  company_name: string;
  cae_ipi: string;
  pro: number;
  ein: string;
  ssn: string;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.company_name = json.company_name;
    this.cae_ipi = json.cae_ipi;
    this.pro = json.pro;
    this.ein = json.ein;
    this.ssn = json.ssn;
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'company_name': this.company_name,
      'cae_ipi': this.cae_ipi,
      'pro': this.pro,
      'ein': this.ein,
      'ssn': this.ssn
    };
    return json;
  }
}
