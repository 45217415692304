import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { DataLayerCustomDimensionsService, PartialCustomDimensionsSet } from './data-layer-custom-dimensions.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  constructor(
    private _dataLayerCustomDimensions: DataLayerCustomDimensionsService,
    private _router: Router,
    private _platform: Platform
  ) {}

  updateCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
    this._dataLayerCustomDimensions.dimensions = someDimensions;
  }

  pageView(overridePath?: string) {
    this._dataLayerCustomDimensions.trigger();
    if (!this._platform.isBrowser) { return; }
    (<any>window).dataLayer.push({
      event: 'virtualPageview',
      virtualPageURL: overridePath || this._router.url,
      virtualPageTitle: overridePath || this._router.url,
    });
  }

  pageViewWithCustomDimensions(someDimensions: PartialCustomDimensionsSet) {
    this.updateCustomDimensions(someDimensions);
    this.pageView();
  }
}
