import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedModule } from '../shared/shared.module';
import { ArtistRoutingModule } from './artist-routing.module';
import { ArtistComponent } from './artist.component';
import { ArtistTracksComponent } from './artisttracks/artisttracks.component';
import { ArtistAboutComponent } from './artist-about/artist-about.component';
import { ArtistAlbumsComponent } from './artist-albums/artist-albums.component';


@NgModule({
  declarations: [ArtistComponent, ArtistTracksComponent, ArtistAboutComponent, ArtistAlbumsComponent],
  imports: [
    CommonModule,
    ArtistRoutingModule,
    SharedModule
  ]
})
export class ArtistModule { }
