import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';
import {
  Observable,
  Subscription,
  BehaviorSubject,
  combineLatest
} from 'rxjs';
import { map, first } from 'rxjs/operators';
import { Platform } from '@angular/cdk/platform';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { PlayerService } from '../services/player.service';
import { TrackVersionService } from '../services/trackversion.service';
import { UserService } from '../services/user.service';
import { PlayingItem } from '../services/player.service';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Mood } from '../models/mood.model';
import { DialogService } from '../services/dialog.service';
import { User } from '../models/user.model';
import { Artist } from '../models/artist.model';
import { HttpClient, HttpHeaders, HttpEventType } from '@angular/common/http';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';

import { Router } from '@angular/router';
import { DownloadService } from '../services/download.service';
import { Download } from '../services/download';

import { saveAs } from 'file-saver';
import { PlaylistService } from '../services/playlist.service';
import { DownloadPlaylistPopComponent } from '../download-playlist-pop/download-playlist-pop.component';


declare type TrackListDataSource = Observable<ReadonlyArray<Track>>;

export interface ReorderedResult {
  track: Track;
  previousIndex: number;
  currentIndex: number;
}

export class TracklistRow {
  constructor(
    public track: Track,
    public version: TrackVersion,
    public main: boolean,
    public playing: boolean,
   
  ) {}
}

@Component({
  selector: 'app-tracklist',
  templateUrl: './tracklist.component.html',
  styleUrls: ['./tracklist.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0'
        }),
        animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)', style({
          opacity: '1',
          height: '*'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)', style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          paddingBottom: '0'
        }))
      ])
    ])
  ]
})
export class TracklistComponent implements OnInit, OnDestroy {
dataset:any;
currentIndex:any;
playingVersion:any;
  @Input()
  set enableReordering(e: boolean) {
    this._enableReordering = e;
    if (e) {
      this.expandedTrackSubject.next(null);
      this._playerService.updateIsPlaying(false);
    }
  }
  get enableReordering(): boolean {
    return this._enableReordering;
  }
@Input() totalTracks:any;
  @Input()
  set enableDragging(e: boolean) {
    this._enableDragging = e;
    if (e) {
      this.expandedTrackSubject.next(null);
    }
  }

  get enableDragging(): boolean {
    return this._enableDragging;
  }

  get dragAxisLock(): string {
    if (this.enableReordering && this.enableDRag) { return 'y'; }
    return null;
  }

  @Input()
  dropLists: string[] = [];

  @Input()
  dropListId: string;

  @Output()
  reordered = new EventEmitter<ReorderedResult>();

  @Output()
  unsubscribeChange = new EventEmitter()
  @Input()
  set dataSource(data: TrackListDataSource) {
    this.dataset = data;
    // console.log(data)
    this._dataSource = combineLatest(
      data,
      this.expandedTrack,
      this._playerService.isPlaying,
      this._playerService.currentlyPlaying
    ).pipe(
      map(([tracks, expandedTrack, isPlaying, currentlyPlaying]) => {
        let retval: TracklistRow[] = [];
        tracks.forEach((t:Track) => {
        
          if(t.versions){
            for (let i = 0, tot = t.versions.length; i < tot; i++) {
              let v = t.versions[i];
              if (i == 0 || (
                expandedTrack && expandedTrack.id == t.id)
              ) {
                let row = new TracklistRow(
                  t,
                  v,
                  i == 0,
                  currentlyPlaying &&
                  currentlyPlaying.track.id == t.id &&
                  currentlyPlaying.version.id == v.id &&
                  isPlaying
                );
                retval.push(row);
              }
            }
          }
          
        });
        return retval;
      })
    );
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
    this._dataSourceSubscription = combineLatest(
      data,
      this.expandedTrack,
      this._playerService.currentlyPlaying
    ).subscribe(([tracks, expandedTrack, currentlyPlaying]) => {
      if (!currentlyPlaying || !currentlyPlaying.track || !currentlyPlaying.version) {
        this._playerService.updatePlaybackContextList([]);
        return;
      }
      let list: PlayingItem[] = [];
      let playingItemInList = false;
      for (let i = 0, tot = tracks.length; i < tot; i++) {
        let t = tracks[i];
        for (let j = 0, totJ = t.versions.length; j < totJ; j++) {
          let v = t.versions[j];
          if (currentlyPlaying.track.id == t.id && currentlyPlaying.version.id == v.id) {
            playingItemInList = true;
          }
          if (j == 0 || (
            expandedTrack && expandedTrack.id == t.id)
          ) {
            list.push(new PlayingItem(t, v));
          }
        }
      }
      if (playingItemInList) {
        this._playerService.updatePlaybackContextList(list);
      }
    });
  }

  get listDataSource(): Observable<ReadonlyArray<TracklistRow>> {
    return this._dataSource;
  }

  set playbackProgress(p: number) {
    this._playbackProgress = p;
    this._playerService.updatePlaybackProgress(this._playbackProgress);
  }
  get playbackProgress(): number {
    return this._playbackProgress;
  }

  // Enable delete track button if the playlist is not shared playlist
  @Input()
  set enableDeleteTrackButton(e: boolean) {
    this._enableDeleteTrackButton = e;
  }

  get enableDeleteTrackButton(): boolean {
    return this._enableDeleteTrackButton;
  }
  expandedTrack: Observable<Track> = null;
  expandedTrackSubject = new BehaviorSubject<Track>(null);
  currentUser: User;
  currentArtists: Artist[] = [];

  dragDelay = 0;

  private _playbackProgress = 0;
  public _dataSource: Observable<ReadonlyArray<TracklistRow>>;
  private _subscriptions: Subscription[] = [];
  private _dataSourceSubscription: Subscription = null;
  private _enableReordering = false;
  private _enableDragging = false;
  private _enableDeleteTrackButton = false;
  public enableDRag = false;
  currentVersion:TrackVersion;
  constructor(
    private _playerService: PlayerService,
    private _trackVersionService: TrackVersionService,
    private _userService: UserService,
    private _dialogService: DialogService,
    private _platform: Platform,
    private http:HttpClient,
    private router: Router,
    private downloads: DownloadService,
    private _playlistServic: PlaylistService
  ) {
    this.expandedTrack = this.expandedTrackSubject.asObservable();
  }
currentPlaying:any;
  ngOnInit() {
    if(this.router.url.includes('playlist')){
     this.enableDRag = true
    }
        // console.log(this.router.url);
    var userDetails = JSON.parse(localStorage.getItem('user'))
    if(userDetails){
      let user  = new User(userDetails);
      this.currentUser = user;
    }
    this._subscriptions.push(
      this._playerService.playbackProgress.subscribe((p) => {
        if (this._playbackProgress != p) {
          this.playbackProgress = p;
        }
      })
    );
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    if (this._platform.IOS || this._platform.ANDROID) {
      this.dragDelay = 500;
    }
    this._playerService.isPlaying.subscribe((p) => {
      // console.log(p)
     
     
    })
  }

  ngOnDestroy() {
    this.playingVersion = null;
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    if (this._dataSourceSubscription) {
      this._dataSourceSubscription.unsubscribe();
    }
  }

  playButtonPressed(track: Track, version: TrackVersion) {
    if(this._playerService.currentPlayingObject && this.playingVersion && version.id === this.playingVersion.id){
      this._playerService.playfromTrackSubject.next(true)
    }
    else{
      this._playerService.play(track, version);
      this.playingVersion = version
    }
    
  }

  pauseButtonPressed(track: Track, version: TrackVersion) {
    this._playerService.updateIsPlaying(false);
    this._playerService.pausebuttonPressed.next(true)
  }

  listTrackBy(index, item: TracklistRow) {
    return item.track.id + '-' + item.version.id;
  }

  expandTrack(track: Track) {
    // if (this.enableReordering && this.enableDRag) { return; }
    if (this.expandedTrackSubject.value && this.expandedTrackSubject.value.id == track.id) {
      this.expandedTrackSubject.next(null);
      return;
    }
    this.expandedTrackSubject.next(track);
  }

  trackIsExpanded(track: Track) {
    return this.expandedTrackSubject.value && this.expandedTrackSubject.value.id == track.id;
  }

  favoriteTrackVersion(version: TrackVersion) {
    if (this.currentUser) {
      this._trackVersionService.favoriteTrackVersion(version).subscribe();
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._trackVersionService.favoriteTrackVersion(version).subscribe();
      }
    });
  }

  unfavoriteTrackVersion(version: TrackVersion) {
    if (this.currentUser) {
      ;
      this._trackVersionService.unfavoriteTrackVersion(version).subscribe(x=>{
        this.unsubscribeChange.emit()
      });
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._trackVersionService.unfavoriteTrackVersion(version).subscribe();
      }
    });
  }

  shareTrack(track: Track) {
    if (this.currentUser) {
      this._dialogService.displayShareTrackForm(track);
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._dialogService.displayShareTrackForm(track);
      }
    });
  }

  displayTrackInfo(track: Track,row) {
    

     let headers = new HttpHeaders();
    this.http.get<any>(
      environment.apiURL + `/api/v1/track/${row.version.id}/get-track-info/`,
      {
        headers: headers
      }
    ).subscribe(x=>{
      let data = track;
      data['composserData']= x;
      // data['sub']= x.sub_genre;
      data['sub']= x.sub_genre_info;

      data['instrumentation']= x.version_instruments_desc;
      data['sonic_references']= x.version_sonic_references;
      data['tv_nw_references']= x.version_tv_nw_references;

      data['support_sonic_band_references']= x.support_sonic_band_references;
      data['support_tv_nw_references']= x.support_tv_nw_references;
      data['version_instrumentation'] = x.version_instrumentation;
      this._dialogService.displayTrackInfo(data);
    },
  error=>{
    this._dialogService.displaydisplayerror(error.error.Error,false)
  })
   
  }

  displayDownloadDialog(data: {track:Track, version:TrackVersion}) {
    // console.log(data)
    if (this.currentUser) {
      this._dialogService.displayDownloadDialog('track', data);
      return;
    }
    this._dialogService.displayLoginForm().subscribe(u => {
      if (u) {
        this._dialogService.displayDownloadDialog('track', data);
      }
    });
  }

  isFavorited(version: TrackVersion): boolean {
    return this.currentUser && version.favorited_by_me;
  }

  amOwner(track: Track): boolean {
    if (!this.currentUser) return false;
    return track.owned_by == this.currentUser.id;
  }

  artistFeatured(track: Track): boolean {
    return track.artist && track.artist.is_featured;
  }

  moodsTrackBy(i: number, mood: Mood) {
    return mood.id + mood.name;
  }

  onListDrop(event: CdkDragDrop<TracklistRow>) {
    // We have to get the data and compare the list index with the dragged item because
    // event.previousIndex doesn't update due to an issue https://github.com/angular/components/issues/14056
    this.listDataSource.pipe(first()).subscribe((data) => {
      let previousIndex = data.findIndex(d => d.track.id == event.item.data.track.id && d.version.id == event.item.data.version.id);
      this.reordered.emit({
        track: event.item.data.track,
        previousIndex: previousIndex,
        currentIndex: event.currentIndex
      });
    });
  }

  editTrackDetail(track: Track) {
    this._dialogService.displayCreateTrack(track);
  }

  addTrackVersion(track: Track) {
    this._dialogService.displayUploadVersion(track);
  }

  editVersionDetail(track: Track, version: TrackVersion) {
    this._dialogService.displayUploadVersion(track, version);
  }
  albumNavigate(id){
    return `/artist/${id}/albums`
  }
  percentDone:any;
  index:any;
  download$: Observable<Download>

  DownloadPlaylistPop(track,i){
    this.http.get(environment.apiURL+'/api/v1/user/current/').subscribe((x: User)=>{
      if(x.can_download){
        if(track.is_full_version === false){
          this.downloadSingleTrack(track)
              }
              else{
               
                this._dialogService.trackDowload(track)
              }
      }
      else{
          this._dialogService.displaydisplayerror('Access Denied',false)
      }
    })
   
  }
  downloadSingleTrack(track){
    
       let requestURL = environment.apiURL + `/api/v1/trackversions/${track.id}/downloadtrack/?id=${this.currentUser.id}&full_version_tracks=true`;
       window.open(requestURL, '_blank')
     }
  
  downloadFile(data: any, type: string, filename: any) {
    let fileName = `${filename}.wav`;
    let blob = new Blob([data], { type: 'application/octet-stream'});
    let url = window.URL.createObjectURL(blob);
    saveAs('https://d1fy9cxdgutbzc.cloudfront.net/media/uploads/original_audio_files/2022/05/07/8be3b972-211e-4840-bacc-c655d94eaf3e.wav', fileName);
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     //alert( 'Please disable your Pop-up blocker and try again.');
    // }
  }
getPercentage(p){
return `--value:95`
}
rateTrack(track,version){
  this._dialogService.displayratetrack(track,version);
}

// Delete track
deleteTrack(row, trackVersion, index){
  if(row.track.versions.length > 1 && row.main){
    this._dialogService.deleteTrack(trackVersion, row.main);
  } else{
    this._playlistServic.deletePlaylistTrack(trackVersion.id, false).subscribe((res:any)=>{
      window.location.reload();
    })
  }
}
}
