import { Injectable } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';


export interface MetaItem {
  name: string;
  content: string;
}


@Injectable({
  providedIn: 'root'
})
export class PageTitleService {

  mainSiteDescription = 'This is MIBE (Music Is Before Everything); a fresh approach to music licensing and sonic branding. With exclusive composers worldwide, we create content for some of the biggest advertisers, film producers, production companies and television networks in the game.';

  constructor(
    private _meta: Meta
  ) {}

  createPageTitle(titleComponents: string[]): string {
    let title = environment.page_title_prefix;
    if (!titleComponents || !titleComponents.length) { return title; }
    title = titleComponents.reduce((title, c) => {
      title = `${title} ${environment.page_title_delimiter} ${c}`;
      return title;
    }, title);
    return title;
  }

}
