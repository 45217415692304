import { MibeModel } from './mibemodel.model';

export class Mood implements MibeModel {
  id: number;
  name: string;
  description: string;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'description': this.description
    };
    return json;
  }
}
