import { Component, OnInit, OnDestroy, Inject, HostBinding, ViewChild, ElementRef } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  group,
  query,
  state
} from '@angular/animations';
import { MatInput } from '@angular/material/input';
import { OverlayRef, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { Subscription, BehaviorSubject } from 'rxjs';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Playlist } from '../models/playlist.model';
import { PlaylistService } from '../services/playlist.service';
import { PLAYLIST_POPOVER_DIALOG_DATA } from '../tokens/playlistpopover.tokens';
import { DialogService } from '../services/dialog.service';

function isFlexibleConnectedPositionStrategy(obj: any): obj is FlexibleConnectedPositionStrategy {
  return obj.positionChanges !== undefined
}

interface PlaylistDialogData {
  track: Track;
  version: TrackVersion;
}

@Component({
  selector: 'app-playlistpopover',
  templateUrl: './playlistpopover.component.html',
  styleUrls: ['./playlistpopover.component.scss'],
  animations: [
    trigger('popoverInOut', [
      transition(':enter', [
        group([
          query('.popover-container', [
            style({
              opacity: 0,
              transform: 'scale(0.8)'
            }),
            animate('120ms cubic-bezier(0, 0, 0.2, 1)', style({
              opacity: 1,
              transform: 'scale(1)'
            }))
          ])
        ])
      ]),
      transition(':leave', animate('100ms 25ms linear', style({opacity: 0})))
    ])
  ]
})
export class PlaylistPopoverComponent implements OnInit, OnDestroy {

  @HostBinding('@popoverInOut')
  public animateMenu = true;

  arrowAfter = false;
  arrowAbove = false;
  hideArrow = false;
  playlistFilter:any[] = []
  newPlaylistModel = {
    name: ''
  };
  creatingPlaylist = false;

  loading = true;
  playlists: Playlist[] = [];
  
  @ViewChild('myinput', {static: false}) myinput: ElementRef;
  focus() {
    this.myinput.nativeElement.focus();
  }

  @ViewChild('createPlaylistField', {static: false})
  set createPlaylistField(f: MatInput) {
    this._createPlaylistField = f;
    if (f) {
      setTimeout(() => {
        f.focus();
      });
    }
  }
  get createPlaylistField(): MatInput {
    return this._createPlaylistField;
  }

  private _subscriptions: Subscription[] = [];
  private _createPlaylistField: MatInput;
  
  constructor(
    @Inject(PLAYLIST_POPOVER_DIALOG_DATA) public dialogData: PlaylistDialogData,
    private _overlayRef: OverlayRef,
    private _playlistService: PlaylistService,
   
    private dialogueService: DialogService
  ) { }

  ngOnInit() {
    if (isFlexibleConnectedPositionStrategy(this._overlayRef.getConfig().positionStrategy)) {
      const positionStrategy = this._overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
      this._subscriptions.push(positionStrategy.positionChanges.subscribe(changes => {
        this.arrowAfter = changes.connectionPair.originX == 'start';
        this.arrowAbove = changes.connectionPair.originY == 'top';
      }));
      this.hideArrow = false;
    } else {
      this.hideArrow = true;
    }

    this.loading = true;
    this._playlistService.getEditablePlaylists(this.dialogData.version.id).subscribe(playlists => {
      this.loading = false;
      this.playlists = playlists.results;
      this.playlistFilter = this.playlists;
    });
    
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  playlistsTrackBy(i: number, playlist: Playlist) {
    return playlist.id + playlist.name;
  }

  createPlaylist() {
    this.creatingPlaylist = true;
  }
errorMsg:any;
  saveNewPlaylist() {

    if(this.newPlaylistModel.name != ''){
      this.errorMsg = null
      this.loading = true;
      this._playlistService.createPlaylist(this.newPlaylistModel.name).subscribe(p => {
        this.playlists.push(p);
        this.addTrackVersionToPlaylist(p);
        this._playlistService.addRemovePlaylistSubject.next(true)
        this.creatingPlaylist = false;
      },(error)=>{
        this.loading = false
        this.errorMsg = error
       
      }
   );
    }
   
  }

  addTrackVersionToPlaylist(p: Playlist) {
    this.loading = true;
    this._playlistService.addTrackVersionsToPlaylist(p.id, [this.dialogData.version.id]).subscribe(up => {
      this.loading = false;
      this._playlistService.addRemovePlaylistSubject.next(true)
      p.loadWithJSON(up.toJSON());
    });
  }

  removeTrackVersionFromPlaylist(p: Playlist) {
    this.loading = true;
    this._playlistService.removeTrackVersionsFromPlaylist(p.id, [this.dialogData.version.id]).subscribe(up => {
      this._playlistService.addRemovePlaylistSubject.next(true)
      this.loading = false;
      p.loadWithJSON(up.toJSON());
      this._playlistService.PlaylistRemoveSubject.next(true)
      this.close()

    });
  }

  toggleTrackVersionInPlaylist(p: Playlist) {
    if (p.containsTrackVersion(this.dialogData.version.id)) {
     
      this.removeTrackVersionFromPlaylist(p);
      
    } else {
      this.addTrackVersionToPlaylist(p);
    }
  }

  playlistContainsTrackVersion(p: Playlist): boolean {
    return p.containsTrackVersion(this.dialogData.version.id);
  }

  close() {
    this._overlayRef.dispose();
  }

  onSearchPlaylist(value) {
    if(value){
      this.playlistFilter = this.searchPlaylist(value);
    }
    else{
      this.playlistFilter = this.playlists;
    }
   
  }

  searchPlaylist(value: string) {
    let filterTempo = value.toLowerCase();
    return this.playlists.filter(option => option.name.toLowerCase().includes(filterTempo));
  }

}
