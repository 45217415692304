import {
  Directive,
  HostListener,
  Input,
  ElementRef,
  Injector,
  OnDestroy
} from '@angular/core';
import {
  Overlay,
  OverlayRef,
  HorizontalConnectionPos,
  VerticalConnectionPos,
  PositionStrategy
} from '@angular/cdk/overlay';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';
import { Subscription } from 'rxjs';
import { Track } from '../../models/track.model';
import { TrackVersion } from '../../models/trackversion.model';
import { User } from '../../models/user.model';
import { DialogService } from '../../services/dialog.service';
import { UserService } from '../../services/user.service';
import { PlaylistPopoverComponent } from '../../playlistpopover/playlistpopover.component';
import { PLAYLIST_POPOVER_DIALOG_DATA } from '../../tokens/playlistpopover.tokens';


@Directive({
  selector: '[appPlaylistPopoverTriggerFor]'
})
export class PlaylistPopoverTriggerForDirective implements OnDestroy {

  @Input('appPlaylistPopoverTriggerFor')
  set track(v: Track) {
    if ((!v && !this._track) || (v && this._track && v.id == this._track.id)) {
      return;
    }
    // TODO Close any open overlay
    this._track = v;
  }
  get track(): Track {
    return this._track;
  }

  @Input('appPlaylistPopoverTriggerForVersion')
  set version(v: TrackVersion) {
    if ((!v && !this._version) || (v && this._version && v.id == this._version.id)) {
      return;
    }
    // TODO Close any open overlay
    this._version = v;
  }
  get version(): TrackVersion {
    return this._version;
  }

  currentUser: User;

  private _track: Track;
  private _version: TrackVersion;
  private _overlayRef: OverlayRef;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _overlay: Overlay,
    private _injector: Injector,
    private _element: ElementRef<HTMLElement>,
    private _userService: UserService,
    private _dialogService: DialogService,
    private _breakpointObserver: BreakpointObserver
  ) {
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    this._subscriptions.push(this._breakpointObserver.observe([
      '(max-width: 767px)'
    ]).subscribe(result => {
      if (this._overlayRef) {
        this._overlayRef.dispose();
        this._overlayRef = null;
        return;
      }
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  @HostListener('click', [])
  onClick(){
    if (!this.track) { return; }
    if (this._overlayRef) {
      this._overlayRef.dispose();
      this._overlayRef = null;
      return;
    }

    if (!this.currentUser) {
      this._dialogService.displayLoginForm().subscribe(u => {
        if (u) { this.onClick(); }
      });
      return;
    }

    // Postiion strategy
    let [originX, originFallbackX]: HorizontalConnectionPos[] =
        ['end', 'start'];

    let [overlayY, overlayFallbackY]: VerticalConnectionPos[] =
        ['top', 'bottom'];

    let [originY, originFallbackY]: VerticalConnectionPos[] = ['bottom', 'top'];
    let [overlayX, overlayFallbackX] = [originX, originFallbackX];
    let offsetY = 0;
    let position: PositionStrategy;
    const isMobile = this._breakpointObserver.isMatched('(max-width: 767px)');
    if (isMobile) {
      position = this._overlay.position()
        .global()
        .centerVertically()
        .centerHorizontally();
    } else {
      position = this._overlay.position()
      .flexibleConnectedTo(this._element)
      .withLockedPosition()
      .withFlexibleDimensions(true)
      .withTransformOriginOn('.popover-container')
      .withPositions([
        {originX, originY, overlayX, overlayY, offsetY},
        {originX: originFallbackX, originY, overlayX: overlayFallbackX, overlayY, offsetY},
        {
          originX,
          originY: originFallbackY,
          overlayX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY
        },
        {
          originX: originFallbackX,
          originY: originFallbackY,
          overlayX: overlayFallbackX,
          overlayY: overlayFallbackY,
          offsetY: -offsetY
        }
      ]);
    }
    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this._overlay.create({
      hasBackdrop: true,
      disposeOnNavigation: true,
      backdropClass: 'playlistpopover-backdrop',
      panelClass: 'playlistpopover',
      scrollStrategy: isMobile ? this._overlay.scrollStrategies.block() : this._overlay.scrollStrategies.reposition(),
      positionStrategy: position
    });

    // Create Injector for Component
    const injectionTokens = new WeakMap();

    injectionTokens.set(PLAYLIST_POPOVER_DIALOG_DATA, {track: this.track, version: this.version});
    injectionTokens.set(OverlayRef, overlayRef);


    const injector = new PortalInjector(this._injector, injectionTokens);

    // Create ComponentPortal that can be attached to a PortalHost
    const playlistPopoverPortal = new ComponentPortal(PlaylistPopoverComponent, null, injector);

    // Attach ComponentPortal to PortalHost
    overlayRef.attach(playlistPopoverPortal);

    this._subscriptions.push(overlayRef.backdropClick().subscribe(_ => {
      overlayRef.dispose();
      this._overlayRef = null;
    }));
    this._overlayRef = overlayRef;
  }

}
