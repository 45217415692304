import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { SupportPage } from '../models/supportpage.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SupportPageService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getSupportPageBySlug(slug: string): Observable<SupportPage> {
    let p = new HttpParams({ fromObject: {} });
    // Check if support pages are cached from server
    const SUPPORT_PAGE_KEY = makeStateKey<any>('supportpage-' + p.toString());
    if (this._transferState.hasKey(SUPPORT_PAGE_KEY)) {
      const support_page = this._transferState.get<any>(SUPPORT_PAGE_KEY, null);
      this._transferState.remove(SUPPORT_PAGE_KEY);
      return of(new SupportPage(support_page));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/supportpages/',
      {
        params: p,
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the products
        if (!this._platform.isBrowser) {
          if (res.results.length) {
            this._transferState.set(SUPPORT_PAGE_KEY, res.results[0]);
          }
        }
      }),
      map((res) => {
        if (res.results.length) {
          return new SupportPage(res.results[0])
        } else {
          return null;
        }
      })
    );
  }
}
