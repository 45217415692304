import { ApplicationRef, Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SwUpdate } from '@angular/service-worker';
import { Platform } from '@angular/cdk/platform';
import { concat, interval } from 'rxjs';
import { first } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SWUpdateService {

  constructor(
    appRef: ApplicationRef,
    updates: SwUpdate,
    snackBar: MatSnackBar,
    platform: Platform
  ) {
    // Don't worry about updates if we're not in the browser
    if (!platform.isBrowser || !updates.isEnabled) { return; }

    // Allow the app to stabilize first, before starting polling for updates with `interval()`.
    const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
    const everySixHours$ = interval(6 * 60 * 60 * 1000);
    const everySixHoursOnceAppIsStable$ = concat(appIsStable$, everySixHours$);

    everySixHoursOnceAppIsStable$.subscribe(() => updates.checkForUpdate());

    updates.available.subscribe(event => {
      // console.log('Update available, current version is "' + event.current.hash + '" available version is "' + event.available.hash + '"');
      let updateSnackbar = snackBar.open('There\'s an update available for this app.', 'Update Now');
      updateSnackbar.onAction().subscribe(() => {
        updates.activateUpdate().then(() => { document.location.reload(); });
      });
    });
  }
}
