import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { HomePageSlide } from '../models/homepageslide.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HomePageSlideService {
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }
initialize = new BehaviorSubject(null)
initializeObject = this.initialize.asObservable();
  getHomePageSlides(loggedIn: boolean = false): Observable<HomePageSlide[]> {
    let pObject = {'user_type': ['0','2']};
    if (loggedIn) {
      pObject = {'user_type': ['1','2']};
    }
    let params = new HttpParams({fromObject: pObject});
    // Check if slides are cached from server
    const SLIDES_KEY = makeStateKey<APIListResponse<Object>>('homepageslides-' + params.toString());
    if (this._transferState.hasKey(SLIDES_KEY)) {
      const slides = this._transferState.get<APIListResponse<Object>>(SLIDES_KEY, null);
      this._transferState.remove(SLIDES_KEY);
      return of(slides.results.map((s) => new HomePageSlide(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/homepageslides/',
      {
        headers: headers,
        params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the slides
        if (!this._platform.isBrowser) {
          this._transferState.set(SLIDES_KEY, res);
        }
      }),
      map((res) => res.results.map((s) => new HomePageSlide(s)))
    );
  }
}
