import { Component, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { Platform } from '@angular/cdk/platform';
import { Observable, Subscription, BehaviorSubject, fromEvent } from 'rxjs';
import { ReorderedResult } from '../shared/tracklist/tracklist.component';
import { Track } from '../shared/models/track.model';
import { Playlist } from '../shared/models/playlist.model';
import { PlaylistTracks } from '../shared/models/playlisttracks.model';
import { PlaylistService } from '../shared/services/playlist.service';
import { PlaylistTracksService } from '../shared/services/playlisttracks.service';
import {
  Filters,
  MinMax,
  filtersToParams,
  areFiltersEqual
} from '../shared/services/track.service';
import { UserService } from '../shared/services/user.service';
import { DialogService } from '../shared/services/dialog.service';
import { User } from '../shared/models/user.model';
import { moveItemInArray } from '@angular/cdk/drag-drop';
import { environment } from '../../environments/environment';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import { OverlayRef } from '@angular/cdk/overlay';
import { MatDialogRef } from '@angular/material';
import { PlaylistPopoverComponent } from '../shared/playlistpopover/playlistpopover.component';
import { saveAs, JSZip } from 'file-saver';

const tracksPageSize = 25;

const HEADER_HEIGHT = 84;

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.scss'],

})
export class PlaylistComponent implements OnInit, OnDestroy {
  downloadPlaylistFlag:any;
  loading = true;
  stickyFilters = false;
  loadingTracks = true;
  savingReordering = false;
  reordering = false;
  percentDone:any;
  currentPlaylist:any;
  get currentUser(): User {
    return this._currentUser;
  }
  set playlist(p: Playlist) {
    if (!p) {
      this._playlist = p;
      return;
    }
    if (!this._playlist || p.id != this._playlist.id) {
      this._playlist = p;
      this.loadTracks();
    }

    const title = this._pageTitleService.createPageTitle(['Playlist', p.name]);
    this._title.setTitle(title);
    const description = p.description || this._pageTitleService.mainSiteDescription;
    const image = p.thumb_image || `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
    this._meta.addTags([
      {name: 'description', content: description},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: '@MIBE_music'},
      {name: 'twitter:title', content: `${p.name} Playlist on MIBE`},
      {name: 'twitter:description', content: description},
      {name: 'twitter:image', content: image},
      {name: 'og:type', content: 'website'},
      {name: 'og:title', content: `${p.name} Playlist`},
      {name: 'og:url', content: `${environment.protocol}://${environment.host}/playlist/${p.id}`},
      {name: 'og:image', content: image},
      {name: 'og:description', content: description}
    ]);
  }
  get playlist(): Playlist {
    return this._playlist;
  }
  totalTracks = 0;
  set currentTracksPage(p: number) {
    if (!isNaN(p) && p != this._currentTracksPage) {
      this._currentTracksPage = p;
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params});
    }
  }
  get currentTracksPage(): number {
    return this._currentTracksPage;
  }
  get tracksNumberOfPages(): number {
    return Math.ceil(this.totalTracks/tracksPageSize);
  }
  tracks: Observable<ReadonlyArray<Track>>;

  @ViewChild('filtersContainer', {static: true})
  filtersEl: ElementRef;

  get apiURL(): string {
    return environment.apiURL;
  }

  get filters(): Filters {
    return this._filters;
  }
  set filters(f: Filters) {
    if (!areFiltersEqual(this._filters, f)) {
      this._filters = f;
      this._currentTracksPage = 1; // Reset page because results changed
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
  }

  private get _params(): { [param: string]: string | string[]; } {
    let params = filtersToParams(this.filters);
    // if (this.currentTracksPage && this.currentTracksPage != 1) {
      debugger
      params['page'] = `${this.currentTracksPage}`;
    // }
    return params;
  }

  private _filters: Filters = {
    duration: null,
    tempo: null,
    vocals: null,
    artistId: null,
    composerId: null,
    bpm:null
  };

  private _currentTracksPage = 1;
  private _currentUser: User;
  private _playlist: Playlist;
  private _playlistTracks: PlaylistTracks[] = [];
  private _subscriptions: Subscription[] = [];
  private _getPlaylistSubscription: Subscription;
  private _getTracksSubscription: Subscription;
  private _tracksSubject: BehaviorSubject<ReadonlyArray<Track>> = new BehaviorSubject<ReadonlyArray<Track>>([]);
  isSharedplaylist:Boolean = false;
  constructor(
    private _ngZone: NgZone,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _playlistService: PlaylistService,
    private _playlistTracksService: PlaylistTracksService,
    private _dialogService: DialogService,
    private _platform: Platform,
    private _analyticsService: AnalyticsService,
    private _historyService: HistoryService,
    private http: HttpClient,
    @Optional() public dialogRef: MatDialogRef<PlaylistPopoverComponent>,
    ) {
    this.tracks = this._tracksSubject.asObservable();
  }

  ngOnInit() {
   
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u => this._currentUser = u)
    );
    // console.log(this._currentUser)
    this._subscriptions.push(
      this._activatedRoute.paramMap.subscribe(params => {
        // Load playlist from routing data or URL
        let playlist: Playlist;
        if (this._platform.isBrowser && window.history.state && window.history.state.data) {
          playlist = new Playlist(window.history.state.data);
          if (!playlist.id) { playlist = null; }
          this.playlist = playlist;
        }
        this._playlistService.currentPlaylistID = +params.get('playlistId'); // `+` sign is used to convert the string to number
        let playlistId = params.get('playlistId');
        // Decode the URL parameter to get the playlist ID if the route path is shared-playlist
        if(this._activatedRoute.snapshot.url[0].path=="shared-playlist"){
          playlistId = atob(playlistId);
          if(this._currentUser==null){
          this._playlistService.isSharedplaylist = true;
          }
        } else {
          this._playlistService.isSharedplaylist = false;
        }
        this._userService.user_id = playlistId
        let playlistIdN: number;
        if (playlistId) {
          playlistIdN = parseInt(playlistId);
        }
        if (this.playlist && playlistIdN == this.playlist.id) {
          this.loading = false;
          return;
        }
        this.loadPlaylist(playlistIdN);
        this._analyticsService.pageView();

       
      })
    );
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params=> {
        window.scroll(0,0);

        // Load Filters from URL
        // let bpm_lte = params.get('bpm__lte');
        // let bpm_gte = params.get('bpm__gte');
        // let tempo: MinMax = null;
        // if (bpm_lte !== null || bpm_gte !== null) {
        //   tempo = {};
        //   if (bpm_lte !== null) {
        //     tempo['max'] = parseFloat(bpm_lte);
        //   }
        //   if (bpm_gte !== null) {
        //     tempo['min'] = parseFloat(bpm_gte);
        //   }
        // }
        let tempo = params.get('tempo');
        let tempoId:any = null
        if (tempo !== null) {
          tempoId = tempo;
        }
        let duration_lte = params.get('duration__lte');
        let duration_gte = params.get('duration__gte');
        let duration: MinMax = null;
        if (duration_lte !== null || duration_gte !== null) {
          duration = {};
          if (duration_lte !== null) {
            duration['max'] = parseFloat(duration_lte);
          }
          if (duration_gte !== null) {
            duration['min'] = parseFloat(duration_gte);
          }
        }
        let is_instrumental = params.get('is_instrumental');
        let vocals: boolean = null;
        if (is_instrumental !== null) {
          vocals = is_instrumental !== 'true';
        }
        let artist = params.get('artist');
        let artistId: number = null;
        if (artist !== null) {
          artistId = parseInt(artist);
        }
        let composer = params.get('composer');
        let composerId: number = null;
        if (composer !== null) {
          composerId = parseInt(composer);
        }
        let bpm = params.get('bpm');
        let filters: Filters = {
          duration: duration,
          tempo: tempoId,
          vocals: vocals,
          artistId: artistId,
          composerId: composerId,
          bpm:bpm
        };
        this.filters = filters;

        let page = params.get('page');
        if (page !== null) {
          this.currentTracksPage = parseInt(page);
        } else {
          this.currentTracksPage = 1;
        }
      })
    );
    // Handle sticky filters while scrolling page
    if (!this._platform.isBrowser || !this.filtersEl) { return; }
    this._ngZone.runOutsideAngular(() => {
      this._subscriptions.push(
        fromEvent(window.document, 'scroll').subscribe(_ => {
          const filtersYPosition = this.filtersEl.nativeElement.getBoundingClientRect().y;
          if (filtersYPosition > HEADER_HEIGHT && !this.stickyFilters) { return; }
          if (filtersYPosition <= HEADER_HEIGHT && this.stickyFilters) { return; }
          this._ngZone.run(() => {
            this.stickyFilters = filtersYPosition <= HEADER_HEIGHT
          });
        })
      );
    });
    this._historyService.removeLastIndex()
    // console.log(this._historyService._history)
   this._playlistService.PlaylistRemove.subscribe(x=>{
     if(x){
      this.loadTracks();
     }
     
   })
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  loadPlaylist(playlistId: number) {
    this.loading = true;
    if (this._getPlaylistSubscription && !this._getPlaylistSubscription.closed) {
      this._getPlaylistSubscription.unsubscribe();
    }
    this._getPlaylistSubscription = this._playlistService.getPlaylist(playlistId).subscribe(p => {
      this.playlist = p;
      this.loading = false;
    }, (error) => {
      if(error.status===404){
        this._router.navigate(['/playlists']);
      }
      this.loadTracks()
      // if (!this.currentUser) {
      //   this._dialogService.displayLoginForm().subscribe(u => {
      //     if (u) {
      //       this.loadPlaylist(playlistId);
      //     } else {
      //       this._router.navigate(['/']);
      //     }
      //   });
      // } else {
      //   this._router.navigate(['/']);
      // }
    });
    
  }

  loadTracks() {
    this._playlistTracks = [];
    if (!this.playlist) {
      return;
    }
    this.loadingTracks = true;
    if (this._getTracksSubscription && !this._getTracksSubscription.closed) {
      this._getTracksSubscription.unsubscribe();
    }
    this._getTracksSubscription = this._playlistTracksService.getPlaylistTracks({
  
      limit: tracksPageSize,
      offset: (this.currentTracksPage - 1) * tracksPageSize,
      playlistId: this.playlist.id,
      filters: this.filters,
      page: this.currentTracksPage,
    }).subscribe((pt:any) => {
      this.playlist.playlist_size = pt.complete_palylist_size
      this.playlist.main_track_size = pt.main_tracks_size
      this.totalTracks = pt.count;
      // console.log(pt)
      // this._playlistTracks = pt.results;
      for(let play of pt.results){
        let pl:any;
        pl = play
        this._playlistTracks.push(pl)
      }
      this._tracksSubject.next(pt.results.map(r => r));
      this.loadingTracks = false;
    });
  }

  doneReordering() {
    
    // console.log(this.playlist)
    this.reordering = false;
    this.savingReordering = true;
    this._playlistTracksService.reorderTracks(this._playlistTracks,this.playlist.id).subscribe(() => {
      
      this.savingReordering = false;
      this.loadTracks();
      window.location.reload();
      // this.ngOnInit();
    }, () => {
      
      this.savingReordering = false;
    });
  }

  startReordering() {
    this.reordering = true;
  }

  reordered(result: ReorderedResult) {
    
    moveItemInArray(this._playlistTracks, result.previousIndex, result.currentIndex);
    this._playlistTracks.forEach((pt, i) => {
      pt.order = i + ((this.currentTracksPage - 1) * tracksPageSize);
    });
    this._tracksSubject.next(this._playlistTracks.map(r => r.track));
    // console.log(this.playlist)
    this.doneReordering()
  }

  sharePlaylist() {
    this._dialogService.displaySharePlaylistForm(this._playlist);
  }

  duplicatePlaylist() {
    this._dialogService.displayCreatePlaylist(this._playlist).subscribe(np => {
      // console.log(np);
      if (np) {
        this._router.navigate(['/playlist', np.id], {state: {data: np.toJSON()}});
      }
    });
  }

//  public displayDownloadDialog() {
//     let playlist = this.playlist;
//     if (this.currentUser) {
//       this._dialogService.displayDownloadDialog('playlist', playlist);
//       return;
//     }
//     this._dialogService.displayLoginForm().subscribe(u => {
//       if (u) {
//         this._dialogService.displayDownloadDialog('playlist', playlist);
//       }
//     });
//   }

  renamePlaylist() {
    this._dialogService.displayCreatePlaylist(null, this.playlist);
  }
  downloadcue(playlist) {


    let requestURL = environment.apiURL + `/api/v1/playlists/${playlist.id}/cuesheet/`
  
    let headers = new HttpHeaders();
    this.http.get(`${requestURL}`, {
      responseType: 'text', headers: headers
    }
    ).subscribe(response => this.downLoadFile(playlist,response, "application/pdf"));
  
  
  }
    downLoadFile(playlist,data: any, type: string) {
      let filename = `${playlist.name.trim()}.pdf`;
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let result = filename.substring(0,4);
    let tr = filename.substring(4);
    if(result.toLowerCase() === 'mibe'){
        filename = result.toUpperCase() + tr
    }
    else{
      filename = 'MIBE ' + filename
    }
    saveAs(url, filename);
    }
    openDownloadPop(row){console.log(`Current playlist: `, row)
    this.currentPlaylist = row
      this.http.get(environment.apiURL + '/api/v1/user/current/').subscribe((x: User)=>{
        if(x.can_download){
          // this.downloadPlaylistFlag = row
          this.downloadPlaylistFlag = this.currentPlaylist
          // this._dialogService.displaydeleteplaylistFormDowload(row)
          this._dialogService.displaydeleteplaylistFormDowload(this.currentPlaylist)
        }
        else{
            this._dialogService.displaydisplayerror('Access Denied',false)
        }
      })
      
    
        }
        downloadAll(row){

          let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/downloadplaylist?id=${this.currentUser.id}&full_version_tracks=true`
          this.http.get(requestURL).subscribe(x=>{
            window.open(requestURL, '_blank')
          },error =>{
            this._dialogService.displaydisplayerror(error.error,false)
          })
         
        }
         downloadMyFilePlaylist(row) {
      
          let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/downloadplaylist?id=${this.currentUser.id}`
          this.http.get(requestURL).subscribe(x=>{
            window.open(requestURL, '_blank')
          },error =>{
            this._dialogService.displaydisplayerror(error.error,false)
          })
          
          // let headers = new HttpHeaders();
          // this.http.get(`${requestURL}`, {
          //   responseType: 'arraybuffer', headers: headers,reportProgress: true,observe: 'events'
          // }
          // ).subscribe(response => {
           
          //   if (response.type === HttpEventType.DownloadProgress) {
             
          //     this.percentDone = Math.round(100 * response.loaded / response.total);
            
          //   }
          //   else if (response.type == HttpEventType.Response) {
          //     this.percentDone = null;
          //     this.downloadFileZip(response, "audio/octet-stream",'test1')
          //   }
          // },
          // error =>{
          //   if(error.status){
          //     this.percentDone = null;
          //     this._dialogService.displaydisplayerror("This playlist has no tracks.",false)
          //   }
            
          // });
        
        }
        downloadFileZip(data: any, type: string, filename: any) {
          // let fileName = `${filename}.zip`;
          // let blob = new Blob([data], { type: 'application/audio'});
          // let url = window.URL.createObjectURL(blob);
          // saveAs(url, fileName);
          const blob1 = new Blob([data], { type: 'application/audio' });
          var zip = new JSZip();
          zip.file("Track.wav", blob1);
          zip.generateAsync({ type: "blob" })
            .then(blob => saveAs(blob, 'file.zip'));
          // let pwa = window.open(url);
          // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
          //     //alert( 'Please disable your Pop-up blocker and try again.');
          // }
        }
}
