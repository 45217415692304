import { MibeModel } from './mibemodel.model';

export class HomePageSlide implements MibeModel {
  id: number;
  name: string;
  title: string;
  subtitle: string;
  buttonText: string;
  buttonURL: string;
  image: string;

  /**
   * Create a HomePageSlide.
   * @param {any} json - The JSON object with which to construct our HomePageSlide.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a HomePageSlide.
   * @param {any} json - The JSON object with which to load our HomePageSlide.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.title = json.title;
    this.subtitle = json.subtitle;
    this.buttonText = json.button_text;
    this.buttonURL = json.button_url;
    this.image = json.image;
  }
  /**
   * Returns a JSON representation of our HomePageSlide
   * @return {any} - The JSON representation of our HomePageSlide
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'title': this.title,
      'subtitle': this.subtitle,
      'button_text': this.buttonText,
      'button_url': this.buttonURL,
      'image': this.image
    };
    return json;
  }
}
