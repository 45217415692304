import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { FullCustomDimensionsSet } from './full-custom-dimensions-set';
import { DataLayerCustomDimensions } from './data-layer-custom-dimensions';


// This is a type that can be used for a partial version of the dimensions set,
// Where there are gaps and some dimensions are empty
export type PartialCustomDimensionsSet = { [key: string]: string };

@Injectable({
  providedIn: 'root'
})
export class DataLayerCustomDimensionsService {
  private _currentSet: FullCustomDimensionsSet;
  private _constantDimensions = {};

  constructor(
    private _platform: Platform
  ) {
    this._generateEmptyDimensionsSet();
  }

  set dimensions(someDimensions: PartialCustomDimensionsSet) {
    Object.keys(DataLayerCustomDimensions).map(key => DataLayerCustomDimensions[key])
    .forEach(key => {
      this._currentSet[key] = someDimensions[key] || this._constantDimensions[key];
    });
  }

  trigger() {
    if (!this._platform.isBrowser) { return; }
    (<any>window).dataLayer.push(this._currentSet);
  }

  private _generateEmptyDimensionsSet() {
    this._currentSet = {
      userId: undefined
    };
  }
}
