import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Show } from '../models/show.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ShowService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {}

  getAllShows(s: string=null): Observable<Show[]> {
    let params: {[param: string]: string | string[]} = {'limit': '250'};
    if (s) {
      params['search'] = s;
    }
    return this.getShows(params);
  }

  getShow(showId: number): Observable<Show> {
    // Check if shows are cached from server
    const SHOW_KEY = makeStateKey<Object>('show-' + showId);
    if (this._transferState.hasKey(SHOW_KEY)) {
      const mood = this._transferState.get<Object>(SHOW_KEY, null);
      this._transferState.remove(SHOW_KEY);
      return of(new Show(mood));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/shows/' + showId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the show
        if (!this._platform.isBrowser) {
          this._transferState.set(SHOW_KEY, res);
        }
      }),
      map((res) => new Show(res))
    );
  }

  private getShows(params: {[param: string]: string | string[]}): Observable<Show[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if shows are cached from server
    const SHOWS_KEY = makeStateKey<APIListResponse<Object>>('shows-' + p.toString());
    if (this._transferState.hasKey(SHOWS_KEY)) {
      const shows = this._transferState.get<APIListResponse<Object>>(SHOWS_KEY, null);
      this._transferState.remove(SHOWS_KEY);
      return of(shows.results.map((s) => new Show(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/shows/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the shows
        if (!this._platform.isBrowser) {
          this._transferState.set(SHOWS_KEY, res);
        }
      }),
      map((res) => res.results.map((s) => new Show(s)))
    );
  }
}
