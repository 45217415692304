import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { AlbumService } from '../services/album.service'
import { Album } from '../models/album.model';
import { Artist } from '../models/artist.model';

export interface DialogData {
  album: Album;
  featuredArtists: Artist[];
}

@Component({
  selector: 'app-create-album',
  templateUrl: './create-album.component.html',
  styleUrls: ['./create-album.component.scss']
})
export class CreateAlbumComponent implements OnInit, OnDestroy {

  loading = false;
  saving = false;
  savingImage = false;
  isEdit = false;
  albumPicDragging = false;
  albumPicError: string;
  currentArtistId: number;

  get image_src() {
    if (this._fileURL) {
      return this._fileURL;
    }
    return this.albumModel.image ? this.albumModel.image : '/assets/img/soundboard-page-marquee.jpg';
  }

  get albumModel() {
    return this._albumModel;
  }

  set albumModel(a) {
    this._albumModel = a;
  }

  private _albumModel: Album;
  private _file: File;
  private _fileURL: string | ArrayBuffer;

  constructor(
    private _albumService: AlbumService,
    public dialogRef: MatDialogRef<CreateAlbumComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    if(!this.data.featuredArtists || this.data.featuredArtists.length < 1) this.close();
    if (this.data.album) {
      this.isEdit = true;
      this.albumModel = this.data.album;
      for (let artist of this.data.featuredArtists) {
        for (let album of artist.albums) {
          if (album.id == this.albumModel.id) {
            this.currentArtistId = artist.id;
            break;
          }
        }
        if (this.currentArtistId !== null) break;
      }
    }
    else {
      this.currentArtistId = this.data.featuredArtists[0] ? this.data.featuredArtists[0].id : null
      this.albumModel = new Album(null);
    }
  }

  ngOnDestroy() {

  }

  close() {
    this.dialogRef.close(this.albumModel);
  }

  onSubmit() {
    this.saving = true;
    if (this.isEdit) {
      this._albumService.updateAlbum(this.albumModel, this._file).subscribe((a) => {
        this.albumModel = a;
        this.close();
        this.saving = false;
      });
      return;
    }
    this._albumService.createAlbum(this.currentArtistId, this.albumModel, this._file).subscribe((a) => {
      this.albumModel = a;
      this.close();
      this.saving = false;
    })

  }

  artistTrackBy(_:number, artist:Artist) {
    return artist.id + '-' + artist.name;
  }


  albumFileInputChange(files: File[]) {
    this.albumPicError = null;
    let file = files[0];
    if (!file) { return; }
    let validFileTypes = ['image/jpeg', 'image/png'];
    if (validFileTypes.indexOf(file.type) === -1) {
      this.albumPicError = 'You must select either a jpeg, or png!';
      return;
    }

    this._file = file;
    let reader = new FileReader();
    reader.addEventListener('load', () => {
      this._fileURL = reader.result;
    });
    reader.readAsDataURL(this._file);
  }
}
