import { MibeModel } from './mibemodel.model';

export class FAQ implements MibeModel {
  id: number;
  question: string;
  answer: string;
  order: number;

  /**
   * Create a Waveform.
   * @param {any} json - The JSON object with which to construct our Waveform.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Waveform.
   * @param {any} json - The JSON object with which to load our Waveform.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.question = json.question;
    this.answer = json.answer;
    this.order = json.order;
  }
  /**
   * Returns a JSON representation of our Waveform
   * @return {any} - The JSON representation of our Waveform
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'question': this.question,
      'answer': this.answer,
      'order': this.order
    };
    return json;
  }
}
