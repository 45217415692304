import { Component, OnInit, Input, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Playlist } from '../models/playlist.model';
import { PlaylistService } from '../services/playlist.service';
import { DialogService } from '../services/dialog.service';

export interface DialogData {
  playlist: Playlist;
  playlistToRename: Playlist;
}

@Component({
  selector: 'app-createplaylist',
  templateUrl: './createplaylist.component.html',
  styleUrls: ['./createplaylist.component.scss']
})
export class CreatePlaylistComponent implements OnInit {

  @Input()
  newPlaylistName = '';

  loading = false;
  errors:any = []
  constructor(
    public dialogRef: MatDialogRef<CreatePlaylistComponent>,
    private _playlistService: PlaylistService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogueService: DialogService
  ) { }

  ngOnInit() {
    if (this.data.playlistToRename) {
      this.newPlaylistName = this.data.playlistToRename.name;
    }
  }

  createPlaylist() {
    this.errors = []
    this.loading = true;
    let observable = this._playlistService.createPlaylist(this.newPlaylistName);
    if (this.data.playlist) {
      observable = this._playlistService.duplicatePlaylist(this.data.playlist, this.newPlaylistName);
    }
    if (this.data.playlistToRename) {
      this.data.playlistToRename.name = this.newPlaylistName;
      observable = this._playlistService.updatePlaylist(this.data.playlistToRename);
    }
    observable.subscribe(p => {
      this.loading = false;
      this.dialogRef.close(p);
    },
    error=>{
      this.loading = false;
      // console.log(error.error)
      this.errors.push(error.error.Error);
    });
  }

  close() {
    this.dialogRef.close(null);
  }

}
