import { Component, OnInit, Input } from '@angular/core';
import { Genre } from '../models/genre.model';
import { Router } from '@angular/router';
import { TrackService } from '../services/track.service';

@Component({
  selector: 'app-prev-next-arrow',
  templateUrl: './prev-next-arrow.component.html',
  styleUrls: ['./prev-next-arrow.component.scss']
})
export class PrevNextArrowComponent implements OnInit {

  @Input()
  genre:Genre

  @Input()
  isNext:boolean = false

  isHovering = false;

  get thumbImage(): string {
    if (this.genre.thumb_image) return this.genre.thumb_image;
    if (this.genre.parent && this.genre.parent.thumb_image) return this.genre.parent.thumb_image;
    return '/assets/img/soundboard-page-marquee.jpg';
  }

  constructor(private _router: Router, public TrackService: TrackService) { 
  }

  ngOnInit() {
  }

  onMouseEnter() {
    this.isHovering = true;
  }

  onMouseLeave() {
    this.isHovering = false;
  }

  arrowClick() {
   
    if(this.genre.parent){
      this.TrackService.CURRENTpopularGenre.next(this.genre)
      // console.log(this.genre)
    }
    else{
      this._router.navigate(
        ['/genre', this.genre.id],
        {state: {data: this.genre.toJSON()}}
      );
    }
   
  }

}
