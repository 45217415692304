import { MibeModel } from './mibemodel.model';

export class Album implements MibeModel {
  id: number;
  name: string;
  description: string;
  image_height: number;
  image_width: number;
  image: string;
  released_at: Date;
  is_active: boolean;
  artist: number;
  favorited_by_me: boolean;

  private _original_data: any;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Album.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Album.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this._original_data = json;
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.image_height = json.image_height;
    this.image_width = json.image_width;
    this.image = json.image;
    this.released_at = json.released_at;
    this.is_active = !!json.is_active;
    this.artist = json.artist;
    this.favorited_by_me = json.favorited_by_me;
  }
  /**
   * Returns a JSON representation of our Album
   * @return {any} - The JSON representation of our Album
   */
  toJSON() {
    var json = this._original_data;
    json['id'] = this.id;
    json['name'] = this.name;
    json['description'] = this.description;
    json['image_height'] = this.image_height;
    json['image_width'] = this.image_width;
    json['image'] = this.image;
    json['released_at'] = this.released_at;
    json['is_active'] = this.is_active;
    json['artist'] = this.artist;
    json['favorited_by_me'] = this.favorited_by_me;
    return json;
  }

  containsTrack(id: number): boolean {
    return this._original_data['contains_track_' + id];
  }
}
