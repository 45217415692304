import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import {
  HttpClient,
  HttpParams,
  HttpHeaders
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Track } from '../models/track.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Genre } from '../models/genre.model';
import { Router, ActivatedRoute } from '@angular/router';
import { ActivityService } from './activity.service';


export interface MinMax {
  min?: number;
  max?: number;
}
export function minMaxToString(minMax: MinMax): string {
  let s = undefined;
  if (minMax) {
    s = '';
    if (minMax.min) {
      s = `${minMax.min}`;
    }
    s = s + ':';
    if (minMax.max) {
      s = s + `${minMax.max}`;
    }
  }
  return s;
}
export function stringToMinMax(s: string): MinMax {
  let mm = null;
  if (s) {
    mm = {};
    let splitS = s.split(':');
    if (splitS[0]) {
      mm['min'] = parseFloat(splitS[0]);
    }
    if (splitS[1]) {
      mm['max'] = parseFloat(splitS[1]);
    }
  }
  return mm;
}
export interface Filters {
  duration: MinMax;
  tempo: any;
  vocals: boolean;
  artistId: number;
  composerId: number
  bpm:string;
}
export enum versionStatus {
  PROCESSING = 0,
  SYSTEM_REJECTED = 1,
  PENDING_REVIEW = 2,
  PENDING_REVIEW_APPROVAL = 3,
  REJECTED = 4,
  ACCEPTED = 5,
  DISABLED = 6
}
export interface GetTracksOptions {
  trackIds?: number[];
  ownedBy?: number;
  playlistId?: number;
  genreId?: number;
  sub_genre?: number;
  moodId?: number;
  moodIDs?: number[];
  artistId?: number;
  composerId?: number;
  albumId?: number;
  filters?: Filters;
  favorite?: boolean;
  downloadable?: boolean;
  ordering?: string;
  offset?: number;
  limit?: number;
  page?:number
  searchString?: string;
  versionStatus?: number[];
}

export function filtersToParams(filters: Filters): { [param: string]: string | string[]; } {
  let params = {};
  if (filters.duration) {
    if (filters.duration.max) {
      params['duration__lte'] = `${filters.duration.max}`;
    }
    if (filters.duration.min) {
      params['duration__gte'] = `${filters.duration.min}`;
    }
  }
  if(filters.bpm){
    params['bpm']= filters.bpm;
  }
  if (filters.tempo) {
    params['tempo'] = filters.tempo.id;
    // params['tempo']= `${filters.tempo}`;
    // if (filters.tempo.max) {
    //   params['bpm__lte'] = `${filters.tempo.max}`;
    // }
    // if (filters.tempo.min) {
    //   params['bpm__gte'] = `${filters.tempo.min}`;
    // }
    // if(filters.tempo.min == 160){
    //   params['tempo'] = 1;
    // }
    // if(filters.tempo.max == 159 && filters.tempo.min == 140){
    //   params['tempo'] = 2;
    // }
    // if(filters.tempo.max == 139 && filters.tempo.min == 120){
    //   params['tempo'] = 3;
    // }
    // if(filters.tempo.max == 119 && filters.tempo.min == 100){
    //   params['tempo'] = 4;
    // }
    // if(filters.tempo.max == 99 && filters.tempo.min == 60){
    //   params['tempo'] = 5;
    // }
    // if(filters.tempo.max == 59 ){
    //   params['tempo'] = 6;
    // }
  }
  if (filters.vocals === true || filters.vocals === false) {
    params['is_instrumental'] = `${!filters.vocals}`;
  }
  if (filters.artistId) {
    params['artist'] = `${filters.artistId}`;
  }
  if (filters.composerId) {
    params['composer'] = `${filters.composerId}`;
  }
  return params;
}

export function areMinMaxEqual(mm1: MinMax, mm2: MinMax): boolean {
  if ((mm1 == null && mm2 != null) || (mm2 == null && mm1 != null)) {
    return false;
  }
  if (mm1 != null && mm2 != null) {
    if (mm1.max != mm2.max || mm1.min != mm2.min) {
      return false;
    }
  }
  return true;
}

export function areFiltersEqual(f1: Filters, f2: Filters): boolean {
  
  if (!areMinMaxEqual(f1.duration, f2.duration)) {
    return false;
  }
  // if (!areMinMaxEqual(f1.tempo, f2.tempo)) {
  //   return false;
  // }
  if ((f2.tempo && f2.tempo.tempo_name) || !f2.tempo) {
    return false;
  }
  if (f1.vocals !== f2.vocals) {
    return false;
  }
  if (f1.artistId !== f2.artistId) {
    return false;
  }
  if (f1.composerId !== f2.composerId) {
    return false;
  }
  if (f1.bpm !== f2.bpm) {
    return false;
  }
  return true;
}

@Injectable({
  providedIn: 'root'
})
export class TrackService {
  public CURRENTSubGenreSubject: BehaviorSubject<Genre> = new BehaviorSubject<Genre>(null);
  public currentSub = this.CURRENTSubGenreSubject.asObservable();

  public CURRENTpopularGenre: BehaviorSubject<Genre> = new BehaviorSubject<Genre>(null);
  public currentpopularSub = this.CURRENTpopularGenre.asObservable();
activityKeyWord:any
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState,
    private router: Router,
    private ActivityService: ActivityService
  ) { 
    this.ActivityService.curentActivity.subscribe(x=>{
     if(x){
      this.activityKeyWord = x
     }

     
    })
  }

  getFeaturedTracks(limit:number = 20): Observable<Track[]> {
    return this._getTracks({
      'is_featured': 'true',
      'limit': limit.toString()
    }).pipe(map(res => res.results));
  }

  getTracks(options: GetTracksOptions): Observable<APIListResponse<Track>> {
    let params: { [param: string]: string | string[]; } = {};
    if (options) {
      if (options.filters) {
        params = filtersToParams(options.filters);
        
      }
      if(params.bpm){
        params['bpm'] = params.bpm;
      }
      if(options.filters.tempo){
        params['tempo'] = options.filters.tempo.id;
      }
      if (params.bpm__lte) {
        delete params["bpm__lte"];
      }
      if (params.bpm__gte) {
        delete params["bpm__gte"];
      }
      if (options.trackIds) {
        params['id'] = options.trackIds.map(id=>id.toString());
      }
      if (options.ownedBy) {
        params['owned_by'] = `${options.ownedBy}`;
      }
      if (options.genreId) {
        params['genre'] = `${options.genreId}`;
      }
      if (options.sub_genre) {
        params['sub_genre'] = `${options.sub_genre}`;
      }
      if (options.moodId) {
        params['moods'] = `${options.moodId}`;
      }
      if (options.moodIDs) {
        params['moodId'] = `${options.moodIDs}`;
      }
      if (options.playlistId) {
        params['playlists'] = `${options.playlistId}`;
      }
      if (options.artistId) {
        // console.log(options)
        params['artist'] = `${options.artistId}`;
      }
      if (options.filters.artistId) {
        // console.log(options)
        params['artist'] = `${options.filters.artistId}`;
      }
      if (options.filters.bpm) {
        // console.log(options)
        params['bpm'] = `${options.filters.bpm}`;
      }
      if (options.composerId) {
        params['composer'] = `${options.composerId}`;
      }
      if (options.albumId) {
        params['albums'] = `${options.albumId}`;
      }
      if (options.offset) {
        params['offset'] = `${options.offset}`;
      }
      if (options.page) {
        if(options.searchString && options.searchString!=''){
        params['page_no'] = `${options.page}`;
        } else {
          params['page'] = `${options.page}`
        }
      }
      if (options.limit) {
        params['limit'] = `${options.limit}`;
      }
      if (options.ordering) {
        params['ordering'] = options.ordering;
      }
      if(options.searchString && options.searchString.length > 2){
        params['search'] = options.searchString
      }
      
      if (options.favorite === true || options.favorite === false) {
        params['is_favorite'] = `${options.favorite}`;
      }
      if ((options.downloadable === true || options.downloadable === false) && options.trackIds) {
        params['id'] = options.trackIds.map(id=>id.toString());
      }
      if ((options.downloadable === true || options.downloadable === false) && !options.trackIds) {
        params['is_downloadable'] = `${options.downloadable}`;
      }
      if (options.versionStatus) {
        params['versions__status'] = options.versionStatus.map(status=>status.toString());
      }
    }
   
  
    return this._getTracks(params);
  }

  shareTrackViaEmail(track: Track, emails: string[], message: string,url: string): Observable<Track> {
    // console.log(url)
   var requestUrl;
    if(url.includes('downloads')){
       requestUrl = environment.apiURL + `/api/v1/tracks/${track.id}/shareemail/?downloads=true`
    }
    else{
       requestUrl = environment.apiURL + '/api/v1/tracks/' + track.id + '/shareemail/'
    }
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      requestUrl,
      {
        'emails': emails,
        'message': message
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      track.loadWithJSON(res);
      return track;
    }));
  }

  getTrack(trackId: number): Observable<Track> {
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/tracks/' + trackId +'/'
    ).pipe(
      map(res => new Track(res))
    );
  }

  updateTrack(track: Track): Observable<Track> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/tracks/' + track.id +'/',
      track.toJSON(),
      {
        headers: headers
      }
    ).pipe(map(res => {
      return new Track(res);
    }));
  }

  createTrack(track: Track): Observable<Track> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/tracks/',
      track.toJSON(),
      {
        headers: headers
      }
    ).pipe(map(res=>{
      return new Track(res);
    }));
  }

  private _getTracks(params: {[param: string]: string | string[]}): Observable<APIListResponse<Track>> {
   let  requestUrl;   
   let url = this.router.url;
  //  console.log(url)
  //  console.log(params)
   if(this.activityKeyWord){
     params['activity'] = 'true'
   }
  
    if(url.includes('downloads') && params.id){
      requestUrl = environment.apiURL + '/api/v1/trackversions/getdownloads/'
    }
    else if(url.includes('downloads') && params.is_downloadable){
      requestUrl = environment.apiURL + '/api/v1/trackversions/getdownloads/'
    }
   else if(url.includes('browse') && params.id){
    requestUrl = environment.apiURL + `/api/v1/tracks_v1/`
    }
    else if(params.artist && (!params.duration__lte && !params.duration__gte && !params.bpm__lte && !params.bpm__gte && !params.is_instrumental && !params.tempo && !params.bpm)){
      requestUrl = environment.apiURL + `/api/v1/tracks_v1/`
    }
    else if (!params.duration__lte && !params.duration__gte && !params.bpm__lte && !params.bpm__gte && params.is_instrumental && !params.artist && !params.tempo && !params.bpm){
      requestUrl = environment.apiURL + `/api/v1/tracks_v1/`
    }
    else if(params.duration__lte || params.duration__gte || params.bpm__lte || params.bpm__gte ||params.is_instrumental || params.artist || params.tempo || params.bpm){
      // else if(params.bpm){
     
      requestUrl = environment.apiURL + '/api/v1/trackversions/gettracks/'
    }
    
    else{
      requestUrl = environment.apiURL + `/api/v1/tracks_v1/`
    }
    
    if(params.is_downloadable){
      delete params.is_downloadable; 
    }
    let p = new HttpParams({ fromObject: params });
    // Check if tracks are cached from server
    const TRACKS_KEY = makeStateKey<APIListResponse<Object>>('tracks-' + p.toString());
    if (this._transferState.hasKey(TRACKS_KEY)) {
      const tracks = this._transferState.get<APIListResponse<Object>>(TRACKS_KEY, null);
      this._transferState.remove(TRACKS_KEY);
      return of({
        next: tracks.next,
        previous: tracks.previous,
        count: tracks.count,
        results: tracks.results.map((s) => new Track(s))
      });
    }
    let headers = new HttpHeaders();
    
    return this.http.get<APIListResponse>(
      requestUrl,
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the tracks
        if (!this._platform.isBrowser) {
          this._transferState.set(TRACKS_KEY, res);
        }
      }),
      map((res) => {
        for(let track of res.results){
          let sortTrack = []
          // console.log(track)
          for(let version of track['versions']){
            sortTrack.push(version)
            // if(version.is_full_version === true && !params.is_favorite){
            //   sortTrack.unshift(version)
            // }
            // else{
            //   sortTrack.push(version)
            // }
            this.ActivityService.activitySub.next(null)
            this.activityKeyWord = null
          }
          track['versions'] = sortTrack;
        }
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((s) => new Track(s))
        };
      })
    );
  }

}
