import { MibeModel } from './mibemodel.model';
import { Track } from './track.model';

export class AlbumTracks implements MibeModel {
  id: number;
  album_id: number;
  track: Track;
  order: number;

  /**
   * Create a AlbumTracks.
   * @param {any} json - The JSON object with which to construct our AlbumTracks.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a AlbumTracks.
   * @param {any} json - The JSON object with which to load our AlbumTracks.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.album_id = json.album_id;
    this.track = new Track(json.track);
    this.order = json.order;
  }
  /**
   * Returns a JSON representation of our AlbumTracks
   * @return {any} - The JSON representation of our AlbumTracks
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'album_id': this.album_id,
      'track': this.track.toJSON(),
      'order': this.order
    };
    return json;
  }
}
