import { MibeModel } from './mibemodel.model';

export class Genre implements MibeModel {
  id: number;
  name: string;
  description: string;
  parent: Genre;
  thumb_image: string;
  marquee_image: string;
  is_featured: boolean;
  track_count: number;
  banner_image:string;

  /**
   * Create a Genre.
   * @param {any} json - The JSON object with which to construct our Genre.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Genre.
   * @param {any} json - The JSON object with which to load our Genre.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.thumb_image = json.thumb_image;
    this.marquee_image = json.marquee_image;
    this.banner_image = json.banner_image;
    this.is_featured = !!json.is_featured;
    this.track_count = json.track_count;
    if (json.parent) {
      this.parent = new Genre(json.parent);
    } else {
      this.parent = null;
    }
  }
  /**
   * Returns a JSON representation of our Genre
   * @return {any} - The JSON representation of our Genre
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'description': this.description,
      'track_count': this.track_count,
      'thumb_image': this.thumb_image,
      'marquee_image': this.marquee_image,
      'is_featured': this.is_featured,
      'banner_image': this.banner_image,
    };
    if (this.parent) {
      json['parent'] = this.parent.toJSON();
    }
    return json;
  }
}
