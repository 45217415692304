import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { APIListResponse } from '../models/apiresponse.model';
import { Message } from '../models/message.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }

  getMessages(params: {[param: string]: string | string[]}): Observable<APIListResponse<Message>> {
    let p = new HttpParams({ fromObject: params });
    // Check if messages are cached from server
    const MESSAGES_KEY = makeStateKey<APIListResponse<Object>>('messages-' + p.toString());
    if (this._transferState.hasKey(MESSAGES_KEY)) {
      const messages = this._transferState.get<APIListResponse<Object>>(MESSAGES_KEY, null);
      this._transferState.remove(MESSAGES_KEY);
      return of({
        next: messages.next,
        previous: messages.previous,
        count: messages.count,
        results: messages.results.map((m) => new Message(m))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/usermessages/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the messages
        if (!this._platform.isBrowser) {
          this._transferState.set(MESSAGES_KEY, res);
        }
      }),
      map( (res) =>  {
          return {
            next: res.next,
            previous: res.previous,
            count: res.count,
            results: res.results.map((m) => new Message(m))
          };

        }
      )
    );
  }

  markAllRead(params: {[param: string]: string | string[]}): Observable<APIListResponse<Message>> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<APIListResponse>(
      environment.apiURL + '/api/v1/usermessages/markread/',
      {},
      {
        headers:headers,
        params: params
      }
    ).pipe(map((res) =>  {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((m) => new Message(m))
        };

      }));
  }

}
