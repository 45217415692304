import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  trigger,
  state,
  style,
  query,
  group,
  animate,
  stagger,
  transition
} from '@angular/animations';
import { Platform } from '@angular/cdk/platform';
import { interval, Subscription } from 'rxjs';
import { HomePageSlide } from '../models/homepageslide.model';
import { HomePageSlideService } from '../services/homepageslide.service';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition('previous => current', [
        query('.slide-image, .content-item', [
          style({
            opacity: '0'
          })
        ]),
        group([
          query('.slide-image', [
            style({
              opacity: '0',
              transform: 'translateX(-100%)'
            }),
            animate('0.7s 0.3s cubic-bezier(0.19, 1, 0.22, 1)')
          ]),
          query('.content-item', [
            style({
              opacity: '0'
            }),
            stagger(300, [
              animate('0.7s 0.3s ease-in-out')
            ])
          ])
        ])
      ]),
      transition('next => current', [
        query('.slide-image, .content-item', [
          style({
            opacity: '0'
          })
        ]),
        group([
          query('.slide-image', [
            style({
              opacity: '0',
              transform: 'translateX(100%)'
            }),
            animate('0.7s 0.3s cubic-bezier(0.19, 1, 0.22, 1)')
          ]),
          query('.content-item', [
            style({
              opacity: '0'
            }),
            stagger(300, [
              animate('0.7s 0.3s ease-in-out')
            ])
          ])
        ])
      ]),
      transition('current => next', [
        style({
          visibility: 'visible',
        }),
        query('.slide-image, .content-item', [
          style({
            opacity: '1'
          })
        ]),
        group([
          query('.slide-image', [
            animate('0.3s cubic-bezier(0.78, 0, 0.81, 0)', style({
              opacity: '0',
              transform: 'translateX(100%)'
            }))
          ]),
          query('.content-item', [
            animate('0.3s ease-in-out')
          ])
        ])
      ]),
      transition('current => previous', [
        style({
          visibility: 'visible',
        }),
        query('.slide-image, .content-item', [
          style({
            opacity: '1'
          })
        ]),
        group([
          query('.slide-image', [
            animate('0.3s cubic-bezier(0.78, 0, 0.81, 0)', style({
              opacity: '0',
              transform: 'translateX(-100%)'
            }))
          ]),
          query('.content-item', [
            animate('0.3s ease-in-out')
          ])
        ])
      ])
    ])
  ]
})
export class SliderComponent implements OnInit, OnDestroy {

  selectedSlide: HomePageSlide;
  slides: HomePageSlide[] = [];
  loading = true;

  get selectedSlideIndex(): number {
    return this.slides.indexOf(this.selectedSlide);
  }
intervalData:any;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _userService: UserService,
    private _slideService: HomePageSlideService,
    private _platform: Platform,
    private _router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u => {
        let loggedIn = !!u;

        this.loading = true;
        this._slideService.getHomePageSlides(loggedIn).subscribe((slides) => {
          this.slides = slides;
          if (!!this.slides.length) {
            this.selectedSlide = this.slides[0];
          }
          this.loading = false;
        });
      })
    );
    if (this._platform.isBrowser) {
      // Progress slides every 5 seconds
      this.intervalData = setInterval(() => {
        this.nextSlide(); 
      }, 5000);
    
      // this.intervalData =  interval(5000).subscribe(() => {
      //   this.nextSlide();
      // })
      
    // this.intervalData=  this._subscriptions.push(
    //     interval(5000).subscribe(() => {
    //       this.nextSlide();
    //     })
    //   );
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  slideState(slide: HomePageSlide) {
    let state = '';
    let i = this.slides.indexOf(slide);
    if (slide == this.selectedSlide) {
      state = 'current';
    } else if (i > this.selectedSlideIndex) {
      state = 'next';
    } else if (i < this.selectedSlideIndex) {
      state = 'previous';
    }
    return state;
  }

  slidesTrackBy(i: number, slide: HomePageSlide) {
    return slide.id + slide.image;
  }

  nextSlide() {
    let nextIndex = this.selectedSlideIndex + 1;
    if (nextIndex > (this.slides.length - 1)) {
      nextIndex = 0;
    }
    this.selectedSlide = this.slides[nextIndex];
    clearInterval(this.intervalData);
    this.intervalData = setInterval(() => {
      this.nextSlide(); 
    }, 5000);
  }

  previousSlide() {
    let prevIndex = this.selectedSlideIndex - 1;
    if (prevIndex < 0) {
      prevIndex = (this.slides.length - 1);
    }
    this.selectedSlide = this.slides[prevIndex];
    clearInterval(this.intervalData);
    this.intervalData = setInterval(() => {
      this.nextSlide(); 
    }, 5000);
  }
navigation(url){
  // console.log(url)
  this._router.navigateByUrl(url['button_url'] ? url['buttonURL'] : '/');
}
clearInterval(){
  clearInterval(this.intervalData);
  this.intervalData = setInterval(() => {
    this.nextSlide(); 
  }, 5000);
}
}
