import { MibeModel } from './mibemodel.model';
import { MenuItem } from './menuitem.model';

export class Menu implements MibeModel {
  id: number;
  name: string;
  location: number;
  items: MenuItem[] = [];

  /**
   * Create a HomePageSlide.
   * @param {any} json - The JSON object with which to construct our HomePageSlide.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a HomePageSlide.
   * @param {any} json - The JSON object with which to load our HomePageSlide.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.location = json.location;
    if (json.items) {
      this.items = json.items.map(i => new MenuItem(i));
    }
  }
  /**
   * Returns a JSON representation of our HomePageSlide
   * @return {any} - The JSON representation of our HomePageSlide
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'location': this.location
    };
    if (this.items) {
      json['items'] = this.items.map(i => i.toJSON());
    }
    return json;
  }
}


export class SubMenu implements MibeModel {
  id: number;
  name: string;
  location: number;
  items: MenuItem[] = [];

  /**
   * Create a HomePageSlide.
   * @param {any} json - The JSON object with which to construct our HomePageSlide.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a HomePageSlide.
   * @param {any} json - The JSON object with which to load our HomePageSlide.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.location = json.location;
    if (json.items) {
      this.items = json.items.map(i => new MenuItem(i));
    }
  }
  /**
   * Returns a JSON representation of our HomePageSlide
   * @return {any} - The JSON representation of our HomePageSlide
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'location': this.location
    };
    if (this.items) {
      json['items'] = this.items.map(i => i.toJSON());
    }
    return json;
  }
}
