import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription, BehaviorSubject } from 'rxjs';
import { Album } from '../models/album.model';
import { Artist } from '../models/artist.model';
import { Track } from '../models/track.model';
import { AlbumTracks } from '../models/albumtracks.model';
import { ReorderedResult } from '../tracklist/tracklist.component';
import { AlbumTracksService } from '../services/albumtracks.service';
import { AlbumService } from '../services/album.service';
import { DialogService } from '../services/dialog.service';
import { moveItemInArray } from '@angular/cdk/drag-drop';

const tracksPageSize = 100;

@Component({
  selector: 'app-artist-album-tracks',
  templateUrl: './artist-album-tracks.component.html',
  styleUrls: ['./artist-album-tracks.component.scss']
})
export class ArtistAlbumTracksComponent implements OnInit {

  @Input()
  set album(a: Album) {
    this._album = a;
    this.loadTracks();
  }
  get album(): Album {
    return this._album;
  }
  @Input()
  set artist(artist) {
    this._artist = artist;
  }
  get artist() {
    return this._artist;
  }

  get is_editable() {
    return !!this.artist;
  }
  loading = true;
  reordering = false;
  savingReordering = false;
  favoriteLoading = false;
  tracks: Observable<ReadonlyArray<Track>>;

  totalTracks = 0;
  set currentTracksPage(p: number) {
    if (!isNaN(p) && p != this._currentTracksPage) {
      this._currentTracksPage = p;
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params});
    }
  }
  get currentTracksPage(): number {
    return this._currentTracksPage;
  }
  get tracksNumberOfPages(): number {
    return Math.ceil(this.totalTracks/tracksPageSize);
  }

  private get _params(): { [param: string]: string | string[]; } {
    let params = {};
    if (this.currentTracksPage && this.currentTracksPage != 1) {
      params['page'] = `${this.currentTracksPage}`;
    }
    return params;
  }

  private _currentTracksPage = 1;
  private _album: Album;
  private _artist: Artist;
  private _getTracksSubscription: Subscription;
  private _albumTracks: AlbumTracks[] = [];
  private _tracksSubject: BehaviorSubject<ReadonlyArray<Track>> = new BehaviorSubject<ReadonlyArray<Track>>([]);

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _albumTracksService: AlbumTracksService,
    private _albumService: AlbumService,
    private _dialogService: DialogService
  ) {
    this.tracks = this._tracksSubject.asObservable();
  }

  ngOnInit() {
  }

  loadTracks() {
    if (!this.album || !this.album.id) {
      return;
    }
    this.loading = true;
    if (this._getTracksSubscription && !this._getTracksSubscription.closed) {
      this._getTracksSubscription.unsubscribe();
    }
    this._getTracksSubscription = this._albumTracksService.getAlbumTracks({
      limit: tracksPageSize,
      albumId: this.album.id
    }).subscribe(pt => {
      this._albumTracks = pt.results;
      this._tracksSubject.next(pt.results.map(at=>at.track));
      this.loading = false;
    });
  }

  editDetails() {
    this._dialogService.displayCreateAlbum([this.artist], this.album).subscribe(a => {
      if(a) this.album = a;
    });
  }

  doneReordering() {
    this.reordering = false;
    this.savingReordering = true;
    this._albumTracksService.reorderTracks(this._albumTracks).subscribe(() => {
      this.savingReordering = false;
    }, () => {
      this.loadTracks();
      this.savingReordering = false;
    });
  }

  startReordering() {
    this.reordering = true;
  }

  reordered(result: ReorderedResult) {
    moveItemInArray(this._albumTracks, result.previousIndex, result.currentIndex);
    this._albumTracks.forEach((pt, i) => {
      pt.order = i + ((this.currentTracksPage - 1) * tracksPageSize);
    });
    this._tracksSubject.next(this._albumTracks.map(r => r.track));
  }

  favoriteAlbum() {
    if (!this._album) { return; }
    this.favoriteLoading = true;
    this._albumService.favoriteAlbum(this._album)
      .subscribe(a => {
        this.favoriteLoading = false;
        this.album = a;
      });
  }

  unfavoriteAlbum() {
    if (!this._album) { return; }
    this.favoriteLoading = true;
    this._albumService.unfavoriteAlbum(this._album)
      .subscribe(a => {
        this.favoriteLoading = false;
        this.album = a;
      });
  }
  sharePlaylist(artist) {
    // console.log(artist)
    this._dialogService.displayShareAlbumForm(artist);
  }

}
