import { Injectable } from '@angular/core';
import { Router } from '@angular/router';


export interface HistoryItem {
  url: string;
  name: string;
}


@Injectable({
  providedIn: 'root'
})
export class HistoryService {

  public _history: HistoryItem[] = [];

  constructor(
    private _router: Router
  ) {
  }

  addItemToHistory(name: string = null) {
    this._history.push({
      url: this._router.url,
      name: name
    });
  }

  previousItem(): HistoryItem {
    if (this._history.length > 1) {
      return this._history[this._history.length - 2];
    }
    return null;
  }

  navigateToPrevious() {
    this._history.pop();
    let prev = this._history.pop();
    if (!prev) { return; }
    this._router.navigateByUrl(prev.url);
  }

  navigateToItem(item: HistoryItem) {
    this._router.navigateByUrl(item.url);
  }
removeLastIndex(){
  this._history.pop();
}
}
