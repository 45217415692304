import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogData } from '../create-track/create-track.component';
import { PlaylistService } from '../services/playlist.service';
import { Playlist } from '../models/playlist.model';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { HttpClient } from '@angular/common/http';
import { DialogService } from '../services/dialog.service';

@Component({
  selector: 'app-download-playlist-pop',
  templateUrl: './download-playlist-pop.component.html',
  styleUrls: ['./download-playlist-pop.component.scss']
})
export class DownloadPlaylistPopComponent implements OnInit {
  private _subscriptions: Subscription[] = [];
  currentUser:User;
  loading:boolean = false;
  count = this.data['content']['_original_data'].approved_track_count;
  actualplaylistSize:any;
  actualmainTracksSize:any;
  playlistSizeinNumber:any;
  mainTracksSizeinNumber:any;
  constructor(  public dialogRef: MatDialogRef<DownloadPlaylistPopComponent>,private http: HttpClient,public _dialogService: DialogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public playlistService: PlaylistService,private _userService: UserService) { }

  ngOnInit() {
    //  console.log(this.data['content']['_original_data'].approved_track_count)
    // console.log(this.data)
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
    this.actualplaylistSize = this.playlist.playlist_size;
    this.actualmainTracksSize = this.playlist.main_track_size;
    this.playlistSizeinNumber = +this.actualplaylistSize.split(" ")[0];
    this.mainTracksSizeinNumber = +this.actualmainTracksSize.split(" ")[0];
  }
  get playlist() {
    return this.data['content'] as Playlist;
  }
  close() {
    this.dialogRef.close(null);
  }
  deletePlaylist(arg){
      this.playlistService.playlistloadDownload.next(arg)
      this.close();

  }
  
  downloadAll(row){
    if(this.playlistSizeinNumber < 4.00){
    let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/playlistdownload?id=${this.currentUser.id}`
    console.log(requestURL)
    window.open(requestURL)
    this.close()
    } else {
      this.close()
      let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/playlistdownload?id=${this.currentUser.id}&is_large_file=true`
      this.http.get(requestURL).subscribe((x:any)=>{
        this.close()
        this._dialogService.displaydisplayAlet(x.message,false)
      },(error)=>{
        this.loading = false
      this._dialogService.displaydisplayAlet(error.error.Error,false)
      this.close()
      });
    }
    // this.loading = true
    // let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/playlistdownload?id=${this.currentUser.id}&full_version_tracks=true`
    // this.http.get(requestURL,{
    //   responseType: 'arraybuffer'
    // }).subscribe(x=>{
    //   // window.open(x['url'], '_blank')
    //   // this.close();
    //   this.loading = false;
    //   const blob = new Blob([x], {
    //     type: 'application/zip'
    //   });
    //   const url = window.URL.createObjectURL(blob);
    //   window.open(url);

    //     this.close();
    // },(error)=>{
    //   // console.log(error)
    //   this.loading = false
    //   this._dialogService.displaydisplayAlet(error.error.Error,false)
    //   this.close()
    // })

   
  }
   downloadMyFilePlaylist(row) {
    if(this.mainTracksSizeinNumber < 4.00){
    this.loading = true
    
    let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/playlistdownload?id=${this.currentUser.id}&full_version_tracks=true`
   console.log(requestURL)
    window.open(requestURL)
    // this.http.get(requestURL).subscribe(x=>{
    //   // window.open(x['url'], '_blank')
    //   // this.close();
    //   this.loading = false
    //   const a = document.createElement('a')
    //     const objectUrl = URL.createObjectURL(x)
    //     a.href = objectUrl
    //     a.download = 'archive.zip';
    //     a.click();
    //     URL.revokeObjectURL(objectUrl);
    //     this.close();
    // },(error)=>{
    //   // console.log(error)
    //   this.loading = false;
    //   this._dialogService.displaydisplayAlet(error.error.Error,false)
      this.close()
    // })
    } else {
      this.close()
      let requestURL = environment.apiURL + `/api/v1/playlists/${row.id}/playlistdownload?id=${this.currentUser.id}&full_version_tracks=true&is_large_file=true`
      this.http.get(requestURL).subscribe((x:any)=>{
        this.close()
        this._dialogService.displaydisplayAlet(x.message,false)
      },(error)=>{
        this.loading = false
      this._dialogService.displaydisplayAlet(error.error.Error,false)
      this.close()
      });
    }
    
  
  }
}
