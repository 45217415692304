import { Component, OnInit, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../../shared/services/page-title.service';
import { environment } from '../../../environments/environment';
import { Subscription } from 'rxjs';
import { ArtistService } from '../../shared/services/artist.service';
import { Artist } from '../../shared/models/artist.model';
import { AnalyticsService } from '../../shared/services/gtm/analytics.service';

@Component({
  selector: 'app-artisttracks',
  templateUrl: './artisttracks.component.html',
  styleUrls: ['./artisttracks.component.scss']
})
export class ArtistTracksComponent implements OnInit, OnDestroy {

  get artist(): Artist {
    return this._artist;
  }
  set artist(a: Artist) {
    this._artist = a;
  }
  private _artist: Artist;
  private _subscriptions: Subscription[] = [];
  constructor(
    private _artistSerice: ArtistService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _analyticsService: AnalyticsService
  ) { }

  ngOnInit() {
    this._subscriptions.push(
      this._artistSerice.currentlyBrowsingArtist.subscribe(a => {
        this.artist = a;

        const title = this._pageTitleService.createPageTitle(['Artist', a.name, 'Tracks']);
        this._title.setTitle(title);
        const description = a.bio.substring(0, 155);
        const image = a.thumb_image;
        this._meta.addTags([
          {name: 'description', content: description},
          {name: 'twitter:card', content: 'summary'},
          {name: 'twitter:site', content: '@MIBE_music'},
          {name: 'twitter:title', content: `${a.name} Tracks on MIBE`},
          {name: 'twitter:description', content: description},
          {name: 'twitter:image', content: image},
          {name: 'og:type', content: 'website'},
          {name: 'og:title', content: `${a.name} Tracks`},
          {name: 'og:url', content: `${environment.protocol}://${environment.host}/artist/${a.id}`},
          {name: 'og:image', content: image},
          {name: 'og:description', content: description}
        ]);
      })
    );
    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

}
