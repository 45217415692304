import { MibeModel } from './mibemodel.model';

export class Product implements MibeModel {
  id: number;
  name: string;
  downloads: number;
  premium_quality_downloads: boolean;
  price: number;
  interval: string;

  /**
   * Create a Waveform.
   * @param {any} json - The JSON object with which to construct our Waveform.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Waveform.
   * @param {any} json - The JSON object with which to load our Waveform.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.downloads = json.downloads;
    this.premium_quality_downloads = json.premium_quality_downloads;
    this.price = json.price;
    this.interval = json.interval;
  }
  /**
   * Returns a JSON representation of our Waveform
   * @return {any} - The JSON representation of our Waveform
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'downloads': this.downloads,
      'premium_quality_downloads': this.premium_quality_downloads,
      'price': this.price,
      'interval': this.interval
    };
    return json;
  }
}
