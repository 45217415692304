import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree, ActivatedRoute } from '@angular/router';
import { Observable, AsyncSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { DialogService } from '../services/dialog.service';
import { CookieService } from '../services/cookie.service';
import { Location } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private _userService: UserService,
    private _dialogService: DialogService,
    private _router: Router,
    private _platform: Platform,
    private activateRouter: ActivatedRoute,
    private _cookieService: CookieService,
    private location: Location
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      this._userService.nextState = next['_routerState']['url'];
      const csrfToken = localStorage.getItem('user')
      console.log(next.url[0].path)
      // Get the previous page path
      let previousFullPath:any = this.location.path();
      // Split the previous page to get the path of the shared playlist page path if the previous path is shared playlist.
      let previousPath:any = previousFullPath.split("/");
      const canActivateSubject = new AsyncSubject<boolean | UrlTree>();
      if (csrfToken) {
        canActivateSubject.next(true);
        canActivateSubject.complete();
      }
   
    this._userService.currentUserStream.pipe(first()).subscribe(u => {
     
      if (u || csrfToken ) {
        canActivateSubject.next(true);
        canActivateSubject.complete();
      } else if (this._platform.isBrowser) {
        // console.log(canActivateSubject)
        this._dialogService.displayLoginForm().pipe(first()).subscribe(u => {
          if (u) {
            canActivateSubject.next(true);
          } else {
            // Added the condition to prevent the navigation to the home page if the previous path is shared playlist's path. otherwise it'll navigate us to the home page.
            if(previousPath && previousPath[1]=='shared-playlist'){
              this._router.navigateByUrl(`/${previousFullPath}`)
            } else {
            canActivateSubject.next(this._router.createUrlTree(['/']));
            }
          }
          canActivateSubject.complete();
        });
      }
    });
    return canActivateSubject.asObservable();

  }

}
