import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { TrackType } from '../models/tracktype.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class TrackTypeService {

  constructor(private http: HttpClient) { }

  getTrackTypes(): Observable<TrackType[]> {
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/tracktypes/',
      {
        headers: headers
      }
    ).pipe(map((res) => res.results.map((s) => new TrackType(s))));
  }
}
