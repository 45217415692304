import { Component, OnInit, OnDestroy, ElementRef, ViewChild, Renderer } from '@angular/core';
import { FormControl } from '@angular/forms';
import { environment } from '../../environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Meta, Title } from '@angular/platform-browser';
import { GetTracksOptions, TrackService } from '../shared/services/track.service';
import { PageTitleService } from '../shared/services/page-title.service';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';

@Component({
  selector: 'app-browse',
  templateUrl: './browse.component.html',
  styleUrls: ['./browse.component.scss']
})
export class BrowseComponent implements OnInit, OnDestroy {

  @ViewChild('searchActionField', {static: false})
  searchActionField: ElementRef;
  searchControl = new FormControl();
  loading = false;

  get getTracksOptions(): GetTracksOptions {
    return this._getTracksOptions;
  }
  set getTracksOptions(o: GetTracksOptions) {
    this._getTracksOptions = o;
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      if (this.searchControl.value != s) {
        this.searchControl.setValue(s);
      }
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: {
        'search': s,
        'page': 1
      }, queryParamsHandling: 'merge'});
    }
  }

  private _subscriptions: Subscription[] = [];
  private _searchString = '';
  private _getTracksOptions: GetTracksOptions = {};
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _trackService: TrackService,
    private _pageTitleService: PageTitleService,
    private _renderer: Renderer,
    private _title: Title,
    private _meta: Meta,
    private _analyticsService: AnalyticsService,
    private _historyService: HistoryService
  ) {
  }

  ngOnInit() {
    this._subscriptions.push(
      this._activatedRoute.paramMap.subscribe(params => {
        let trackId = params.get('trackId');
        let trackIdN: number;
        if (trackId) {
          trackIdN = parseInt(trackId);
          this._getTracksOptions.trackIds = [trackIdN];
          this.loading = true;
          this._trackService.getTrack(trackIdN)
            .subscribe(t => {
              this.loading = false;
              // Set SEO Stuff
              this._title.setTitle(this._pageTitleService.createPageTitle(['Track', t.title]));
              const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
              this._meta.addTags([
                {name: 'description', content: t.description},
                {name: 'twitter:card', content: 'summary'},
                {name: 'twitter:site', content: '@MIBE_music'},
                {name: 'twitter:title', content: `${t.title} on MIBE`},
                {name: 'twitter:description', content: t.description},
                {name: 'twitter:image', content: image},
                {name: 'og:type', content: 'website'},
                {name: 'og:title', content: `${t.title}`},
                {name: 'og:url', content: `${environment.protocol}://${environment.host}/browse/${t.id}`},
                {name: 'og:image', content: image},
                {name: 'og:description', content: t.description}
              ]);
            });
        } else {
          const title = this._pageTitleService.createPageTitle(['Tracks']);
          this._title.setTitle(title);
          const description = this._pageTitleService.mainSiteDescription;
          const image = `${environment.protocol}://${environment.host}/assets/img/soundboard-page-marquee.jpg`;
          this._meta.addTags([
            {name: 'description', content: description},
            {name: 'twitter:card', content: 'summary'},
            {name: 'twitter:site', content: '@MIBE_music'},
            {name: 'twitter:title', content: `Browse Tracks on MIBE`},
            {name: 'twitter:description', content: description},
            {name: 'twitter:image', content: image},
            {name: 'og:type', content: 'website'},
            {name: 'og:title', content: `Browse Tracks`},
            {name: 'og:url', content: `${environment.protocol}://${environment.host}/browse`},
            {name: 'og:image', content: image},
            {name: 'og:description', content: description}
          ]);
        }
      })
    );
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params => {
        this._analyticsService.pageView();
        // Load Filters from URL
        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }

        this._historyService.addItemToHistory('Browse');
      })
    );
    this._subscriptions.push(
      this.searchControl.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(v => {
        this.searchString = v;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  searchAction() {
    if (this.searchActionField) {
      this._renderer.invokeElementMethod(this.searchActionField.nativeElement, 'blur');
    }
  }

}
