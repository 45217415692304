import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { Menu } from '../models/menu.model';
import { MenuItem } from '../models/menuitem.model';
import { MenuService } from '../services/menu.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  loading = true;
  leftMenu: Menu;
  centerMenu: Menu;
  rightMenu: Menu;

  year = (new Date()).getFullYear();

  constructor(
    private _menuService: MenuService
  ) { }

  ngOnInit() {
    this.loading = true;
    forkJoin(
      this._menuService.getLeftFooterMenu(),
      this._menuService.getCenterFooterMenu(),
      this._menuService.getRightFooterMenu()
    ).subscribe(([leftMenu, centerMenu, rightMenu]) => {
      this.loading = false;
      this.leftMenu = leftMenu;
      this.centerMenu = centerMenu;
      this.rightMenu = rightMenu;
    });
  }

  menuTrackBy(_: number, m: MenuItem) {
    return m.id + m.url;
  }
  artistNavigate(){
    window.location.href = "https://admin.mibemusic.com/"
  }
}
