import { Component, OnInit, OnDestroy, Inject, HostBinding, ViewChild } from '@angular/core';
import {
  trigger,
  style,
  animate,
  transition,
  group,
  query,
  state
} from '@angular/animations';
import { MatInput } from '@angular/material/input';
import { OverlayRef, FlexibleConnectedPositionStrategy } from '@angular/cdk/overlay';
import { Subscription, combineLatest } from 'rxjs';
import { Track } from '../models/track.model';
import { Album } from '../models/album.model';
import { Artist } from '../models/artist.model';
import { AlbumService } from '../services/album.service';
import { ArtistService } from '../services/artist.service';
import { DialogService } from '../services/dialog.service';
import { ALBUM_POPOVER_DIALOG_DATA } from '../tokens/albumpopover.tokens';

function isFlexibleConnectedPositionStrategy(obj: any): obj is FlexibleConnectedPositionStrategy {
  return obj.positionChanges !== undefined
}

@Component({
  selector: 'app-albumpopover',
  templateUrl: './albumpopover.component.html',
  styleUrls: ['./albumpopover.component.scss'],
  animations: [
    trigger('popoverInOut', [
      transition(':enter', [
        group([
          query('.popover-container', [
            style({
              opacity: 0,
              transform: 'scale(0.8)'
            }),
            animate('120ms cubic-bezier(0, 0, 0.2, 1)', style({
              opacity: 1,
              transform: 'scale(1)'
            }))
          ])
        ])
      ]),
      transition(':leave', animate('100ms 25ms linear', style({opacity: 0})))
    ])
  ]
})
export class AlbumPopoverComponent implements OnInit, OnDestroy {

  @HostBinding('@popoverInOut')
  public animateMenu = true;

  arrowAfter = false;
  arrowAbove = false;
  hideArrow = false;

  loading = true;
  albums: Album[] = [];
  featuredArtists: Artist[] = [];

  @ViewChild('createAlbumField', {static: false})
  set createAlbumField(f: MatInput) {
    this._createAlbumField = f;
    if (f) {
      setTimeout(() => {
        f.focus();
      });
    }
  }
  get createAlbumField(): MatInput {
    return this._createAlbumField;
  }

  private _subscriptions: Subscription[] = [];
  private _createAlbumField: MatInput;

  constructor(
    @Inject(ALBUM_POPOVER_DIALOG_DATA) public track: Track,
    private _overlayRef: OverlayRef,
    private _albumService: AlbumService,
    private _artistService: ArtistService,
    private _dialogService: DialogService
  ) { }

  ngOnInit() {
    if (isFlexibleConnectedPositionStrategy(this._overlayRef.getConfig().positionStrategy)) {
      const positionStrategy = this._overlayRef.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
      this._subscriptions.push(positionStrategy.positionChanges.subscribe(changes => {
        this.arrowAfter = changes.connectionPair.originX == 'start';
        this.arrowAbove = changes.connectionPair.originY == 'top';
      }));
      this.hideArrow = false;
    } else {
      this.hideArrow = true;
    }

    this.loading = true;
    combineLatest(
      this._albumService.getEditableAlbums(this.track.artist, this.track.id),
      this._artistService.currentUserArtistsStream
    ).subscribe(([albums, artists]:any) => {
      this.albums = albums;
      this.featuredArtists = artists.filter(a=>a.is_featured);
      this.loading = false;
    });
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  albumsTrackBy(i: number, album: Album) {
    return album.id + album.name;
  }

  createAlbum() {
    this._dialogService.displayCreateAlbum(this.featuredArtists).subscribe(a=>{
      if (!a) return;
      this.albums.unshift(a);
    })
  }

  addTrackToAlbum(p: Album) {
    this.loading = true;
    this._albumService.addTracksToAlbum(p.id, [this.track.id]).subscribe(up => {
      this.loading = false;
      p.loadWithJSON(up.toJSON());
    });
  }

  removeTrackFromAlbum(p: Album) {
    this.loading = true;
    this._albumService.removeTracksFromAlbum(p.id, [this.track.id]).subscribe(up => {
      this.loading = false;
      p.loadWithJSON(up.toJSON());
    });
  }

  toggleTrackInAlbum(p: Album) {
    if (p.containsTrack(this.track.id)) {
      this.removeTrackFromAlbum(p);
    } else {
      this.addTrackToAlbum(p);
    }
  }

  albumContainsTrack(p: Album): boolean {
    return p.containsTrack(this.track.id);
  }

}
