import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';

import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Playlist } from '../models/playlist.model';
import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from "ngx-stripe";
import { Subscription as Sub } from '../models/subscription.model';
import { SubscriptionService } from '../services/subscription.service';
import { TrackService } from '../services/track.service';
import { TrackVersionService } from '../services/trackversion.service';
import { PlaylistService } from '../services/playlist.service';
import { SiteConfiguration } from '../models/siteconfiguration.model';
import { SiteConfigurationService } from '../services/siteconfiguration.service';
import { PaymentSourceService } from '../services/paymentsource.service';

export interface TrackDownloadData {track:Track, version:TrackVersion}

export interface DialogData {
  type: 'track' | 'playlist',
  content: TrackDownloadData | Playlist
}

@Component({
  selector: 'app-downloaddialog',
  templateUrl: './downloaddialog.component.html',
  styleUrls: ['./downloaddialog.component.scss']
})
export class DownloadDialogComponent implements OnInit, OnDestroy {

  @ViewChild(StripeCardComponent, {static: false})
  card: StripeCardComponent;

  loading = true;
  purchasing = false;
  displayErrors = false;
  noSubscription = false;
  hasPaymentSources = false;
  subscription:Sub;
  siteConfig: SiteConfiguration;
  numDownloadsPlaylistNeeds:number = 1;
  error = '';

  cardOptions: ElementOptions = {};

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  get pricePerSong(): string {
    if (!this.siteConfig || !this.siteConfig.pay_per_track_price) { return ''; }
    return '$' + (this.siteConfig.pay_per_track_price / 100).toFixed(2);
  }

  get playlist() {
    return this.data.content as Playlist;
  }


  get trackData() {
    return this.data.content as TrackDownloadData;
  }

  get isPlaylist(): boolean {
    return this.data.type == 'playlist';
  }

  get title() {
    return this.isPlaylist ? this.playlist.name: this.trackData.track.title;
  }

  get downloadAvailable() {
    if (this.noSubscription || !this.subscription) {
      return false;
    }
    return this.numDownloadsPlaylistNeeds <= this.subscription.available_downloads;
  }

  get availableDownloads() {
    if (this.subscription) return this.subscription.available_downloads;
    return 0;
  }

  get numDownloadsUsed() {
    if (this.subscription) return this.subscription.current_period_downloads_count;
    return -1;
  }

  get totalPeriodDownloads() {
    if (this.subscription && this.subscription.product) return this.subscription.product.downloads;
    return 0;
  }

  private _subscriptions: Subscription[] = [];

  constructor(
    private _platform: Platform,
    private _subscriptionService: SubscriptionService,
    private _paymentSourceService: PaymentSourceService,
    private _trackService: TrackService,
    private _siteConfigurationService: SiteConfigurationService,
    private _trackVersionService: TrackVersionService,
    private _stripeService: StripeService,
    private _playlistService: PlaylistService,
    public dialogRef: MatDialogRef<DownloadDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this._paymentSourceService.getAllPaymentSources().subscribe(sources => {
      this.hasPaymentSources = sources.length > 0;
    });
    this._siteConfigurationService.getSiteConfiguration().subscribe(c => {
      this.siteConfig = c;
      this._subscriptionService.getCurrentSubscription().subscribe(s=>{
        if (s && s.product && s.product.downloads == -1) {
          this.initiateDownload();
          return;
        }
        this.subscription = s;
        if (this.isPlaylist) {
          this._trackService.getTracks({downloadable: true, playlistId:this.playlist.id, limit: 1}).subscribe(tracks=> {
            if (tracks && tracks.results && tracks.count == this.playlist.track_count) {
              this.initiateDownload();
              return;
            }
            this.numDownloadsPlaylistNeeds = this.playlist.track_count - tracks.count;
            this.loading = false;
          });
        } else {
          this._trackService.getTracks({downloadable: true, trackIds:[this.trackData.track.id], limit: 1}).subscribe(tracks=> {
            if (tracks && tracks.count > 0) {
              this.initiateDownload();
              return;
            }
            this.loading = false;
          });
        }
      }, (_) => {
        this.noSubscription = true;
        this.loading = false;
      });
    });
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close();
  }

  initiateDownload() {
    this.loading = true;
    if (this.isPlaylist) {
      this._playlistService.downloadPlaylist(this.playlist.id).subscribe(d=> {
        this.loading = false;
        if (this._platform.isBrowser && window) {
          window.location.href = d.download_url;
        }
        this.close();
      });
      return;
    }
    this._trackVersionService.downloadTrackVersion(this.trackData.version).subscribe(d=> {
      this.loading = false;
      if (this._platform.isBrowser && window) {
        window.location.href = d.download_url;
      }
      this.close();
    });

  }

  oneTimePurchase() {
    this.purchasing = true;
    this._trackVersionService.purchaseTrackVersion(this.trackData.version)
      .subscribe(d => {
        this.purchasing = false;
        if (this._platform.isBrowser && window) {
          window.location.href = d.download_url;
        }
        this.close();
      }, _ => {
        this.purchasing = false;
        this.displayErrors = true;
      });
  }

  oneTimePurchaseAddingPaymentMethod() {
    this.purchasing = true;
    this.error = '';
    this._stripeService.createToken(this.card.getCard(), {})
      .subscribe(result => {
        if (result.token) {
          this._paymentSourceService.createPaymentSource(result.token.id)
            .subscribe(_ => {
              this.oneTimePurchase();
            });
        } else if (result.error) {
          // console.log(result.error);
          this.error = result.error.message;
          this.purchasing = false;
        }
      });
  }

}
