import { MibeModel } from './mibemodel.model';

export class Playlist implements MibeModel {
  id: number;
  name: string;
  description: string;
  thumb_image: string;
  marquee_image: string;
  is_featured: boolean;
  is_client_seen: boolean;
  is_public: boolean;
  is_editable: boolean;
  track_count: number;
  banner_image:string;
  playlist_size:string;
  main_track_size:string;

  private _original_data: any;

  /**
   * Create a Playlist.
   * @param {any} json - The JSON object with which to construct our Playlist.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Playlist.
   * @param {any} json - The JSON object with which to load our Playlist.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this._original_data = json;
    this.id = json.id;
    this.name = json.name;
    this.description = json.description;
    this.thumb_image = json.thumb_image;
    this.marquee_image = json.marquee_image;
    this.banner_image = json.banner_image
    this.is_featured = !!json.is_featured;
    this.is_client_seen = !!json.is_client_seen;
    this.is_public = !!json.is_public;
    this.is_editable = !!json.is_editable;
    this.track_count = json.track_count;
    this.playlist_size = json.playlist_size;
    this.main_track_size = json.main_track_size;
  }
  /**
   * Returns a JSON representation of our Playlist
   * @return {any} - The JSON representation of our Playlist
   */
  toJSON() {
    var json = this._original_data;
    json['id'] = this.id;
    json['name'] = this.name;
    json['description'] = this.description;
    json['thumb_image'] = this.thumb_image;
    json['marquee_image'] = this.marquee_image;
    json['banner_image'] = this.banner_image
    json['is_featured'] = this.is_featured;
    json['is_client_seen'] = this.is_client_seen;
    json['is_public'] = this.is_public;
    json['is_editable'] = this.is_editable;
    json['track_count'] = this.track_count;
    json['playlist_size'] = this.playlist_size;
    json['main_track_size'] = this.main_track_size;
    return json;
  }

  containsTrackVersion(id: number): boolean {
    return this._original_data['contains_track_version_' + id];
  }
}
