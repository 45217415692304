import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PlaylistService } from '../services/playlist.service';
import { Playlist } from '../models/playlist.model';

export interface DialogData {
  animal: string;
  name: string;
}


@Component({
  selector: 'app-deleteplaylist',
  templateUrl: './deleteplaylist.component.html',
  styleUrls: ['./deleteplaylist.component.scss']
})
export class DeleteplaylistComponent implements OnInit {
  
 
  constructor(  public dialogRef: MatDialogRef<DeleteplaylistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,public playlistService: PlaylistService) { }

  ngOnInit() {
    // console.log(this.data)
  }
  get playlist() {
    return this.data['content'] as Playlist;
  }
  close() {
    this.dialogRef.close(null);
  }
  deletePlaylist(){
    this.playlistService.deletePlaylist(this.playlist.id).subscribe(x=>{
      this.close();
      this.playlistService.playlistload.next(x)
    })
  }
}
