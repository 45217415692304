import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { PlaylistTracks } from '../models/playlisttracks.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Filters, filtersToParams } from './track.service';
import { Track } from '../models/track.model';

export interface GetPlaylistTracksOptions {
  playlistId: number;
  limit?: number;
  offset?: number;
  filters?: Filters;
  page?: any;
}

@Injectable({
  providedIn: 'root'
})
export class PlaylistTracksService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getPlaylistTracks(options: GetPlaylistTracksOptions): Observable<APIListResponse<Track>> {
    let params: any = {};
    if (options.filters) {
      params = filtersToParams(options.filters);
    }
    params['playlist_id'] = `${options.playlistId}`;
    if (options.limit) {
      params['limit'] = `${options.limit}`;
    }
    if (options.offset) {
      params['offset'] = `${options.offset}`;
    }
    if (options.page) {
      params['page'] = `${options.page}`;
    }
    debugger
    // Check if playlist is cached from server
    const PLAYLIST_TRACKS_KEY = makeStateKey<APIListResponse<Object>>('playlisttracks-' + options.playlistId);
    if (this._transferState.hasKey(PLAYLIST_TRACKS_KEY)) {
      const playlistTracks = this._transferState.get<APIListResponse<Object>>(PLAYLIST_TRACKS_KEY, null);
      this._transferState.remove(PLAYLIST_TRACKS_KEY);
      return of({
        next: playlistTracks.next,
        previous: playlistTracks.previous,
        count: playlistTracks.count,
        results: playlistTracks.results.map(pt => new Track(pt))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse<Object>>(
      environment.apiURL + '/api/v1/playlisttracks/',
      {
        headers: headers,
        params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_TRACKS_KEY, res);
        }
      }),
      map((res:any) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.data.map(pt => new Track(pt)),
          complete_palylist_size: res.results.complete_palylist_size,
          main_tracks_size: res.results.main_tracks_size,
        };
      })
    );
  }

  reorderTracks(playlistTracks: PlaylistTracks[],arg){
   
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    let request = `${environment.apiURL }/api/v1/playlisttracks/${arg}/update-reorder/`
   
    return this.http.put<Object>(request,{reorder_info :playlistTracks.map(pt => { return  {order: pt.order,top_version: pt.client_top_version}})}
      ,
      {
        headers: headers
      }
    );
  }
}
