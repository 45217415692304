import { Component, OnInit, Input } from '@angular/core';
import { Genre } from '../../shared/models/genre.model';
import { TrackService } from '../services/track.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-genrecover',
  templateUrl: './genrecover.component.html',
  styleUrls: ['./genrecover.component.scss']
})
export class GenrecoverComponent implements OnInit {

  @Input()
  genre: Genre;

  get backgroundImage(): string {
    let backgroundImage = '';
    if (this.genre && this.genre.thumb_image) {
      backgroundImage = `url(${this.genre.thumb_image})`;
    } else if (this.genre && this.genre.marquee_image) {
      backgroundImage = `url(${this.genre.marquee_image})`;
    } else if (this.genre && this.genre.parent) {
      if (this.genre.parent.thumb_image) {
        backgroundImage = `url(${this.genre.parent.thumb_image})`;
      } else if (this.genre.parent.marquee_image) {
        backgroundImage = `url(${this.genre.parent.marquee_image})`;
      }
    }
    return backgroundImage;
  }

  constructor(public TrackService: TrackService, public _router: Router) { }

  ngOnInit() {
  }
subGnereChange(genre){
  // console.log(genre)
  if(genre.parent){
    this.TrackService.CURRENTpopularGenre.next(genre)
  }
  else{
    this.setGenre(genre)
  }
 
}

setGenre(genre: Genre) {
  if (genre) {
    this._router.navigate(
      ['/genre', genre.id],
      {state: {data: genre.toJSON()}}
    );
  } else {
    this._router.navigate(['/browse']);
  }
}
}
