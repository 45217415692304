import {
  Component,
  OnInit,
  OnDestroy,
  Output,
  Input,
  EventEmitter,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  trigger,
  style,
  query,
  animate,
  stagger,
  transition,
  group
} from '@angular/animations';
import { FormControl } from '@angular/forms';
import { ReplaySubject, Subscription, of, Subject } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map,
  takeUntil,
  take,
  first
} from 'rxjs/operators';
import { Filters, MinMax, minMaxToString, stringToMinMax } from '../services/track.service';
import { ArtistService, FilterableArtist } from '../services/artist.service';
import { Router } from '@angular/router';
import { MatSelect } from '@angular/material';
import { HttpClient } from '@angular/common/http';
// import { environment } from '../../../environments/environment.prod';
import { environment } from '../../../environments/environment';
import { PlaylistService } from '../services/playlist.service';

interface SelectString {
  value: string;
  label: string;
  min?: any;
  max?: any
}

@Component({
  selector: 'app-pagefilters',
  templateUrl: './pagefilters.component.html',
  styleUrls: ['./pagefilters.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('emphasizeControls', [
      transition('*=>*', [
        query('.mat-form-field-ripple', [
          stagger(300, [
            group([
              animate('0.1s cubic-bezier(.25,.8,.25,1)', style({
                opacity: '1',
              })),
              animate('0.3s cubic-bezier(.25,.8,.25,1)', style({
                transform: 'scaleX(1)',
                'background-color': '#50e3c2'
              }))
            ]),
            animate('0.3s 0.1s cubic-bezier(.25,.8,.25,1)')
          ])
        ], { optional: true })
      ])
    ])
  ]
})
export class PageFiltersComponent implements OnInit, OnDestroy {

  artistFilterCtrl = new FormControl();
  filteredArtistOptions: ReplaySubject<FilterableArtist[]> = new ReplaySubject<FilterableArtist[]>(1);
  searchingArtist = false;
  tempoFilterCtrl = new FormControl();
  filteredTempoOptions: ReplaySubject<SelectString[]> = new ReplaySubject<SelectString[]>(1);

  emphasizeControlsState = 0;
  tmpArtistList: any[] = [];
  tmpTempoList: any[] = [];
  artist: any = null;
  tempData: string = ""
  @Input()
  set filters(f: Filters) {
    this._filters = f;
    if (!(f.artistId || f.composerId) && this.selectedArtist) {
      this.selectedArtist = null;
    } else if ((f.artistId || f.composerId) && (!this.selectedArtist || (this.selectedArtist.id != f.artistId && this.selectedArtist.id != f.composerId))) {
      this.loading = true;
      this._artistService.getAllArtistsForFilter()
        .subscribe((artists: any[]) => {
          if (artists.length <= 0) {
            this.loading = false;
            return;
          }
          let art = artists.filter(x => x.id == this.filters.artistId)
          this.bankCtrl.setValue(art[0])


          this.filteredBanks.next(artists);
          // this.selectedArtist = {id:a.id, name:a.name, type:a.type};
          // this.filteredArtistOptions.next(artists);
          this.tmpArtistList = artists || [];
          this.loading = false;
          this._cdr.detectChanges();
        });
    }
  }
  get filters(): Filters {
    return this._filters;
  }
  get duration(): string {
    return minMaxToString(this.filters.duration);
  }
  // get tempo(): string {
  //   return minMaxToString(this.filters.tempo);
  // }
  get bpm(): string {
    return this.filters.bpm;
  }
  @Output()
  filtersChange = new EventEmitter<Filters>();

  get filtersApplied(): boolean {
    return !!this.filters.duration || !!this.filters.tempo || this.filters.vocals != null || !!this.filters.artistId;
  }

  get selectedArtist(): FilterableArtist {
    return this._selectedArtist;
  }
  set selectedArtist(a: FilterableArtist) {
    this._selectedArtist = a;
    console.log(this.filters.tempo)
    this.filters = {
      duration: this.filters.duration,
      tempo: this.filters.tempo,
      vocals: this.filters.vocals,
      artistId: this.filters.artistId,
      composerId: a && a.type == 'composer' ? a.id : null,
      bpm: this.filters.bpm
    };
    this.filtersChange.emit(this.filters);
  }

  loading = false;

  private _filters: Filters = {
    duration: null,
    tempo: null,
    vocals: null,
    artistId: null,
    composerId: null,
    bpm: null
  };
  private _subscriptions: Subscription[] = [];
  private _selectedArtist: FilterableArtist;
  tempoDrop: any = []
  constructor(
    private _cdr: ChangeDetectorRef,
    private _artistService: ArtistService,
    private router: Router,
    private http: HttpClient,
    private _playlistService: PlaylistService
  ) {
    this.http.get(environment.apiURL + '/api/v1/track/tempo-dropdown/').subscribe(x => {
      this.tempoDrop = x;
    })
  }


  ngOnInit() {

    this._subscriptions.push(this.bankFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingArtist = true;
      }),
      switchMap((v) => {

        // if (!v || v.length <= 2) {
        //   if (this.selectedArtist) {
        //     return of([this.selectedArtist]);
        //   } else {
        //     return of([]);
        //   }
        // }

        //search word must contain at least 3 characters to display artist options
        if (!v || v.length < 3) {

          // load the initial bank list
          this.filteredBanks.next([]);

          this.filteredArtistOptions.next([]); // Clear options if input is less than 3 characters
          this.searchingArtist = false; // Stop searching indicator
          return of([]); // Return an empty observable
        }

        else {

          // load the initial bank list
          //this.filteredBanks.next(this.banks.slice());

          return this._artistService.getAllArtistsForFilter().pipe(
            map(a => {

              let contains_selected = a.findIndex(artist => {
                return !this.selectedArtist || artist.id == this.selectedArtist.id;
              }) != -1;
              if (!contains_selected && this.selectedArtist) {
                a.push(this.selectedArtist);
              }
              return a;
            })
          );
        }

      }),
      tap(() => {
        this.searchingArtist = false;
      })
    ).subscribe(a => {
      this.filteredArtistOptions.next(a);
    }));


    const baseTempoOptions = this.tempoDrop;
    this._subscriptions.push(this.tempoFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      map((v) => {
        let addedTempoOptions: SelectString[] = [];
        if (this.filters.tempo) {
          let tempoStr = minMaxToString(this.filters.tempo);
          if (baseTempoOptions.findIndex(o => o.value == tempoStr) == -1) {
            addedTempoOptions.push({ value: tempoStr, label: `${this.filters.tempo.min} bpm` });
          }
        }
        if (!!v && !isNaN(parseInt(v))) {
          addedTempoOptions.push({ value: v + ':' + `${parseInt(v) + 1}`, label: `${v} bpm` });
        }
        this.tmpTempoList = baseTempoOptions || []
        return addedTempoOptions.concat(baseTempoOptions);
      })
    ).subscribe(o => {
      this.filteredTempoOptions.next(o);
    }));
    this._artistService.getAllArtistsForFilter()
      .subscribe((artists: FilterableArtist[]) => {
        if (artists.length <= 0) {
          this.loading = false;
          return;
        }
        let a = artists[0]
        this.selectedArtist = { id: a.id, name: a.name, type: a.type };
        this.filteredArtistOptions.next(artists);
        this.tmpArtistList = artists || [];
        this.loading = false;
        this._cdr.detectChanges();
      });
    // -----------------------------------------------------
    // set initial selection


    // // load the initial bank list
    // this.filteredBanks.next(this.banks.slice());

    // listen for search field value changes
    this.bankFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterBanks();
      });
  }


  // artistChange
  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  resetFilters() {
    this.artist = null
    this.filters = {
      duration: null,
      tempo: null,
      vocals: null,
      artistId: null,
      composerId: null,
      bpm: null
    };



    this.filtersChange.emit(this.filters);
    var short_addresses = this.router.url.split('?')[0]
    // window.location.replace(`${environment.host}${short_addresses}`);

    // console.log(environment)
    this.bankCtrl.setValue(null)
    this.bankFilterCtrl.setValue(null)
    this.artistChanged(null)
    this.router.navigateByUrl(short_addresses)
    this.getValue();
    this.tempData = ""
  }

  durationChanged(duration: string) {
    // console.log(duration)
    this.filters = {
      duration: stringToMinMax(duration),
      tempo: this.filters.tempo,
      vocals: this.filters.vocals,
      artistId: this.filters.artistId,
      composerId: this.filters.composerId,
      bpm: this.filters.bpm
    };
    this.filtersChange.emit(this.filters);
  }
  artistChanged(artistId: any) {
    this.filteredArtistOptions.next(this.tmpArtistList)
    // console.log(this.bankCtrl)
    // console.log(this.bankFilterCtrl)
    this.filters = {
      duration: this.filters.duration,
      tempo: this.filters.tempo,
      vocals: this.filters.vocals,
      artistId: artistId,
      composerId: this.filters.composerId,
      bpm: this.filters.bpm
    };
    // console.log()
    this.filtersChange.emit(this.filters);
  }
  tempoChanged(tempo: any) {
    this.filters = {
      duration: this.filters.duration,
      tempo: tempo,
      vocals: this.filters.vocals,
      artistId: this.filters.artistId,
      composerId: this.filters.composerId,
      bpm: null,

    };
    this.tempData = tempo
    this.filtersChange.emit(this.filters);
  }
  vocalsChanged(vocals: boolean) {
    this.filters = {
      duration: this.filters.duration,
      tempo: this.filters.tempo,
      vocals: vocals,
      artistId: this.filters.artistId,
      composerId: this.filters.composerId,
      bpm: this.filters.bpm
    };
    this.filtersChange.emit(this.filters);
  }
  applyFilter(bpm) {
    this.filters = {
      duration: this.filters.duration,
      tempo: null,
      vocals: this.filters.vocals,
      artistId: this.filters.artistId,
      composerId: this.filters.composerId,
      bpm: bpm
    };
    this.tempData = bpm
    this.filtersChange.emit(this.filters);
    // console.log(bpm)
  }
  artistCompareWith(a1: FilterableArtist, a2: FilterableArtist) {
    if (!a1 || !a2) {
      return a1 == a2;
    }
    return a1.id == a2.id;
  }

  selectStringCompareWith(o1: SelectString, o2: SelectString) {
    if (!o1 || !o2) {
      return o1 == o2;
    }
    return o1.value == o2.value;
  }

  artistsTrackBy(i: number, artist: FilterableArtist) {
    if (!artist) {
      return artist;
    }
    return i + '-' + artist.id + '-' + artist.type;
  }

  selectStringTrackBy(i: number, s: SelectString) {
    if (!s) {
      return s;
    }
    return s.value + s.label;
  }
  onSearchArtist(value) {
    let newArtistsList = this.searchArtist(value);
    this.filteredArtistOptions.next(newArtistsList);
  }
  artistChangeData() {

  }
  searchArtist(value: string) {
    // console.log(value)
    let filterArtist = value.toLowerCase();
    return this.tmpArtistList.filter(option => option.name.toLowerCase().includes(filterArtist));
  }

  onSearchTempo(value) {
    let newTempoList = this.searchTempo(value);
    this.filteredTempoOptions.next(newTempoList);
  }

  searchTempo(value: any) {
    if (value) {
      if (typeof (Number(value)) == 'number') {
        let filterTempo = Number(value);
        let result = this.tmpTempoList.filter(option => ((filterTempo >= Number(option.min)) && filterTempo <= (Number(option.max))));

        return result;
      }
      else {
        let filterTempo = value.toLowerCase();
        return this.tmpTempoList.filter(option => option.value.toLowerCase().startsWith(filterTempo));
      }
    }
    else {
      let filterTempo = value.toLowerCase();
      return this.tmpTempoList.filter(option => option.value.toLowerCase().startsWith(filterTempo));
    }

  }
  getValue() {

    if (this.filters.tempo) {
      this.tempData = this.filters.tempo.tempo_name
    }
    if (this.filters.bpm) {
      this.tempData = this.filters.bpm
      return this.filters.bpm

    }
    if (!this.filters.bpm && !this.filters.tempo) {

      return "";

    }

  }

  getInputValue() {
    if (this.filters.bpm) {
      return this.filters.bpm
    }
  }
  gettempoValue(e) {
    const baseTempoOptions = this.tempoDrop
    let data = baseTempoOptions.filter(x => x.value == e)
    // console.log(e)
    return data.length !== 0 ? data[0].label : ""

  }
  // -------------------------------------------------------------------------------------------------------





  /** list of banks */
  protected banks: Bank[] = [];

  /** control for the selected bank */
  public bankCtrl: FormControl = new FormControl();

  /** control for the MatSelect filter keyword */
  public bankFilterCtrl: FormControl = new FormControl();

  /** list of banks filtered by search keyword */
  public filteredBanks: ReplaySubject<Bank[]> = new ReplaySubject<Bank[]>(1);

  @ViewChild('singleSelect', { static: false }) singleSelect: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();



  ngAfterViewInit() {
    this._artistService.getAllArtistsForFilter().subscribe((x: any) => {
      this.banks = x;
      // this.bankCtrl.setValue();
      //this.filteredBanks.next(this.banks.slice());
    })


    // console.log(this.selectedArtist)
    // this.setInitialValue();

  }

  /**
   * Sets the initial value after the filteredBanks are loaded initially
   */
  protected setInitialValue() {


    this.filteredBanks
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        // setting the compareWith property to a comparison function
        // triggers initializing the selection according to the initial value of
        // the form control (i.e. _initializeSelection())
        // this needs to be done after the filteredBanks are loaded initially
        // and after the mat-option elements are available
        this.singleSelect.compareWith = (a: Bank, b: Bank) => a && b && a.id === b.id;
      });
  }

  protected filterBanks() {
    if (!this.banks) {
      return;
    }
    // get the search keyword
    let search = this.bankFilterCtrl.value;
    if (!search || search.length < 3) {
      //this.filteredBanks.next(this.banks.slice());
      this.filteredBanks.next([]);
      return;
    } else {
      search = search.toLowerCase();
    }
    // filter the banks
    this.filteredBanks.next(
      this.banks.filter(bank => bank.name.toLowerCase().indexOf(search) > -1)
    );
  }

}
export interface Bank {
  id: string;
  name: string;
}

export interface BankGroup {
  name: string;
  banks: Bank[];
}


/** list of banks */
export const BANKS: Bank[] = [
  { name: 'Bank A (Switzerland)', id: 'A' },
  { name: 'Bank B (Switzerland)', id: 'B' },
  { name: 'Bank C (France)', id: 'C' },
  { name: 'Bank D (France)', id: 'D' },
  { name: 'Bank E (France)', id: 'E' },
  { name: 'Bank F (Italy)', id: 'F' },
  { name: 'Bank G (Italy)', id: 'G' },
  { name: 'Bank H (Italy)', id: 'H' },
  { name: 'Bank I (Italy)', id: 'I' },
  { name: 'Bank J (Italy)', id: 'J' },
  { name: 'Bank Kolombia (United States of America)', id: 'K' },
  { name: 'Bank L (Germany)', id: 'L' },
  { name: 'Bank M (Germany)', id: 'M' },
  { name: 'Bank N (Germany)', id: 'N' },
  { name: 'Bank O (Germany)', id: 'O' },
  { name: 'Bank P (Germany)', id: 'P' },
  { name: 'Bank Q (Germany)', id: 'Q' },
  { name: 'Bank R (Germany)', id: 'R' }
];