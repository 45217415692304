import { MibeModel } from './mibemodel.model';

export class SiteConfiguration implements MibeModel {
  terms_of_service_slug: string;
  privacy_policy_slug: string;
  pay_per_track_price: number;
  pay_per_track_product: number;

  /**
   * Create a TrackType.
   * @param {any} json - The JSON object with which to construct our TrackType.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackType.
   * @param {any} json - The JSON object with which to load our TrackType.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.terms_of_service_slug = json.terms_of_service_slug;
    this.privacy_policy_slug = json.privacy_policy_slug;
    this.pay_per_track_price = json.pay_per_track_price;
    this.pay_per_track_product = json.pay_per_track_product;
  }
  /**
   * Returns a JSON representation of our TrackType
   * @return {any} - The JSON representation of our TrackType
   */
  toJSON() {
    var json: any = {
      'terms_of_service_slug': this.terms_of_service_slug,
      'privacy_policy_slug': this.privacy_policy_slug,
      'pay_per_track_price': this.pay_per_track_price,
      'pay_per_track_product': this.pay_per_track_product
    };
    return json;
  }
}
