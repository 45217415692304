import { Component, OnInit } from '@angular/core';

import { forkJoin } from 'rxjs';

import { MatDialogRef } from '@angular/material/dialog';
import { Subscription } from '../models/subscription.model';
import { Product } from '../models/product.model';
import { SubscriptionService } from '../services/subscription.service';
import { ProductService } from '../services/product.service';

@Component({
  selector: 'app-change-subscription',
  templateUrl: './change-subscription.component.html',
  styleUrls: ['./change-subscription.component.scss']
})
export class ChangeSubscriptionComponent implements OnInit {

  loading = true;
  subscription: Subscription;
  products: Product[];

  complete = false;
  completionMessage: String;

  get productList() {
    return this.products.filter(p=>{
      return p.id != this.subscription.product.id;
    });
  }

  constructor(
    public dialogRef: MatDialogRef<ChangeSubscriptionComponent>,
    private _subscriptionService: SubscriptionService,
    private _productService: ProductService
  ) { }

  ngOnInit() {
    forkJoin(
      this._subscriptionService.getCurrentSubscription(),
      this._productService.getAllProducts()
    ).subscribe(results => {
      this.loading = false;
      this.subscription = results[0];
      this.products = results[1];
    });
  }

  close() {
    this.dialogRef.close(this.subscription);
  }

  productTrackBy(_:number, p:Product) {
    return p.id;
  }

  is_upgrade(product:Product) {
    if (!(this.subscription && this.subscription.product) || !product) return true;
    if (
      (this.subscription.product.downloads < product.downloads && this.subscription.product.downloads != -1 && product.downloads != -1) ||
      (this.subscription.product.downloads != -1 && product.downloads == -1)
    ) {
      return true;
    }
    if ((this.subscription.product.downloads == -1 && product.downloads == -1)) return this.subscription.product.price <= product.price;
    return false;
  }

  changeSubscription(product:Product) {
    this.loading = true;
    this._subscriptionService.updateSubscription(product.id).subscribe(
      s => {
        this.subscription = s;
        this.loading = false;
        this.close();
      }, (results) => {
        this.loading = false;
        this.complete = true;
        // console.log(results);
        this.completionMessage = "An Error Occurred While Changing Your Subscription. Please Try Again Later.";
      }
    );
  }

  requestAccess() {
    //TODO: SOMETHING
    this.complete = true;
    this.completionMessage = "Your request has been recived. Someone from our team will be in touch soon!"
  }

  cancelSubscription() {
    this.loading = true;
    this._subscriptionService.removeSubscription().subscribe(
      s=>{
        this.subscription = s;
        this.loading = false;
        this.close();
      }, (results) => {
        this.loading = false;
        this.complete = true;
        // console.log(results);
        this.completionMessage = "An Error Occurred While Canceling Your Subscription. Please Try Again Later.";
      }
    );
  }

}
