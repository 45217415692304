import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';


@Injectable({
  providedIn: 'root'
})
export class CookieService {

  constructor(
    private _platform: Platform,
    @Inject( DOCUMENT ) private _document: Document
  ) {
  }

  /**
   * @param name     Cookie name
   * @param value    Cookie value
   * @param expires  Number of days until the cookies expires or an actual `Date`
   * @param path     Cookie path
   * @param domain   Cookie domain
   * @param secure   Secure flag
   * @param sameSite OWASP samesite token `Lax` or `Strict`
   */
  set(
    name: string,
    value: string,
    expires?: number | Date,
    path?: string,
    domain?: string,
    secure?: boolean,
    sameSite?: 'Lax' | 'Strict'
  ): void {
    if (!this._platform.isBrowser) { return; }

    let cookieString: string = encodeURIComponent( name ) + '=' + encodeURIComponent( value ) + ';';

    if ( expires ) {
      if ( typeof expires === 'number' ) {
        const dateExpires: Date = new Date( new Date().getTime() + expires * 1000 * 60 * 60 * 24 );

        cookieString += 'expires=' + dateExpires.toUTCString() + ';';
      } else {
        cookieString += 'expires=' + expires.toUTCString() + ';';
      }
    }

    if ( path ) {
      cookieString += 'path=' + path + ';';
    }

    if ( domain ) {
      cookieString += 'domain=' + domain + ';';
    }

    if ( secure ) {
      cookieString += 'secure;';
    }

    if ( sameSite ) {
      cookieString += 'sameSite=' + sameSite + ';';
    }

    this._document.cookie = cookieString;
  }

  /**
   * @param name   Cookie name
   * @param path   Cookie path
   * @param domain Cookie domain
   */
  delete( name: string, path?: string, domain?: string ): void {
    if (!this._platform.isBrowser) { return; }

    this.set( name, '', new Date('Thu, 01 Jan 1970 00:00:01 GMT'), path, domain );
  }

  /**
   * @param name Cookie name
   * @returns {any}
   */
  get(name: string): string {
    if (!this._platform.isBrowser) { return ''; }

    name = encodeURIComponent( name );

    const regExp: RegExp = this.getCookieRegExp( name );
    const result: RegExpExecArray = regExp.exec( this._document.cookie );
    if (!result) { return ''; }
    return decodeURIComponent( result[ 1 ] );
  }

  /**
   * @param name Cookie name
   * @returns {RegExp}
   */
  private getCookieRegExp( name: string ): RegExp {
    const escapedName: string = name.replace( /([\[\]\{\}\(\)\|\=\;\+\?\,\.\*\^\$])/ig, '\\$1' );

    return new RegExp( '(?:^' + escapedName + '|;\\s*' + escapedName + ')=(.*?)(?:;|$)', 'g' );
  }
}
