import { MibeModel } from './mibemodel.model';
import { Product } from './product.model';

export class Subscription implements MibeModel {
  id: number;
  product: Product;
  current_period_end: Date;
  current_period_start: Date;
  cancel_at_period_end: boolean;
  canceled_at: Date;
  current_period_downloads_count: number;
  downloads_count: number;
  available_downloads: number;

  /**
   * Create a Waveform.
   * @param {any} json - The JSON object with which to construct our Waveform.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Waveform.
   * @param {any} json - The JSON object with which to load our Waveform.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    if (json.product) {
      this.product = new Product(json.product);
    }
    if (json.current_period_end) {
      this.current_period_end = new Date(json.current_period_end);
    }
    if (json.current_period_start) {
      this.current_period_start = new Date(json.current_period_start);
    }
    this.cancel_at_period_end = !!json.cancel_at_period_end;
    if (json.canceled_at) {
      this.canceled_at = new Date(json.canceled_at);
    }
    this.current_period_downloads_count = json.current_period_downloads_count;
    this.downloads_count = json.downloads_count;
    this.available_downloads = json.available_downloads;
  }
  /**
   * Returns a JSON representation of our Waveform
   * @return {any} - The JSON representation of our Waveform
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'cancel_at_period_end': !!this.cancel_at_period_end,
      'available_downloads': this.available_downloads,
      'current_period_downloads_count': this.current_period_downloads_count,
      'downloads_count': this.downloads_count
    };
    if (this.current_period_end) {
      json['current_period_end'] = this.current_period_end.toJSON();
    }
    if (this.current_period_start) {
      json['current_period_start'] = this.current_period_start.toJSON();
    }
    if (this.canceled_at) {
      json['canceled_at'] = this.canceled_at.toJSON();
    }
    if (this.product) {
      json['product'] = this.product.toJSON();
    }
    return json;
  }
}
