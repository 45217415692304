import { MibeModel } from './mibemodel.model';
import { Show } from './show.model';
import { AnimationQueryMetadata } from '@angular/animations';

export class User implements MibeModel {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  nick_name: string;
  company_name: string;
  shows: Show[] = [];
  address1: string;
  address2: string;
  city: string;
  state: string;
  postal_code: string;
  phone_number: string;
  fax: string;
  birth_date: Date;
  cae_ipi: string;
  ssn: string;
  pro: number;
  unread: number;
  is_client: boolean;
  ms_username:string;
  can_download:boolean;
  _isScalar:any;
  
  // additional_numbers?:boolean;
  // allowed_cowriters?:any;
  // artist_royalities?:any;
  // can_download?:boolean;
  // can_upload?:boolean;
  // country?:any;
  // cowriter_quanity_limit?:any;
  // date_joined?:any;
  // discount_rate?:number;
  // ein?:any;
  // groups?:any;
  // is_active?:boolean;
  // is_artist?:boolean;
  // is_cms_enabled?:boolean;
  // is_disable?:boolean
  // is_staff?:boolean;
  // is_superuser?:boolean
  // last_login?:any;
  // project_name?:any;
  // publisher_percentage?:any;
  // social_media_links?:any;
  // tos_status?:any;
  // unlimited_cowriters?:boolean;
  // user_permissions?:any;
  // visible_genres?:any;
  /**
   * Create a User.
   * @param {any} json - The JSON object with which to construct our User.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a User.
   * @param {any} json - The JSON object with which to load our User.
   */
  loadWithJSON(json: any) {
    
    if (!json) {
      return;
    }
    this.id = json.id;
    this.email = json.email;
    this.first_name = json.first_name;
    this.last_name = json.last_name;
    this.nick_name = json.nick_name;
    this.company_name = json.company_name;
    if (json.shows) {
      this.shows = json.shows.map(s => new Show(s));
    }
    this.address1 = json.address1;
    this.address2 = json.address2;
    this.city = json.city;
    this.state = json.state;
    this.postal_code = json.postal_code;
    this.phone_number = json.phone_number;
    this.fax = json.fax;
    
      this.birth_date = new Date(json.birth_date);
  
    this.cae_ipi = json.cae_ipi;
    this.ssn = json.ssn;
    this.pro = json.pro;
    this.unread = json.unread;
    this.ms_username = json.ms_username
    this.can_download = json.can_download
   
    // this.additional_numbers = json.additional_numbers;
    // this.allowed_cowriters =json.json.additional_numbers;
    // this.artist_royalities =json.artist_royalities;
    // this.can_download=json.can_download;
    // this.can_upload= json.can_upload;
    // this.country =json.country;
    // this.cowriter_quanity_limit = json.cowriter_quanity_limit;
    // this.date_joined= json.date_joined;
    // this.discount_rate =json.discount_rate;
    // this.ein =json.ein;
    // this.groups =json.groups;
    // this.is_active = json.is_active;
    // this.is_artist = json.is_artist;
    // this.is_cms_enabled =json.is_cms_enabled;
    // this.is_disable =json.is_disable
    // this.is_staff = json.is_staff;
    // this.is_superuser = json.is_superuser
    // this.last_login = json.last_login;
    // this.project_name = json.project_name;
    // this.publisher_percentage = json.publisher_percentage;
    // this.social_media_links = json.social_media_links;
    // this.tos_status = json.tos_status;
    // this.unlimited_cowriters = json.unlimited_cowriters;
    // this.user_permissions = json.user_permissions;
    // this.visible_genres = json.visible_genres;
  }
  /**
   * Returns a JSON representation of our User
   * @return {any} - The JSON representation of our User
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'email': this.email,
      'first_name': this.first_name,
      'last_name': this.last_name,
      'nick_name': this.nick_name,
      'company_name': this.company_name,
      'shows': this.shows.map(s => s.toJSON()),
      'address1': this.address1,
      'address2': this.address2,
      'city': this.city,
      'state': this.state,
      'postal_code': this.postal_code,
      'phone_number': this.phone_number,
      'fax': this.fax,
      'cae_ipi': this.cae_ipi,
      'ssn': this.ssn,
      'pro': this.pro,
      'unread': this.unread,
      'ms_username':this.ms_username,
      'can_download': this.can_download
      

  //  "additional_numbers":this.additional_numbers,
  // "allowed_cowriters":this.allowed_cowriters,
  // "artist_royalities":this.artist_royalities,
  // "can_download":this.can_download,
  // "can_upload":this.can_upload,
  // "country":this.country,
  // "cowriter_quanity_limit":this.cowriter_quanity_limit,
  // "date_joined":this.date_joined,
  // "discount_rate":this.discount_rate,
  // "ein":this.ein,
  // "groups":this.groups,
  // "is_active":this.is_active,
  // "is_artist":this.is_artist,
  // "is_cms_enabled":this.is_cms_enabled,
  // "is_disable":this.is_disable,
  // "is_staff":this.is_staff,
  // "is_superuser":this.is_superuser,
  // "last_login":this.last_login,
  // "project_name":this.project_name,
  // "publisher_percentage":this.publisher_percentage,
  // "social_media_links":this.social_media_links,
  // "tos_status":this.tos_status,
  // "unlimited_cowriters":this.unlimited_cowriters,
  // "user_permissions":this.user_permissions,
  // "visible_genres":this.visible_genres
    };
   
      json['birth_date'] = this.birth_date ? this.birth_date.toJSON(): null;
  
    return json;
  }
}
