import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { ActivityService } from './activity.service';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { UserService } from './user.service';

export class PlayingItem {
  constructor(public track: Track, public version: TrackVersion) {}
}

@Injectable({
  providedIn: 'root'
})
export class PlayerService {
  currentPlayingObject:any;
  currentlyPlaying: Observable<PlayingItem>;
  private _currentlyPlayingSubject: BehaviorSubject<PlayingItem> = new BehaviorSubject<PlayingItem>(null);
  isPlaying: Observable<boolean>;
  public _isPlayingSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  playbackProgress: Observable<number>;
  private _playbackProgressSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  playbackContextList: Observable<PlayingItem[]>;
  private _playbackContextListSubject: BehaviorSubject<PlayingItem[]> = new BehaviorSubject<PlayingItem[]>([]);
playfromTrackSubject= new BehaviorSubject<boolean>(false);
playtrack = this.playfromTrackSubject.asObservable();
  clearPlaying: Observable<any>;
  public clearPlayingSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  pausebuttonPressed : BehaviorSubject<any> = new BehaviorSubject<any>(false);
  currentPause:any;
  playTrack: Observable<any>;
  public playTrackSubject: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  get nextItem(): PlayingItem {
    return this._nextItem;
  }
  private _nextItem: PlayingItem;
  get previousItem(): PlayingItem {
    return this._previousItem;
  }
  private _previousItem: PlayingItem;
  private _subscriptions: Subscription[] = [];
  currentUser:any;
  constructor(
    private http: HttpClient,
    private _activityService: ActivityService,
    private _userService: UserService
  ) {
    this.currentlyPlaying = this._currentlyPlayingSubject.asObservable();
    this.isPlaying = this._isPlayingSubject.asObservable();
    this.playbackProgress = this._playbackProgressSubject.asObservable();
    this.playbackContextList = this._playbackContextListSubject.asObservable();
    this.clearPlaying = this.clearPlayingSubject.asObservable();
    this.playTrack = this.playTrackSubject.asObservable();
    this.currentPause = this.pausebuttonPressed.asObservable()
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
   
  }

  previous() {
    this.clearPlayingSubject.next(true)
    if (!this._previousItem) { return; }
    let currentlyPlaying = this._isPlayingSubject.getValue();
    if (currentlyPlaying) {
      this.play(this._previousItem.track, this._previousItem.version);
    } else {
      this.updateCurrentlyPlaying(this._previousItem.track, this._previousItem.version);
    }
  }

  play(track: Track, version: TrackVersion) {

    this.updateIsPlaying(false);
    
    if (this.updateCurrentlyPlaying(track, version)) {
      this.updateIsPlaying(true);
    }

    this.updateNextAndPrev();
  }

  updateCurrentlyPlaying(track: Track, version: TrackVersion): boolean {
    if (!track || !version) {
      this._currentlyPlayingSubject.next(null);
      this._isPlayingSubject.next(false);
      this._playbackProgressSubject.next(0);
      return false;
    }
    if(this.currentUser){
      this._activityService.recordActivity('played_track', track.id).subscribe();
    }
    
    let currentlyPlaying = new PlayingItem(track, version);
    this._currentlyPlayingSubject.next(currentlyPlaying);
    this._playbackProgressSubject.next(0);
    return true;
  }

  next() {
    this.clearPlayingSubject.next(true)
    if (!this._nextItem) { return; }
    let currentlyPlaying = this._isPlayingSubject.getValue();
    if (currentlyPlaying) {
      this.play(this._nextItem.track, this._nextItem.version);
    } else {
      this.updateCurrentlyPlaying(this._nextItem.track, this._nextItem.version);
    }
  }

  updateIsPlaying(playing: boolean) {
  
    this._isPlayingSubject.next(playing);
  }

  updatePlaybackProgress(progress: number) {
    this._playbackProgressSubject.next(progress);
  }

  updatePlaybackContextList(list: PlayingItem[]) {
    this._playbackContextListSubject.next(list);
    this.updateNextAndPrev();
  }

  private updateNextAndPrev() {
    let list = this._playbackContextListSubject.getValue();
    let currentlyPlaying = this._currentlyPlayingSubject.getValue();
    let prev: PlayingItem = null;
    let next: PlayingItem = null;
    if (!currentlyPlaying) {
      this._nextItem = next;
      this._previousItem = prev;
      return;
    }
    let found = false;
    for (let i = 0, tot = list.length; i < tot; i++) {
      let item = list[i];
      if (item.track.id == currentlyPlaying.track.id && item.version.id == currentlyPlaying.version.id) {
        found = true;
        continue;
      }
      if (found) {
        next = item;
        break;
      }
      prev = item;
    }
    if (!found) {
      prev = null;
    }
    this._nextItem = next;
    this._previousItem = prev;
  }
}
