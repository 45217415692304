import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, UrlTree } from '@angular/router';
import { Observable, AsyncSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { ArtistService } from '../services/artist.service';

@Injectable({
  providedIn: 'root'
})
export class NotArtistGuard implements CanActivate {
  constructor(
    private _userService: UserService,
    private _artistService: ArtistService,
    private _router: Router
  ) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const canActivateSubject = new AsyncSubject<boolean | UrlTree>();
    this._userService.currentUserStream.pipe(first()).subscribe(u => {
      if (!u) {
        canActivateSubject.next(true);
        canActivateSubject.complete();
        return;
      }
      this._artistService.currentUserArtistsStream.pipe(first()).subscribe(a => {
        if (!a || !a.length) {
          canActivateSubject.next(true);
          canActivateSubject.complete();
          return;
        }
        canActivateSubject.next(this._router.createUrlTree(['/account']));
        canActivateSubject.complete();
      })
    });
    return canActivateSubject.asObservable();

  }

}
