import { MibeModel } from './mibemodel.model';

export class TrackComposer implements MibeModel {
  composer_id: number;
  composer_name: string;
  composer_pro: string;
  composer_percentage: number;
  publisher_name: string;
  publisher_pro: string;
  publisher_percentage: number;

  /**
   * Create a TrackType.
   * @param {any} json - The JSON object with which to construct our TrackType.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackType.
   * @param {any} json - The JSON object with which to load our TrackType.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.composer_id = json.composer_id;
    this.composer_name = json.composer_name;
    this.composer_pro = json.composer_pro;
    this.composer_percentage = json.composer_percentage;
    this.publisher_name = json.publisher_name;
    this.publisher_pro = json.publisher_pro;
    this.publisher_percentage = json.publisher_percentage;
  }
  /**
   * Returns a JSON representation of our TrackType
   * @return {any} - The JSON representation of our TrackType
   */
  toJSON() {
    var json: any = {
      'composer_id': this.composer_id,
      'composer_name': this.composer_name,
      'composer_pro': this.composer_pro,
      'composer_percentage': this.composer_percentage,
      'publisher_name': this.publisher_name,
      'publisher_pro': this.publisher_pro,
      'publisher_percentage': this.publisher_percentage
    };
    return json;
  }
}
