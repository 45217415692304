import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  MatAutocomplete,
  MatAutocompleteSelectedEvent
} from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Show } from '../models/show.model';
import { ShowService } from '../services/show.service';
import { ConfirmPasswordStateMatcher } from '../../account/account-profile/account-profile.component';
import { SiteConfigurationService } from '../services/siteconfiguration.service';
import { SiteConfiguration } from '../models/siteconfiguration.model';

import { MatDialogRef } from '@angular/material/dialog';

import { usStates } from '../../shared/models/states.model';

import { Subscription, ReplaySubject, of } from 'rxjs';
import {
  startWith,
  debounceTime,
  distinctUntilChanged,
  tap,
  switchMap,
  map
} from 'rxjs/operators';

import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {
  maxDate = new Date();
  @Output()
  openLogin = new EventEmitter();

  selectedAccountType = false;

  get isArtist(): boolean {
    return this._isArtist;
  }
  set isArtist(a: boolean) {
    this._isArtist = a;
    if (a) {
      this.dialogRef.updateSize('900px');
    } else {
      this.dialogRef.updateSize('465px');
    }
  }
  private _isArtist = false;

  @ViewChild('autoShows', {static: false}) matAutocompleteShow: MatAutocomplete;
  @ViewChild('showInput', {static: false}) showInput: ElementRef<HTMLInputElement>;
  showCtrl = new FormControl();
  searchingShows = false;
  showsChanged = false;
  siteConfig: SiteConfiguration;
  filteredShowOptions: ReplaySubject<Show[]> = new ReplaySubject<Show[]>(1);

  separatorKeysCodes: number[] = [ENTER, COMMA];

  usStates = usStates;

  loading = false;
  passwordInputType = 'password';
  confirmPasswordInputType = 'password';
  confirmPasswordMatcher: ConfirmPasswordStateMatcher;
  registerModel = new User(null);
  registerExtras = {
    password: '',
    confirmPassword: '',
    newsletterSignup: false,
    notes: '',
    portfolio_link: ''
  };
  errors: string[] = [];
  private _subscriptions: Subscription[] = [];

  constructor(
    private _userService: UserService,
    private _siteConfigService: SiteConfigurationService,
    private _showService: ShowService,
    public dialogRef: MatDialogRef<RegisterComponent>
  ) {
    this.confirmPasswordMatcher = new ConfirmPasswordStateMatcher(this.registerExtras);
  }

  ngOnInit() {
    this.isArtist = this._isArtist;
    this._siteConfigService.getSiteConfiguration().subscribe(c => {
      this.siteConfig = c;
    });
    this._subscriptions.push(this.showCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingShows = true;
      }),
      switchMap((v) => {
        return this._showService.getAllShows(v).pipe(
          map(s => {
            s = s.filter(
              show => this.registerModel.shows.findIndex(
                innerShow => innerShow.name.toLowerCase() == show.name.toLowerCase()
              ) == -1
            );
            return s;
          })
        );
      }),
      tap(() => {
        this.searchingShows = false;
      })
    ).subscribe(a => {
      this.filteredShowOptions.next(a);
    }));
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  togglePasswordInputType() {
    if (this.passwordInputType == 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }

  toggleConfirmPasswordInputType() {
    if (this.confirmPasswordInputType == 'password') {
      this.confirmPasswordInputType = 'text';
    } else {
      this.confirmPasswordInputType = 'password';
    }
  }

  register() {
    
    if (this.registerExtras.password != this.registerExtras.confirmPassword) { return; }
    this.errors = [];
    this.loading = true;
    this._userService.registerUser(
      this.registerModel,
      this.registerExtras.password,
      this.registerExtras.newsletterSignup,
      this.registerExtras.notes,
      this.registerExtras.portfolio_link
    ).subscribe((currentUser) => {
      this.loading = false;
      this.dialogRef.close({
        registered: true,
        user: currentUser,
        isArtist: this.isArtist
      });
    }, (err) => {
      this.loading = false;
      this.errors.push('Error registering, please try again later, if the error persists please contact us.');
      console.error(err);
    });
  }

  openLoginForm() {
    this.openLogin.emit();
  }

  addShow(event: MatChipInputEvent) {
    if (!this.matAutocompleteShow.isOpen) {
      const value = event.value;

      // Add our show
      if ((value || '').trim()) {
        let show = new Show({name: value.trim()});
        this.registerModel.shows.push(show);
        this.showCtrl.patchValue(null);
        this.showsChanged = true;
        event.input.value = '';
      }
    }
  }

  removeShow(i:number) {
    this.registerModel.shows.splice(i, 1);
    this.showsChanged = true;
  }

  selectedShow(event: MatAutocompleteSelectedEvent) {
    let show = new Show({name: event.option.viewValue.trim()});
    this.registerModel.shows.push(show);
    this.showsChanged = true;
    setTimeout(() => {
      this.showCtrl.patchValue(null);
      this.showInput.nativeElement.value = '';
    });
  }

  showTrackBy(_: number, show: Show) {
    return show.name;
  }

}
