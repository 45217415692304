import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
export interface DialogData {
  message: string;
  name: string;
  logout:boolean
}
@Component({
  selector: 'app-download-alert',
  templateUrl: './download-alert.component.html',
  styleUrls: ['./download-alert.component.scss']
})
export class DownloadAlertComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<DownloadAlertComponent>,
    public userservice: UserService,
    public router: Router,
    @Inject(MAT_DIALOG_DATA)public data: DialogData) { }

  ngOnInit() {
  }
  close() {
   
    if(this.data.logout === true){
     if(this.userservice.currentUserStream){
      this.userservice._currentUserSubject.next(null);
      localStorage.clear()
      this.router.navigateByUrl('/')
      window.location.reload()
     }
     

    }
    this.dialogRef.close(null);
    
  }
}
