import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';
import { of } from 'rxjs';

interface TokenResponse {
  access: string;
  refresh: string;
}

const LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME = "MIBE_ACCESS_TOKEN"
const LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME = "MIBE_REFRESH_TOKEN"
const LOCALSTORAGE_REMEMBER_ME_KEY_NAME = "MIBE_REMEMBER_ME_TOKEN"

const jwtHelper = new JwtHelperService();

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  get hasActiveTokens(): boolean {
    return (this.currentAccessToken && !jwtHelper.isTokenExpired(this.currentAccessToken)) ||
      this.currentRefreshToken && !jwtHelper.isTokenExpired(this.currentRefreshToken);
  }

  get currentAccessToken(): string {
    if (this._currentAccessToken) {
      return this._currentAccessToken;
    }
    if (!this._platform.isBrowser) {
      return null;
    }
    let storageValue = localStorage.getItem(LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME) ||
      localStorage.getItem(LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME);
    this._currentAccessToken = storageValue;
    return storageValue;
  }

  get currentRefreshToken(): string {
    if (this._currentRefreshToken) {
      return this._currentRefreshToken;
    }
    if (!this._platform.isBrowser) {
      return null;
    }
    let storageValue = localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME) ||
      localStorage.getItem(LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME);
    this._currentRefreshToken = storageValue;
    return storageValue;
  }

  get currentRememberMe(): boolean {
    if (this._currentRememberMe) {
      return this._currentRememberMe;
    }
    if (!this._platform.isBrowser) {
      return false;
    }
    let storageValue = localStorage.getItem(LOCALSTORAGE_REMEMBER_ME_KEY_NAME) || '0';
    this._currentRememberMe = storageValue == '1';
    return this._currentRememberMe;
  }

  private _currentAccessToken: string;
  private _currentRefreshToken: string;
  private _currentRememberMe: boolean;

  constructor(
    private http: HttpClient,
    private _platform: Platform
  ) { }

  getCurrentAccessToken(): Observable<string> {
    if (this.currentAccessToken && !jwtHelper.isTokenExpired(this.currentAccessToken)) {
      return of(this.currentAccessToken);
    }
    if (this.currentRefreshToken && !jwtHelper.isTokenExpired(this.currentRefreshToken)) {
      return this.refreshAccessToken(this.currentRefreshToken);
    }
    return of(null);
  }

  setAccessToken(token: string, rememberMe: boolean) {
    this._currentAccessToken = token;
    if (!this._platform.isBrowser) {
      return;
    }
    try {
      localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME);
    } catch (e) {
      console.error(e);
    }
    try {
      localStorage.removeItem(LOCALSTORAGE_REMEMBER_ME_KEY_NAME);
    } catch (e) {
      console.error(e);
    }
    try {
      localStorage.removeItem(LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME);
    } catch (e) {
      console.error(e);
    }
    if (token) {
      if (rememberMe) {
        try {
          localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME, token);
        } catch (e) {
          console.error(e);
        }
        try {
          localStorage.setItem(LOCALSTORAGE_REMEMBER_ME_KEY_NAME, rememberMe ? '1' : '0');
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          localStorage.setItem(LOCALSTORAGE_ACCESS_TOKEN_KEY_NAME, token);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  setRefreshToken(token: string, rememberMe: boolean) {
    this._currentRefreshToken = token;
    if (!this._platform.isBrowser) {
      return;
    }
    try {
      localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME);
    } catch (e) {
      console.error(e);
    }
    try {
      localStorage.removeItem(LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME);
    } catch (e) {
      console.error(e);
    }
    if (token) {
      if (rememberMe) {
        try {
          localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME, token);
        } catch (e) {
          console.error(e);
        }
      } else {
        try {
          localStorage.setItem(LOCALSTORAGE_REFRESH_TOKEN_KEY_NAME, token);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }

  getNewToken(email: string, password: string, rememberMe: boolean): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<TokenResponse>(
      environment.apiURL + '/api/v1/token/',
      {
        email: email,
        password: password
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this.setAccessToken(res.access, rememberMe);
      if (res.refresh) {
        this.setRefreshToken(res.refresh, rememberMe);
      }
      return res.access;
    }));
  }

  refreshAccessToken(refreshToken: string): Observable<string> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<TokenResponse>(
      environment.apiURL + '/api/v1/token/refresh/',
      {
        refresh: refreshToken
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this.setAccessToken(res.access, this.currentRememberMe);
      if (res.refresh) {
        this.setRefreshToken(res.refresh, this.currentRememberMe);
      }
      return res.access;
    }),
    share());
  }
}
