import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  NgZone,
  ViewChild,
  ElementRef
} from '@angular/core';
import {
  trigger,
  style,
  query,
  animate,
  stagger,
  transition,
  group
} from '@angular/animations';
import { FormControl } from '@angular/forms';
import { Platform } from '@angular/cdk/platform';
import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, Observable, ReplaySubject, BehaviorSubject, fromEvent } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap, filter, tap } from 'rxjs/operators';
import { Track } from '../models/track.model';
import { Playlist } from '../models/playlist.model';
import { PlaylistService } from '../services/playlist.service';
import { DialogService } from '../services/dialog.service';
import {
  TrackService,
  Filters,
  MinMax,
  GetTracksOptions,
  filtersToParams,
  areFiltersEqual
} from '../services/track.service';
import { TracklistRow } from '../tracklist/tracklist.component';
import { UserService } from '../services/user.service';
import { MatDialogRef } from '@angular/material';
import { environment } from '../../../environments/environment';
import { PageFiltersComponent } from '../pagefilters/pagefilters.component';

const tracksPageSize = 25;

const HEADER_HEIGHT = 84;

var nextId = 0;

@Component({
  selector: 'app-tracks-page-list',
  templateUrl: './tracks-page-list.component.html',
  styleUrls: ['./tracks-page-list.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({
          opacity: '0'
        }),
        animate('0.3s ease-in-out', style({
          opacity: '1'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1'
        }),
        animate('0.3s ease-in-out', style({
          opacity: '0'
        }))
      ])
    ]),
    trigger('playlistsInOut', [
      transition('* => *', [
        query(':enter', [
          query('.playlist', [
            style({
              width: '0',
              padding: '12px 0'
            }),
            animate('0.3s ease-in-out')
          ], { optional: true })
        ], { optional: true }),
        query(':leave', [
          query('.playlist', [
            style('*'),
            animate('0.3s ease-in-out', style({
              width: '0',
              padding: '12px 0'
            }))
          ], { optional: true })
        ], { optional: true })
      ])
    ])
  ]
})
export class TracksPageListComponent implements OnInit, OnDestroy {

  loadingTracks = false;
  stickyFilters = false;

  loadingPlaylists = false;
  playlistFilterCtrl = new FormControl();
  editablePlaylists: ReplaySubject<Playlist[]> = new ReplaySubject<Playlist[]>(1);

  savingPlaylists = { 0: false };
  savedPlaylists = { 0: false };
  userDetails:any;

  trackListDropListId = `track-page-list-drop-list-${nextId++}`;

  get dropListIds(): string[] {
    return this._currentEditablePlaylists.map(p => { return this.domIDForPlaylist(p); });
  }

  @ViewChild('filtersContainer', { static: true })
  filtersEl: ElementRef;

  @ViewChild('playlistsOuterContainer', { static: true })
  playlistsEl: ElementRef;

  @ViewChild(PageFiltersComponent, { static: false }) pageFiltersComponent: PageFiltersComponent;
  @Input()
  set baseTrackOptions(o: GetTracksOptions) {
    this._baseTrackOptions = o;
    this._currentTracksPage = 1;
    this.loadTracks();
    this._params['tempo']= this.filters.tempo
    this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
  }
  get baseTrackOptions(): GetTracksOptions {
    return this._baseTrackOptions;
  }

  totalTracks = 0;
  set currentTracksPage(p: number) {
    if (!isNaN(p) && p != this._currentTracksPage) {
      this._currentTracksPage = p;
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
  }
  get currentTracksPage(): number {
    return this._currentTracksPage;
  }
  get tracksNumberOfPages(): number {
    return Math.ceil(this.totalTracks / tracksPageSize);
  }
  tracks: Observable<ReadonlyArray<Track>>;
  get filters(): Filters {
    return this._filters;
  }
  set filters(f: Filters) {
  
    if (!areFiltersEqual(this._filters, f)) {
      this._filters = f;
      this._currentTracksPage = 1; // Reset page because results changed
      this.loadTracks();
      // console.log(this._params)
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
   
  }
  get ordering(): string {
    return this._ordering;
  }
  set ordering(o: string) {
    if (o != this._ordering) {
      this._ordering = o;
      this._currentTracksPage = 1; // Reset page because results changed
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
  }

  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
  }

  get genreId(): string {
    return this._genreId;
  }
  set genreId(g: string) {
    if (this._genreId != g) {
      this._genreId = g;
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
  }

  get moodId(): string {
    return this._moodId;
  }
  set moodId(m: string) {
    if (this._moodId != m) {
      this._moodId = m;
      this.loadTracks();
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: this._params });
    }
  }

  get showingPlaylists(): boolean {
    return this._showingPlaylists;
  }

  get currentEditablePlaylists(): Playlist[] {
    return this._currentEditablePlaylists;
  }

  private get _params(): { [param: string]: string | string[]; } {
    let params = filtersToParams(this.filters);
    // console.log(this.filters)
    if (this.ordering) {
      params['ordering'] = this.ordering;
    }
    if (this.currentTracksPage && this.currentTracksPage != 1) {
      params['page'] = `${this.currentTracksPage}`;
    }
    if (this.searchString) {
      params['search'] = this.searchString;
    }
    if (this._genreId) {
      params['genre'] = this._genreId;
    }
    if (this._moodId) {
      params['mood'] = this._moodId;
    }
    ;
    return params;
  }
  private _showingPlaylists = false;
  private _filters: Filters = {
    duration: null,
    tempo: null,
    vocals: null,
    artistId: null,
    composerId: null,
    bpm:null
  };
  private _currentEditablePlaylists: Playlist[] = [];
  private _ordering: string;
  private _currentTracksPage = 1;
  private _searchString = '';
  private _genreId: string;
  private _moodId: string;
  private _subscriptions: Subscription[] = [];
  private _getTracksSubscription: Subscription;
  public count = 0
  private _tracksSubject: BehaviorSubject<ReadonlyArray<Track>> = new BehaviorSubject<ReadonlyArray<Track>>([]);
  private _baseTrackOptions: GetTracksOptions;
  constructor(
    private _ngZone: NgZone,
    private _platform: Platform,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _trackService: TrackService,
    private _playlistService: PlaylistService,
    private _dialogService: DialogService,
    private _userService: UserService
  ) {
    this.tracks = this._tracksSubject.asObservable();
  }

  ngOnInit() {
    this._playlistService.currentPlaylistData.subscribe(x=>{
      this._subscriptions.push(this.playlistFilterCtrl.valueChanges.pipe(
        startWith(''),
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.loadingPlaylists = true;
        }),
        switchMap((v) => {
          return this._playlistService.getEditablePlaylists(null, { ordering: 'name', search: v }, 'track-client')
            .pipe(map(p => p));
        }),
        tap(() => {
          this.loadingPlaylists = false;
        })
      ).subscribe(p => {
        this.count = 0
        let value = p.results
        this.count = p.count
        let data = value.filter(x=> !x.is_public)
        this.editablePlaylists.next(data);
        this._currentEditablePlaylists = data;
      }));
    })
    this._subscriptions.push(this.playlistFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.loadingPlaylists = true;
      }),
      switchMap((v) => {
        return this._playlistService.getEditablePlaylists(null, { ordering: 'name', search: v }, 'track-client')
        .pipe(map(p => p));
      }),
      tap(() => {
        this.loadingPlaylists = false;
      })
    ).subscribe(p => {
      this.count = 0
      let value = p.results
      this.count = p.count
      let data = value.filter(x=> !x.is_public)
      this.editablePlaylists.next(data);
      this._currentEditablePlaylists = data;
    }));
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params => {
        
        // Load Filters from URL
        // let bpm_lte = params.get('bpm__lte');
        // let bpm_gte = params.get('bpm__gte');
        // let tempo: any = null;
       
        // if (bpm_lte !== null || bpm_gte !== null) {
        //   tempo = {};
        //   if (bpm_lte !== null) {
        //     tempo['max'] = parseFloat(bpm_lte);
        //   }
        //   if (bpm_gte !== null) {
        //     tempo['min'] = parseFloat(bpm_gte);
        //   }
        // }

        let duration_lte = params.get('duration__lte');
        let duration_gte = params.get('duration__gte');
        let duration: MinMax = null;
        if (duration_lte !== null || duration_gte !== null) {
          duration = {};
          if (duration_lte !== null) {
            duration['max'] = parseFloat(duration_lte);
          }
          if (duration_gte !== null) {
            duration['min'] = parseFloat(duration_gte);
          }
        }
        let is_instrumental = params.get('is_instrumental');
        let vocals: boolean = null;
        if (is_instrumental !== null) {
          vocals = is_instrumental !== 'true';
        }
        // let tempo = params.get('tempo');
        // let tempoId:any = null
        // if (tempo !== null) {
        //   tempoId = tempo;
        // }
        let artist = params.get('artist');
        let artistId: number = null;
        if (artist !== null) {
          artistId = parseInt(artist);
        }
        let composer = params.get('composer')
        let composerId: number = null
        if (composer !== null) {
          composerId = parseInt(composer);
        }
        let bpm = params.get('bpm');
        let tempo = params.get('tempo');
      //  console.log(bpm)
       
        let filters: Filters = {
          duration: duration,
          tempo: tempo,
          vocals: vocals,
          artistId: artistId,
          composerId: composerId,
          bpm: bpm

        };
        this.filters = filters;

        let ordering = params.get('ordering');
        this.ordering = ordering;

        let page = params.get('page');
        if (page !== null) {
          this.currentTracksPage = parseInt(page);
        } else {
          this.currentTracksPage = 1;
        }

        let searchString = params.get('search');
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }

        let genreId = params.get('genre');
        if (genreId !== null) {
          this.genreId = genreId;
        } else {
          this.genreId = null;
        }

        let moodId = params.get('mood');
        if (moodId !== null) {
          this.moodId = moodId;
        } else {
          this.moodId = null;
        }
      })
    );
    // Handle sticky filters while scrolling page
    if (!this._platform.isBrowser || !this.filtersEl) { return; }
    this._ngZone.runOutsideAngular(() => {
      this._subscriptions.push(
        fromEvent(window.document, 'scroll').subscribe(_ => {
          const filtersYPosition = this.filtersEl.nativeElement.getBoundingClientRect().y;
          if (filtersYPosition > HEADER_HEIGHT && !this.stickyFilters) { return; }
          if (filtersYPosition <= HEADER_HEIGHT && this.stickyFilters) { return; }
          this._ngZone.run(() => {
            this.stickyFilters = filtersYPosition <= HEADER_HEIGHT
          });
        })
      );
    });
    this._userService.currentUserStream.subscribe(u => {
      // console.log(u)
      this.userDetails = u;
    })
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  loadTracks() {
    if (!this.baseTrackOptions) { return; }
    if (!this.filters) { return; }
    this.loadingTracks = true;
    if (this._getTracksSubscription && !this._getTracksSubscription.closed) {
      this._getTracksSubscription.unsubscribe();
    }

    let params = {
      filters: this.filters,
      ordering: this.ordering,
      searchString: this.searchString,
      limit: tracksPageSize,
      // offset: (this.currentTracksPage),
      page: (this.currentTracksPage)
    };
    if (this.genreId) {
      params['genreId'] = this.genreId;
    }
    if (this.moodId) {
      params['moodId'] = this.moodId;
    }
    
    this._getTracksSubscription = this._trackService.getTracks(
      Object.assign(Object.assign({}, this.baseTrackOptions), params)).subscribe(t => {
        // this.totalTracks = t.count;
        // Tracks count is modified from the results array of objects. Each of the objects having count as the key, this key will have the total count of the tracks.
        this.totalTracks = t.results.length>0 ? t.results[0].count: 0;
        // console.log(this.totalTracks)
        this._tracksSubject.next(t.results);
        this.loadingTracks = false;
      });
  }

  toggleShowingPlaylists() {
    if (this._showingPlaylists) {
      this._showingPlaylists = false;
      return;
    }
    this.playlistFilterCtrl.setValue('', { emitEvent: true });
    this._showingPlaylists = true;
  }

  playlistTrackBy(_: number, p: Playlist) {
    return p.id + '-' + p.name;
  }

  domIDForPlaylist(p: Playlist) {
    return `playlist-drop-${p.id}`;
  }

  onPlaylistDrop(p: Playlist, e: CdkDragDrop<TracklistRow>) {
    this.savingPlaylists[p.id] = true;
    this.savedPlaylists[p.id] = false;
    this._playlistService.addTrackVersionsToPlaylist(p.id, [e.item.data.version.id])
      .subscribe(playlist => {
        p.loadWithJSON(playlist.toJSON());
        this.savingPlaylists[p.id] = false;
        this.savedPlaylists[p.id] = true;
        setTimeout(() => {
          this.savedPlaylists[p.id] = false;
        }, 2000);
        this.ngOnInit();
      },
        error => {
          // console.log(error.error.Error[0])
          this.savingPlaylists[p.id] = false;
          this._dialogService.displaydisplayerror(error.error.Error[0],false)
        }
      );
  }

  addPlaylist() {
    this._dialogService.displayCreatePlaylist().subscribe(p => {
      if (p) {
        
        this._currentEditablePlaylists.push(p);
        this.editablePlaylists.next(this._currentEditablePlaylists);
        this.playlistFilterCtrl.setValue('', { emitEvent: true });
      }
    });
  }
  resetFilters() {
    this.pageFiltersComponent.resetFilters();
  }
}
