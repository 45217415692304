import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { ContactRequest } from '../models/contactrequest.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class ContactRequestService {

  constructor(
    private http: HttpClient
  ) {
  }

  createContactRequest(r: ContactRequest): Observable<ContactRequest> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/contactrequests/',
      r.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new ContactRequest(res)));
  }
}
