import { MibeModel } from './mibemodel.model';

export class Waveform implements MibeModel {
  data: number[][];

  /**
   * Create a Waveform.
   * @param {any} json - The JSON object with which to construct our Waveform.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Waveform.
   * @param {any} json - The JSON object with which to load our Waveform.
   */
  loadWithJSON(json: any) {
    // if (!json) {
    //   return;
    // }
    // console.log(json)
    this.data = json.RIFF;
  }
  /**
   * Returns a JSON representation of our Waveform
   * @return {any} - The JSON representation of our Waveform
   */
  toJSON() {
    var json: any = {
      'data': this.data
    };
    return json;
  }
}
