import { Component, OnInit, OnDestroy, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';

import { MatTooltip } from '@angular/material/tooltip';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { Subscription } from 'rxjs';

import { PlaylistService } from '../services/playlist.service';
import { Playlist } from '../models/playlist.model';
import { UserService } from '../services/user.service';
import { User } from '../models/user.model';

import { environment } from '../../../environments/environment';

export interface DialogData {
  playlist: Playlist;
}

@Component({
  selector: 'app-shareplaylist',
  templateUrl: './shareplaylist.component.html',
  styleUrls: ['./shareplaylist.component.scss'],
  animations: [
    trigger('emailFormInOut', [
      transition(':enter', [
        style({
          height: '0px',
          overflow: 'hidden',
          opacity: '0',
          paddingBottom: '0'
        }),
        animate('0.4s ease-in-out', style({
          opacity: '1',
          overflow: 'hidden',
          height: '*'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          overflow: 'hidden',
          height: '*'
        }),
        animate('0.4s ease-in-out', style({
          opacity: '0',
          height: '0px',
          paddingBottom: '0'
        }))
      ])
    ])
  ]
})
export class SharePlaylistComponent implements OnInit {

  @ViewChild('copiedTooltip', {static: false})
  copiedTooltip: MatTooltip;

  loading = false;
  sharingViaEmail = false;
  emailShareModel = {
    emails: '',
    message: ''
  };
  copiedMessage = '';
  errors: string[] = [];
  currentUser: User;

  get playlistLink() {
    return environment.protocol + '://' + environment.host +
      this._router.createUrlTree(['/playlist', this.data.playlist.id]).toString();
  }

  private _subscriptions: Subscription[] = [];

  constructor(
    private _userService: UserService,
    private _playlistService: PlaylistService,
    public dialogRef: MatDialogRef<SharePlaylistComponent>,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u => {
        if (!u) { this.dialogRef.close(); return; }
        this.currentUser = u;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  shareViaEmail() {
    this.errors = [];
    if (!this.emailShareModel.emails) {
      this.errors.push('Email is required!')
    }
    this.loading = true;
    this._playlistService.sharePlaylistViaEmail(
      this.data.playlist,
      this.emailShareModel.emails.split(',').map(e => e.trim()),
      this.emailShareModel.message
    ).subscribe((_) => {
      this.loading = false;
      this.dialogRef.close();
    }, (err) => {
      this.loading = false;
      this.errors.push('Error sharing track, please check the recipient\'s address');
      console.error(err);
    });
  }

  copiedLink() {
    this.copiedMessage = 'Copied!';
    setTimeout(() => {
      this.copiedTooltip.show();
    });
    setTimeout(() => {
      this.copiedMessage = '';
    }, 3000);
  }

  close() {
    this.dialogRef.close();
  }

}
