import { Component, OnInit, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { environment } from '../../environments/environment';
import { MessageService } from '../shared/services/message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Message } from '../shared/models/message.model';
import { APIListResponse } from '../shared/models/apiresponse.model';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';
import { UserService } from '../shared/services/user.service';

import {
  Subscription
 } from 'rxjs';
 import {
   trigger,
   style,
   animate,
   transition
 } from '@angular/animations';

const MESSAGES_PER_PAGE = 12;

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  animations: [
    trigger('messageInOut', [
      transition(':enter', [
        style({
          padding: '0',
          height: '0',
          opacity: '0'
        }),
        animate('0.7s ease-in-out', style({
          padding: '*',
          height: '*',
          opacity: '1'
        }))
      ]),
      transition(':leave', [
        style({
          padding: '*',
          height: '*',
          opacity: '1'
        }),
        animate('0.3s ease-in-out', style({
          padding: '0',
          height: '0',
          opacity: '0'
        }))
      ])
    ]),
  ]
})
export class MessagesComponent implements OnInit, OnDestroy {


  messages: APIListResponse<Message>;

  loading = true;

  action: string|string[] = null;
  created_at__gte: Date;
  created_at__lte: Date;

  offset = 0;
  limit = MESSAGES_PER_PAGE;

  get dateFormatOptions() {
    return {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
  }

  get days(): Object[] {
    if (!this.messages) return null;
    let days = [];
    let today = new Date();
    let yesterday = new Date(new Date().setDate(today.getDate() - 1));
    var curDay = today;
    var curObject = {
      title: "Today",
      messages: []
    };
    for (let message of this.messages.results) {
      if (this.is_day(message.created_at, curDay)){
        curObject.messages.push(message);
        continue;
      }
      if (curObject.messages.length > 0) days.push(Object.assign({}, curObject));
      curObject.messages = [message];
      curObject.title = this.is_day(message.created_at, yesterday) ? 'Yesterday' : message.created_at.toLocaleDateString('en-US', this.dateFormatOptions);
      curDay = new Date(message.created_at.toDateString());
    }
    if (curObject.messages.length > 0) days.push(Object.assign({}, curObject));
    return days;
  }

  private is_day(d1:Date, d2:Date) {
    return d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
  }

  get activeAction() {
    return this.actionState(this.action);
  }

  actionState(action: string | string[]) {
    if (!action) return 'all'
    switch(action) {
      case 'played_track':
        return 'songs_played';
      case 'searched':
        return 'searches';
      case 'downloaded':
        return 'downloads';
    }
    if (action.includes('playlist')) return 'playlist_activity';
    if (action != null) {
      if (action.length > 0) {
        if (action.includes('playlist_created') && action.includes('added_to_playlist')) return 'playlist_activity';
      }
    }
    return 'all';
  }

  private get _params(): {[param: string]: string | string[]} {
    let params = {}
    if (this.created_at__gte) params['created_at__gte'] = this.created_at__gte.toISOString();
    if (this.created_at__lte) params['created_at__lte'] = this.created_at__lte.toISOString();
    if (this.limit) params['limit'] = this.limit.toString();
    if (this.offset) params['offset'] = this.offset.toString();
    params['ordering'] = '-created_at';
    return params;
  }

  private _subscriptions: Subscription[] = [];
  private _getMessagesSubscription: Subscription;

  constructor(
    private _messageService: MessageService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _historyService: HistoryService,
    private _userService: UserService
  ) { }

  ngOnInit() {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params=> {
        let created_at__gte = params.get('created_at__gte');
        if (created_at__gte) this.created_at__gte = new Date(created_at__gte);
        else this.created_at__gte = null;
        let created_at__lte = params.get('created_at__lte');
        if (created_at__lte) this.created_at__lte = new Date(created_at__lte);
        else this.created_at__lte = null;
        this.loadMessages();

        this._historyService.addItemToHistory('Activity');
      })
    );

    const title = this._pageTitleService.createPageTitle(['Messages']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    const image = `${environment.protocol}://${environment.host}/assets/img/activity-background.jpg`;
    this._meta.addTags([
      {name: 'description', content: description},
      {name: 'twitter:card', content: 'summary'},
      {name: 'twitter:site', content: '@MIBE_music'},
      {name: 'twitter:title', content: 'MIBE Messages'},
      {name: 'twitter:description', content: description},
      {name: 'twitter:image', content: image},
      {name: 'og:type', content: 'website'},
      {name: 'og:title', content: 'Messages'},
      {name: 'og:url', content: `${environment.protocol}://${environment.host}/messages`},
      {name: 'og:image', content: image},
      {name: 'og:description', content: description}
    ]);

    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  loadMessages(loadMore=false) {
    this.loading = true;
    if (this._getMessagesSubscription && !this._getMessagesSubscription.closed) {
      this._getMessagesSubscription.unsubscribe();
    }
    if (!loadMore) {
      this.offset = 0;
      this.messages = null;
    } else {
      this.offset = this.messages.results.length;
    }

    this._getMessagesSubscription = this._messageService.getMessages(this._params).subscribe(messages=> {
      this.loading = false;
      // If loading more, add new results to the end of prev results
      if (loadMore) messages.results = this.messages.results.concat(messages.results);
      this.messages = messages;

      this._messageService.markAllRead(this._params).subscribe((_) => {
        this._userService.refreshCurrentUser().subscribe((_) => {
          // noop
        });
      });
    });
  }

  getAllActivities() {
    this.limit = null;
    this.loadMessages(true);
    this.limit = MESSAGES_PER_PAGE;
  }

  updateDate(type: 'gte' | 'lte', value:Date) {
    if (type == 'gte') {
      this.created_at__gte = value;
      this._router.navigate(['/messages'], { queryParams: { created_at__gte: this.created_at__gte },  queryParamsHandling: "merge" });
      return;
    }
    this.created_at__lte = value;
    this._router.navigate(['/messages'], { queryParams: { created_at__lte: this.created_at__lte },  queryParamsHandling: "merge" });
  }

  dayTrackBy(_:number, day:{title: string, messages: Message[]}) {
    return `${day.title}-${day.messages[0].created_at.toDateString()}`;
  }

  messagesTrackBy(_:number, message:Message) {
    return `${message.id}`;
  }

}
