// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  enableSW: false,
  // apiURL: 'https://devapi.mibemusic.ml',
  // apiURL: 'https://qaapi.mibemusic.ml',

  //apiURL: 'https://qaapi.mibemusic.xyz',
  apiURL: 'https://api.mibemusic.com',
  //apiURL: 'http://mibe-api.intuitiveperception.com:8000',
  // host: 'qaclient.mibemusic.xyz',

  // apiURL:'https://demoapi.mibemusic.xyz',
  //host: 'https://localhost:4200',
  host: 'https://mibemusic.com',
  awsHost: 'https://music.mibemusic.com/',
  protocol: 'https',
  stripe_key: 'pk_test_Jtkhuw4kk82JYNufldT3hzr500oMJVGszz',
  page_title_prefix: 'MIBE',
  page_title_delimiter: '|'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
