import { MibeModel } from './mibemodel.model';
import { Track } from './track.model';
import { Playlist } from './playlist.model';

export class Activity implements MibeModel {
  id: number;
  action: string;
  content_object: Track|Playlist|null;
  additional_data: any;
  created_at: Date;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.action = json.action;
    switch(json.action) {
      case 'played_track':
      case 'added_to_playlist':
      case 'removed_from_playlist':
      case 'downloaded':
        this.content_object = new Track(json.content_object);
        break;
      case 'playlist_created':
      case 'playlist_duplicated':
      case 'playlist_shared':
        this.content_object = new Playlist(json.content_object);
        break;
      default:
        this.content_object = null;
    }
    this.additional_data = json.additional_data;
    this.created_at = new Date(json.created_at);
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'action': this.action,
      'content_object': this.content_object ? this.content_object.toJSON() : null,
      'additional_data': this.additional_data,
      'created_at': this.created_at.toString()
    };
    return json;
  }
}
