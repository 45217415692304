import { Component, OnInit, OnDestroy } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { environment } from '../../environments/environment';
import { ActivityService } from '../shared/services/activity.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Activity } from '../shared/models/activity.model';
import { APIListResponse } from '../shared/models/apiresponse.model';
import { Track } from '../shared/models/track.model';
import { Playlist } from '../shared/models/playlist.model';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';
import * as moment from 'moment';

import {
  Subscription
} from 'rxjs';
import {
  trigger,
  style,
  animate,
  transition
} from '@angular/animations';

const ACTIVITIES_PER_PAGE = 12;

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.scss'],
  animations: [
    trigger('activityInOut', [
      transition(':enter', [
        style({
          padding: '0',
          height: '0',
          opacity: '0'
        }),
        animate('0.7s ease-in-out', style({
          padding: '*',
          height: '*',
          opacity: '1'
        }))
      ]),
      transition(':leave', [
        style({
          padding: '*',
          height: '*',
          opacity: '1'
        }),
        animate('0.3s ease-in-out', style({
          padding: '0',
          height: '0',
          opacity: '0'
        }))
      ])
    ]),
  ]
})
export class ActivityComponent implements OnInit, OnDestroy {

  maxDate = new Date();
  activities: APIListResponse<Activity>;

  loading = true;

  action: string | string[] = null;
  created_at__gte: Date;
  created_at__lte: Date;
pageNumber: any = 1
  offset = 0;
  limit = ACTIVITIES_PER_PAGE;

  get allActions(): string[] {
    return [
      'played_track',
      'searched',
      'playlist_created',
      'playlist_deleted',
      'playlist_duplicated',
      'playlist_shared',
      'added_to_playlist',
      'removed_from_playlist',
      'downloaded',
      'album_shared',
      'artist_shared',
      'downloaded_playlist',
      'track_shared',

    ];
  }

  get dateFormatOptions() {
    return {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    };
  }

  get days(): Object[] {
    if (!this.activities) return null;
    let days = [];
    let today = new Date();
    let yesterday = new Date(new Date().setDate(today.getDate() - 1));
    var curDay = today;
    var curObject = {
      title: "Today",
      activities: []
    };
    for (let activity of this.activities.results) {
      if (this.is_day(activity.created_at, curDay)) {
        curObject.activities.push(activity);
        continue;
      }
      if (curObject.activities.length > 0) days.push(Object.assign({}, curObject));
      curObject.activities = [activity];
      curObject.title = this.is_day(activity.created_at, yesterday) ? 'Yesterday' : activity.created_at.toLocaleDateString('en-US', this.dateFormatOptions);
      curDay = new Date(activity.created_at.toDateString());
    }
    if (curObject.activities.length > 0) days.push(Object.assign({}, curObject));
    return days;
  }

  private is_day(d1: Date, d2: Date) {
    return d1.getDate() == d2.getDate() && d1.getMonth() == d2.getMonth() && d1.getFullYear() == d2.getFullYear();
  }

  get activeAction() {
    return this.actionState(this.action);
  }

  actionState(action: string | string[]) {
    if (!action) return 'all'
    switch (action) {
      case 'played_track':
        return 'songs_played';
      case 'searched':
        return 'searches';
      case 'downloaded':
        return 'downloads';
        case 'playlist_shared':
        return 'playlist_shared';
        case 'track_shared':
        return 'track_shared';
        case 'downloaded_playlist':
        return 'downloaded_playlist';
    }
    if (action.includes('playlist')) return 'playlist_activity';
    if (action != null) {
      if (action.length > 0) {
        if (action.includes('playlist_created') && action.includes('added_to_playlist')) return 'playlist_activity';
      }
    }
    return 'all';
  }

  private get _params(): { [param: string]: string | string[] } {
    let params = {}
    if (this.action) params['action'] = this.action;
    else params['action'] = this.allActions;
    let from = moment(this.created_at__gte).add('days', 1).toISOString();
    let to = moment(this.created_at__lte).add('days', 1).toISOString();
    // console.log(from)
    if (this.created_at__gte) params['created_at__gte'] = from;
    if (this.created_at__lte) params['created_at__lte'] = to;
    if (this.limit) params['limit'] = this.limit.toString();
    if (this.offset) params['offset'] = this.offset.toString();
    params['ordering'] = '-created_at';
    params['page'] = this.pageNumber;

    return params;
  }

  private _subscriptions: Subscription[] = [];
  private _getActivitiesSubscription: Subscription;

  constructor(
    private _activityService: ActivityService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _historyService: HistoryService
  ) { }

  ngOnInit() {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params => {
        let action = params.getAll('action');
        if (action.length == 1) this.action = action[0];
        else if (action.length > 0) this.action = action;
        else this.action = null;
        let created_at__gte = params.get('created_at__gte');
        if (created_at__gte) this.created_at__gte = new Date(created_at__gte);
        else this.created_at__gte = null;
        let created_at__lte = params.get('created_at__lte');
        if (created_at__lte) this.created_at__lte = new Date(created_at__lte);
        else this.created_at__lte = null;
        this.pageNumber = 1;
        this.loadActivities();

        this._historyService.addItemToHistory('Activity');
      })
    );

    this._activityService.getActivities(this._params).subscribe(activities => {
      this.loading = false;
      this.activities = activities;
    });

    const title = this._pageTitleService.createPageTitle(['Activity']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    const image = `${environment.protocol}://${environment.host}/assets/img/activity-background.jpg`;
    this._meta.addTags([
      { name: 'description', content: description },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@MIBE_music' },
      { name: 'twitter:title', content: 'MIBE Activity' },
      { name: 'twitter:description', content: description },
      { name: 'twitter:image', content: image },
      { name: 'og:type', content: 'website' },
      { name: 'og:title', content: 'Activity' },
      { name: 'og:url', content: `${environment.protocol}://${environment.host}/activity` },
      { name: 'og:image', content: image },
      { name: 'og:description', content: description }
    ]);

    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  loadActivities(loadMore = false) {
    this.loading = true;
    if (this._getActivitiesSubscription && !this._getActivitiesSubscription.closed) {
      this._getActivitiesSubscription.unsubscribe();
    }
    if (!loadMore) {
      this.offset = 0;
      this.activities = null;
      this.pageNumber = 1
    } else {
      this.pageNumber = this.pageNumber + 1;
      this.offset = this.activities.results.length;
    }

    this._getActivitiesSubscription = this._activityService.getActivities(this._params).subscribe(activities => {
      this.loading = false;
      // If loading more, add new results to the end of prev results
      if (loadMore) activities.results = this.activities.results.concat(activities.results);
      this.activities = activities;
    });
  }

  getAllActivities() {
    this.limit = null;
    this.loadActivities(true);
    this.limit = ACTIVITIES_PER_PAGE;
  }

  actionLink(activity: Activity) {
    switch (activity.action) {
      case 'played_track':
        if (activity.content_object && activity.content_object instanceof Track && activity.content_object.id) return { routerLink: ['/browse', activity.content_object.id], queryParams: {} };
        return { routerLink: ['/browse'], queryParams: {} };
      case 'searched':
      
        if (activity.additional_data && activity.additional_data.genre)
        {
          this._activityService.activitySub.next(true)
          return { routerLink: ['/genre',activity.additional_data.genre.id], queryParams: { search: activity.additional_data.keyword, activity: true } };
        } 
        return { routerLink: ['/activity'], queryParams: {} };
      case 'playlist_created':
        if (activity.content_object && activity.content_object instanceof Playlist && activity.content_object.id) return { routerLink: ['/playlist', activity.content_object.id], queryParams: {} };
        return { routerLink: ['/playlists'], queryParams: {} };
      case 'playlist_deleted':
        return { routerLink: ['./'], queryParams: {} };
      case 'playlist_duplicated':
        if (activity.content_object && activity.content_object instanceof Playlist && activity.content_object.id) return { routerLink: ['/playlist', activity.content_object.id], queryParams: {} };
        return { routerLink: ['/playlists'], queryParams: {} };
      case 'playlist_shared':
        if (activity.content_object && activity.content_object instanceof Playlist && activity.content_object.id) return { routerLink: ['/playlist', activity.content_object.id], queryParams: {} };
        return { routerLink: ['/playlists'], queryParams: {} };
      case 'added_to_playlist':
        if (activity.additional_data && activity.additional_data.playlist_id) return { routerLink: ['/playlist', activity.additional_data.playlist_id], queryParams: {} };
        return { routerLink: ['/playlists'], queryParams: {} };
      case 'removed_from_playlist':
        if (activity.content_object && activity.content_object instanceof Track && activity.content_object.id) return { routerLink: ['/browse', activity.content_object.id], queryParams: {} };
        return { routerLink: ['/browse'], queryParams: {} };
      case 'downloaded':
        if (activity.additional_data && activity.additional_data['track_id']) return { routerLink: ['/downloads', activity.additional_data['track_id']], queryParams: {} };
        return { routerLink: ['/downloads'], queryParams: {} };
        case 'downloaded_playlist':
        if (activity.additional_data && activity.additional_data.playlist_id) return { routerLink: ['/playlist', activity.additional_data.playlist_id], queryParams: {} };
        return { routerLink: ['/playlists'], queryParams: {} };
    }
    return { routerLink: ['./'], queryParams: {} };
  }

  typePretty(activity: Activity) {
    switch (activity.action) {
      case 'played_track':
        return 'Played Track';
      case 'searched':
        return 'Searched';
      case 'playlist_created':
        return 'Created Playlist';
      case 'playlist_deleted':
        return 'Deleted Playlist';
      case 'playlist_duplicated':
        return 'Duplicated Playlist';
      case 'playlist_shared':
        return 'Shared Playlist';
      case 'added_to_playlist':
        return 'Added to Playlist';
      case 'removed_from_playlist':
        return 'Removed from Playlist';
      case 'downloaded':
        return 'Downloaded';

      case 'album_shared':
        return 'Album Shared';
      case 'artist_shared':
        return 'Artist Shared';
      case 'downloaded_playlist':
        return 'Playlist Downloaded';
      case 'track_shared':
        return 'Track Shared';
    }
    return 'Action Taken';
  }

  contentPretty(activity: Activity) {
    switch (activity.action) {
      case 'played_track':
        if (activity.content_object && activity.content_object instanceof Track && activity.content_object.title) return activity.content_object.title;
        return 'Played Track';
      case 'searched':
     
        if (activity.additional_data && activity.additional_data.keyword) return activity.additional_data.keyword;
        return 'Searched';
      case 'playlist_created':
        if (activity.content_object && activity.content_object instanceof Playlist && activity.content_object.name) return activity.content_object.name;
        return 'Created Playlist';
      case 'playlist_deleted':
        if (activity.additional_data && activity.additional_data.playlist_name) return activity.additional_data.playlist_name;
        return 'Deleted Playlist';
      case 'playlist_duplicated':
        if (activity.content_object && activity.content_object instanceof Playlist && activity.content_object.name) return activity.content_object.name;
        return 'Duplicated Playlist';
      case 'playlist_shared':
        if (activity.content_object && activity.content_object instanceof Playlist && activity.content_object.name) return activity.content_object.name;
        return 'Shared Playlist';
      case 'added_to_playlist':
        if (activity.additional_data && activity.additional_data.playlist_name) return activity.additional_data.playlist_name;
        return 'Added to Playlist';
      case 'removed_from_playlist':
        if (activity.additional_data && activity.additional_data.playlist_name) return activity.additional_data.playlist_name;
        return 'Removed from Playlist';
      case 'downloaded':
        if (activity.additional_data && activity.additional_data.track_name) return activity.additional_data.track_name;
        return 'Downloaded';

      case 'album_shared':
        if (activity.additional_data && activity.additional_data.playlist_name) return activity.additional_data.playlist_name;
        return 'Album Shared';
      case 'track_shared':
        if (activity.additional_data && activity.additional_data.track_title) return activity.additional_data.track_title;
        return 'Track Shared';
      case 'downloaded_playlist':
        if (activity.additional_data && activity.additional_data.playlist_name) return activity.additional_data.playlist_name;
        return 'Playlist Downloaded';
      case 'artist_shared':
        if (activity.additional_data && activity.additional_data.artist_name) return activity.additional_data.artist_name;
        return 'Artist Shared';

        case 'downloaded_playlist':
        if (activity.additional_data && activity.additional_data.playlist_name) return activity.additional_data.playlist_name;
        return 'Download Playlist';

    }
    return 'Action Taken';
  }

  updateDate(type: 'gte' | 'lte', value: Date) {
    this.pageNumber = 1
    if (type == 'gte') {
      this.created_at__gte = value;
      this._router.navigate(['/activity'], { queryParams: { created_at__gte: this.created_at__gte }, queryParamsHandling: "merge" });
      return;
    }
    this.created_at__lte = value;
    this._router.navigate(['/activity'], { queryParams: { created_at__lte: this.created_at__lte }, queryParamsHandling: "merge" });
  }

  dayTrackBy(_: number, day: { title: string, activities: Activity[] }) {
    return `${day.title}-${day.activities[0].created_at.toDateString()}`;
  }

  activityTrackBy(_: number, activity: Activity) {
    return `${activity.id}`;
  }

}
