import { MibeModel } from './mibemodel.model';

export class ContactRequest implements MibeModel {
  id: number;
  name: string;
  email: string;
  phone_number: string;
  company_name: string;
  project: string;
  subject: string;
  message: string;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Mood.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Mood.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.name = json.name;
    this.email = json.email;
    this.phone_number = json.phone_number;
    this.company_name = json.company_name;
    this.project = json.project;
    this.subject = json.subject;
    this.message = json.message;
  }
  /**
   * Returns a JSON representation of our Mood
   * @return {any} - The JSON representation of our Mood
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'name': this.name,
      'email': this.email,
      'phone_number': this.phone_number,
      'company_name': this.company_name,
      'project': this.project,
      'subject': this.subject,
      'message': this.message
    };
    return json;
  }
}
