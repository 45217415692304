import {
  Component,
  OnInit,
  HostBinding,
  Output,
  EventEmitter,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import {COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete, MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatSelect } from '@angular/material';
import {
  trigger,
  style,
  animate,
  transition,
} from '@angular/animations';
import { Subscription, Observable, of, ReplaySubject } from 'rxjs';
import { map, startWith, debounceTime, distinctUntilChanged, switchMap, filter, tap } from 'rxjs/operators';
import { Genre } from '../models/genre.model';
import { GenreService } from '../services/genre.service';
import { Playlist } from '../models/playlist.model';
import { PlaylistService } from '../services/playlist.service';
import { Mood } from '../models/mood.model';
import { MoodService } from '../services/mood.service';
import { Artist } from '../models/artist.model';
import { ArtistService } from '../services/artist.service';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { ActivityService } from '../services/activity.service';
import { Track } from '../models/track.model';
import { TrackService } from '../services/track.service';
import { filtersToParams, MinMax, minMaxToString, stringToMinMax } from '../services/track.service';

interface SelectString {
  value: string;
  label: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  animations: [
    trigger('searchInOut', [
      transition(':enter', [
        style({
          opacity: '0'
        }),
        animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)', style({
          opacity: '1'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          overflow: 'hidden'
        }),
        animate('0.7s cubic-bezier(0.78, 0, 0.81, 0)', style({
          opacity: '0'
        }))
      ])
    ])
  ]
})
export class SearchComponent implements OnInit, OnDestroy {

  @HostBinding('@searchInOut')
  public animateSearch = true;

  @ViewChild('searchField', {static: true})
  searchField: ElementRef<HTMLInputElement>;


  artistFilterCtrl = new FormControl();
  filteredArtistOptions: ReplaySubject<Artist[]> = new ReplaySubject<Artist[]>(1);
  searchingArtist = false;

  tempoFilterCtrl = new FormControl();
  filteredTempoOptions: ReplaySubject<SelectString[]> = new ReplaySubject<SelectString[]>(1);

  @Output()
  closeSearch = new EventEmitter();

  searchModel = {
    searchString: '',
    genre: null,
    subGenre: null,
    mood: null,
    duration: null,
    tempo: null,
    vocals: null,
    artist: null
  };
  get durationFilter(): string {
    return minMaxToString(this.searchModel.duration);
  }
  get tempoFilter(): string {
    return minMaxToString(this.searchModel.tempo);
  }
  get genre(): number {
    return this.searchModel.genre;
  }
  set genre(g: number) {
    this.searchModel.genre = g;
    this.genreChange(this.genre);
  }
  get subGenre(): number {
    return this.searchModel.subGenre;
  }
  set subGenre(g: number) {
    this.searchModel.subGenre = g;
  }
  get genres(): Genre[] {
    return this._genres;
  }
  set genres(genres: Genre[]) {
    this._genres = genres;
    this.genreChange(this.genre);
  }
  get subGenres(): Genre[] {
    return this._subGenres;
  }
  set subGenres(subGenres: Genre[]) {
    this._subGenres = subGenres;
    if (this.genre) {
      let selected = subGenres.find(g => g.id == this.genre);
      if (selected) {
        this.genre = selected.parent.id;
        this.subGenre = selected.id;
      }
    }
  }
  get popularArtists(): Artist[] {
    // Uncomment this to show featured items
    return []; // this.artists.filter(a => a.is_featured);
  }
  get selectedArtist(): Artist {
    return this._selectedArtist;
  }
  set selectedArtist(a: Artist) {
    this._selectedArtist = a;
    this.searchModel.artist = a ? a.id : null;
  }
  loadingGenres = false;
  loadingMoods = false;
  loadingArtists = false;
  moods: Mood[] = [];
  popularGenres: Genre[] = [];
  popularTracks: Track[] = [];
  featuredPlaylists: Playlist[] = [];
  recentSearches: string[] = [];

  currentUser: User;

  private _genres: Genre[] = [];
  private _subGenres: Genre[] = [];
  private _selectedArtist: Artist;

  private get _params(): { [param: string]: string | string[]; } {
    let params = filtersToParams({
      duration: this.searchModel.duration,
      tempo: this.searchModel.tempo,
      vocals: this.searchModel.vocals,
      artistId: this.searchModel.artist,
      composerId: null,
      bpm: null
    });
    if (this.searchModel.searchString) {
      params['search'] = this.searchModel.searchString;
    }
    if (this.searchModel.subGenre) {
      params['genre'] = `${this.searchModel.subGenre}`;
    } else if (this.searchModel.genre) {
      params['genre'] = `${this.searchModel.genre}`;
    }
    if (this.searchModel.mood) {
      params['mood'] = `${this.searchModel.mood}`;
    }
    if (this.searchModel.artist) {
      params['artist'] = `${this.searchModel.artist}`;
    }
    return params;
  }

  private _subscriptions: Subscription[] = [];
  private _subGenreFetchSubscription: Subscription;
  private _currentlyBrowsingGenre: Genre;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _genreService: GenreService,
    private _moodService: MoodService,
    private _artistService: ArtistService,
    private _playlistService: PlaylistService,
    private _userService: UserService,
    private _activityService: ActivityService,
    private _trackService: TrackService,
    private _renderer: Renderer,
    private _platform: Platform
  ) { }

  ngOnInit() {
    this._subscriptions.push(this.artistFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      tap(() => {
        this.searchingArtist = true;
      }),
      switchMap((v) => {
        if (!v || v.length <= 2) {
          if (this.selectedArtist) {
            return of([this.selectedArtist]);
          } else {
            return of([]);
          }
        }
        return this._artistService.getAllArtists({
          ordering: 'name',
          search: v
        }).pipe(
          map(a => {
            let contains_selected = a.findIndex(artist => {
              return !this.selectedArtist || artist.id == this.selectedArtist.id;
            }) != -1;
            if (!contains_selected) {
              a.push(this.selectedArtist);
            }
            return a;
          })
        );
      }),
      tap(() => {
        this.searchingArtist = false;
      })
    ).subscribe(a => {
      this.filteredArtistOptions.next(a);
    }));
    const baseTempoOptions: SelectString[] = [
      {value: '160:', label: 'Very Fast: 160-400 bpm'},
      {value: '140:159', label: 'Fast: 140-159 bpm'},
      {value: '120:139', label: 'Lively: 120-139 bpm'},
      {value: '100:119', label: 'Moderate: 100-119 bpm'},
      {value: '60:99', label: 'Slow: 60-99 bpm'},
      {value: ':59', label: 'Very Slow: 0-59 bpm'}
    ];
    this._subscriptions.push(this.tempoFilterCtrl.valueChanges.pipe(
      startWith(''),
      debounceTime(150),
      distinctUntilChanged(),
      map((v) => {
        let addedTempoOptions: SelectString[] = [];
        if (this.searchModel.tempo) {
          let tempoStr = minMaxToString(this.searchModel.tempo);
          if (baseTempoOptions.findIndex(o => o.value == tempoStr) == -1) {
            addedTempoOptions.push({value: tempoStr, label: `${this.searchModel.tempo.min} bpm`});
          }
        }
        if (!!v && !isNaN(parseInt(v))) {
          addedTempoOptions.push({value: v + ':' + `${parseInt(v) + 1}`, label: `${v} bpm`});
        }
        return addedTempoOptions.concat(baseTempoOptions);
      })
    ).subscribe(o => {
      this.filteredTempoOptions.next(o);
    }));
    this._subscriptions.push(
      this._genreService.currentlyBrowsingGenre.subscribe((g) => {
        this._currentlyBrowsingGenre = g;
        let genreId = g ? `${g.id}` : this._activatedRoute.snapshot.queryParamMap.get('genre');
        if (genreId) {
          this.genre = parseInt(genreId);
        } else {
          this.genre = null;
        }
      })
    );
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe((params) => {
        this.searchModel.searchString = params.get('search') || '';
        let genre = this._currentlyBrowsingGenre ? `${this._currentlyBrowsingGenre.id}` : params.get('genre');
        if (genre) {
          this.genre = parseInt(genre);
        } else {
          this.genre = null;
        }
        let mood = params.get('mood');
        if (mood) {
          this.searchModel.mood = parseInt(mood);
        } else {
          this.searchModel.mood = null;
        }
        let artist = params.get('artist');
        if (artist) {
          let artistId = parseInt(artist);
          if (this.searchModel.artist != artistId) {
            this.loadingArtists = true;
            this._artistService.getArtist(artistId)
              .subscribe(a => {
                this.selectedArtist = a;
                this.filteredArtistOptions.next([a]);
                this.loadingArtists = false;
              });
          }
        } else {
          this.searchModel.artist = null;
          this.selectedArtist = null;
        }
        let bpm_lte = params.get('bpm__lte');
        let bpm_gte = params.get('bpm__gte');
        let tempo: MinMax = null;
        if (bpm_lte !== null || bpm_gte !== null) {
          tempo = {};
          if (bpm_lte !== null) {
            tempo['max'] = parseFloat(bpm_lte);
          }
          if (bpm_gte !== null) {
            tempo['min'] = parseFloat(bpm_gte);
          }
        }
        this.searchModel.tempo = tempo;
        let duration_lte = params.get('duration__lte');
        let duration_gte = params.get('duration__gte');
        let duration: MinMax = null;
        if (duration_lte !== null || duration_gte !== null) {
          duration = {};
          if (duration_lte !== null) {
            duration['max'] = parseFloat(duration_lte);
          }
          if (duration_gte !== null) {
            duration['min'] = parseFloat(duration_gte);
          }
        }
        this.searchModel.duration = duration;
        let is_instrumental = params.get('is_instrumental');
        let vocals: boolean = null;
        if (is_instrumental !== null) {
          vocals = is_instrumental !== 'true';
        }
        this.searchModel.vocals = vocals;
      })
    );
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u => this.currentUser = u)
    );
    this.loadingGenres = true;
    this._genreService.getParentGenres().subscribe(genres => {
      this.loadingGenres = false;
      this.genres = genres;
    });
    this.loadingMoods = true;
    this._moodService.getAllMoods().subscribe(moods => {
      this.loadingMoods = false;
      this.moods = moods;
    });
    // Uncomment this to show featured items
    /*
    this._playlistService.getFeaturedPlaylists().subscribe(
      playlists => this.featuredPlaylists = playlists.results
    );
    this._genreService.getFeaturedGenres().subscribe(
      genres => this.popularGenres = genres
    );
    this._trackService.getFeaturedTracks(3).subscribe(
      tracks => this.popularTracks = tracks
    );
    */
    this._activityService.getActivities({'action': 'searched', 'limit': '10', 'ordering': '-created_at'})
      .pipe(
        map(as => as.results.map(a => {
          
          if (a.additional_data) {
            return a.additional_data.search_string as string;
          }
          return '';
        }).filter(a => !!a))
      ).subscribe(s => this.recentSearches = s);

    // Autofocus on input
    if (this.searchField && this._platform.isBrowser) {
      this._renderer.invokeElementMethod(this.searchField.nativeElement, 'focus');
    }
  }
  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  search() {
    // If a mood or a genre was selected and nothing else navigate to the page
    let hasOther = false;
    let hasMood = false;
    let hasGenre = false;
    let hasArtist = false;
    Object.keys(this.searchModel).forEach((k) => {
      if (k == 'genre' || k == 'subGenre') {
        hasGenre = hasGenre || !!this.searchModel[k];
      } else if (k == 'mood') {
        hasMood = !!this.searchModel.mood;
      } else if (k == 'artist') {
        hasArtist = !!this.searchModel.artist;
      } else {
        hasOther = hasOther || !!this.searchModel[k];
      }
    });
    // Perform search
    this._activityService.recordActivity('searched', null, {search_string: this.searchModel.searchString}).subscribe();
    if (hasMood && !hasGenre && !hasArtist) {
      this._router.navigate(['/mood', this.searchModel.mood], {queryParams: this._params});
      this.close();
      return;
    }
    if (hasGenre && !hasArtist) {
      this._router.navigate(['/genre', this.searchModel.subGenre || this.searchModel.genre], {queryParams: this._params});
      this.close();
      return;
    }
    if (hasArtist) {
      this._router.navigate(['/artist', this.searchModel.artist], {queryParams: this._params});
      this.close();
      return;
    }
    this._router.navigate(['/browse'], {queryParams: this._params});
    this.close();
  }

  genreChange(genre: number) {
    this.searchModel.genre = genre;

    if (!genre) { return; }

    if (this.subGenres && this.subGenres.length) {
      let selected = this.subGenres.find(g => g.id == this.genre);
      if (selected) {
        this.searchModel.genre = selected.parent.id; // Don't need to update from network
        this.subGenre = selected.id;
        return;
      }
    }
    if (this.genres && this.genres.length) {
      let selected = this.genres.find(g => g.id == this.genre);
      if (!selected) {
        this.loadingGenres = true;
        this._genreService.getGenre(this.genre).subscribe(g => {
          this.loadingGenres = false;
          if (g.parent) {
            this.genre = g.parent.id;
            this.subGenre = g.id;
          }
        });
        return;
      }
    }

    if (this._subGenreFetchSubscription && !this._subGenreFetchSubscription.closed) {
      this._subGenreFetchSubscription.unsubscribe();
    }
    this.subGenres = [];
    this._subGenreFetchSubscription = this._genreService.getSubGenres(genre).subscribe(genres => {
      this.subGenres = genres;
    });
  }

  durationFilterChange(duration: string) {
    this.searchModel.duration = stringToMinMax(duration);
  }

  tempoFilterChange(tempo: string) {
    this.searchModel.tempo = stringToMinMax(tempo);
  }

  genresTrackBy(i: number, genre: Genre) {
    return genre.id + genre.name;
  }

  tracksTrackBy(i: number, track: Track) {
    return track.id + track.title;
  }

  moodsTrackBy(i: number, mood: Mood) {
    return mood.id + mood.name;
  }

  selectStringTrackBy(i: number, s: SelectString) {
    if (!s) {
      return s;
    }
    return s.value + s.label;
  }

  artistsTrackBy(i: number, artist: Artist) {
    return artist.id + artist.name;
  }

  artistCompareWith(a1: Artist, a2: Artist) {
    if (!a1 || !a2) {
      return a1 == a2;
    }
    return a1.id == a2.id;
  }

  playlistsTrackBy(i: number, playlist: Playlist) {
    return playlist.id + playlist.name;
  }

  close() {
    this.closeSearch.emit();
  }

  clear() {
    this.searchModel = {
      searchString: '',
      genre: null,
      subGenre: null,
      mood: null,
      duration: null,
      tempo: null,
      vocals: null,
      artist: null
    };
  }

}
