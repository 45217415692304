import { Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Subscription, BehaviorSubject, Observable } from 'rxjs';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';
import { HistoryService } from '../shared/services/history.service';
import {
  debounceTime,
  distinctUntilChanged
} from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Track } from '../shared/models/track.model';
import { GetTracksOptions } from '../shared/services/track.service';

@Component({
  selector: 'app-downloads',
  templateUrl: './downloads.component.html',
  styleUrls: ['./downloads.component.scss']
})
export class DownloadsComponent implements OnInit, OnDestroy {

  @ViewChild('searchActionField', {static: false})
  searchActionField: ElementRef;
  searchControl = new FormControl();
  loading = false;
  tracks: any;
  get searchString(): string {
    return this._searchString;
  }
  set searchString(s: string) {
    if (this._searchString != s) {
      this._searchString = s;
      if (this.searchControl.value != s) {
        this.searchControl.setValue(s);
      }
      this._router.navigate([], { relativeTo: this._activatedRoute, queryParams: {
        'search': s
      }, queryParamsHandling: 'merge'});
    }
  }
  private _tracksSubject: BehaviorSubject<ReadonlyArray<Track>> = new BehaviorSubject<ReadonlyArray<Track>>([]);
  private _searchString = '';
  private _subscriptions: Subscription[] = [];
  private _getTracksOptions: GetTracksOptions = {};


  get getTracksOptions(): GetTracksOptions {
    return this._getTracksOptions;
  }
  set getTracksOptions(o: GetTracksOptions) {
    this._getTracksOptions = o;
  }

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _renderer: Renderer,
    private _analyticsService: AnalyticsService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _historyService: HistoryService,
    private http: HttpClient
  ) {
   
  }

  ngOnInit() {
    // let requestURL = environment.apiURL + `/api/v1/trackversions/getdownloads/`
    // this.http.get(requestURL).subscribe(x =>{
      
    //  this.tracks = x['results']
    // })
    
    this._subscriptions.push(
      this._activatedRoute.paramMap.subscribe(params=> {
        // Load Filters from URL
        let searchString = params.get('search');
        let trackId = params.get('trackId');
        let trackIdN: number;
        if (trackId) {
          trackIdN = parseInt(trackId);
          this._getTracksOptions.trackIds = [trackIdN];
        }
        this._getTracksOptions.downloadable = true;
        if (searchString !== null) {
          this.searchString = searchString;
        } else {
          this.searchString = '';
        }

        this._historyService.addItemToHistory('Downloads');
      })
    );
    this._subscriptions.push(
      this.searchControl.valueChanges.pipe(
        debounceTime(250),
        distinctUntilChanged()
      )
      .subscribe(v => {
        this.searchString = v;
      })
    );
    const title = this._pageTitleService.createPageTitle(['Downloads']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    this._meta.addTags([
      {name: 'description', content: description}
    ]);
    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  searchAction() {
    if (this.searchActionField) {
      this._renderer.invokeElementMethod(this.searchActionField.nativeElement, 'blur');
    }
  }

}
