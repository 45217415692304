import { Component, OnInit, OnDestroy, Inject } from '@angular/core';

import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'

import { Subscription, Observable, forkJoin } from 'rxjs';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { TrackVersionService } from '../services/trackversion.service';
import { SiteConfigurationService } from '../services/siteconfiguration.service';
import { SiteConfiguration } from '../models/siteconfiguration.model';

export interface DialogData {
  track: Track;
  version: TrackVersion
}

@Component({
  selector: 'app-upload-version',
  templateUrl: './upload-version.component.html',
  styleUrls: ['./upload-version.component.scss']
})
export class UploadVersionComponent implements OnInit, OnDestroy {

  loading = false;
  saving = false;
  uploaded = false;
  isEdit = false;
  versionDragging = false;
  track: Track;
  siteConfig: SiteConfiguration;

  get fileName() {
    if(this._files && this._files.length) {
      if (this._files.length == 1) {
        return this._files[0].name;
      } else {
        return `${this._files.length} Files`;
      }
    }
    return "No File Selected";
  }

  get curWavFile() {
    if(this.data.version && this.data.version.wav_file) {
      return this.data.version.wav_file;
    }
    return '/';
  }

  get files(): FileList {
    return this._files;
  }

  get trackVersionModel() {
    return this._trackVersionModel;
  }

  set trackVersionModel(t) {
    this._trackVersionModel = t;
  }

  private _files: FileList = null;
  private _trackVersionModel: TrackVersion;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _trackVersionService: TrackVersionService,
    private _siteConfigService: SiteConfigurationService,
    public dialogRef: MatDialogRef<UploadVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit() {
    this._siteConfigService.getSiteConfiguration().subscribe(c => {
      this.siteConfig = c;
    });
    if (this.data.track) {
      this.track = this.data.track;
    } else {
      this.close();
    }
    if (this.data.version) {
      this.trackVersionModel = this.data.version;
      this.isEdit = true;
    } else {
      this.trackVersionModel = new TrackVersion(null);
    }
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  close() {
    this.dialogRef.close(this.trackVersionModel);
  }

  onSubmit() {
    this.saving = true;
    let observables: Observable<any>[] = [];
    if (this.isEdit) {
      if (!this._files.length) {
        for (let i = 0, tot = this.files.length; i < tot; i++) {
          let f = this.files[i];
          let versionModel = new TrackVersion(this.trackVersionModel.toJSON());
          observables.push(this._trackVersionService.updateTrackVersion(versionModel, f));
        }
      } else {
        observables.push(this._trackVersionService.updateTrackVersion(this.trackVersionModel, null));
      }
      forkJoin(observables).subscribe(()=> {
        this.close();
        this.saving = false;
      });
      return;
    }
    for (let i = 0, tot = this.files.length; i < tot; i++) {
      let f = this.files[i];
      let versionModel = new TrackVersion(this.trackVersionModel.toJSON());
      observables.push(this._trackVersionService.createTrackVersion(this.track.id, versionModel, f));
    }

    forkJoin(observables).subscribe(()=> {
      this.uploaded = true;
      this.saving = false;
    });
  }

  versionFileInputChange(files:FileList) {
    this._files = files;
  }

}
