import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { SiteConfiguration } from '../models/siteconfiguration.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SiteConfigurationService {

  private _config: SiteConfiguration;

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getSiteConfiguration(): Observable<SiteConfiguration> {
    if (this._config) { return of(this._config); }
    // Check if support pages are cached from server
    const SITE_CONFIG_KEY = makeStateKey<any>('siteconfig');
    if (this._transferState.hasKey(SITE_CONFIG_KEY)) {
      const config = this._transferState.get<any>(SITE_CONFIG_KEY, null);
      this._transferState.remove(SITE_CONFIG_KEY);
      this._config = new SiteConfiguration(config);
      return of(this._config);
    }
    let headers = new HttpHeaders();
    return this.http.get<any>(
      environment.apiURL + '/api/v1/config/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the products
        if (!this._platform.isBrowser) {
          this._transferState.set(SITE_CONFIG_KEY, res);
        }
      }),
      map((res) => {
        this._config = new SiteConfiguration(res);
        return this._config;
      })
    );
  }
}
