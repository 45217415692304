import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { GetTracksOptions } from '../services/track.service';
import { Genre } from '../models/genre.model';
import { TracksPageListComponent } from '../tracks-page-list/tracks-page-list.component';

export interface TracksPageOptions {
  subtitle: string;
  title: string;
  image: string;
}

@Component({
  selector: 'app-trackspage',
  templateUrl: './trackspage.component.html',
  styleUrls: ['./trackspage.component.scss']
})
export class TracksPageComponent implements OnInit {

  @ViewChild(TracksPageListComponent, { static: false }) tracksPageListComponent: TracksPageListComponent;

  loadingTracks = false;

  @Input()
  set baseTrackOptions(o: GetTracksOptions) {
    // console.log(o)
    this._baseTrackOptions = o;
  }
  get baseTrackOptions(): GetTracksOptions {
    return this._baseTrackOptions;
  }

  @Input()
  set subtitle(s: string) {
    this.options.subtitle = s;
  }
  get subtitle(): string {
    return this.options.subtitle;
  }

  @Input()
  set title(t: string) {
    this.options.title = t;
  }
  get title(): string {
    return this.options.title;
  }

  @Input()
  set image(i: string) {
    this.options.image = i;
  }
  get image(): string {
    return this.options.image;
  }

  @Input()
  prev: Genre;

  @Input()
  next: Genre;

  options: TracksPageOptions = {
    subtitle: '',
    title: '',
    image: ''
  }

  private _baseTrackOptions: GetTracksOptions;
  constructor() {}

  ngOnInit() {}

  ngOnDestroy() {}

  resetFilters() {
    this.tracksPageListComponent.resetFilters();
  }
}
