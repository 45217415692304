import { MibeModel } from './mibemodel.model';

export class TrackVersion implements MibeModel {
  id: number;
  title: string;
  mp3_file: string;
  waveform_file: string;
  wav_file: string;
  calculated_bpm: number;
  calculated_key: string;
  // Length of track in seconds
  duration: number;
  is_full_version: boolean;
  is_instrumental: boolean;
  favorited_by_me: boolean;
  created_at: Date;
  updated_at: Date;
  waveform_file_data:any
  /**
   * Create a TrackVersion.
   * @param {any} json - The JSON object with which to construct our TrackVersion.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackVersion.
   * @param {any} json - The JSON object with which to load our TrackVersion.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.title = json.title;
    this.mp3_file = json.mp3_file;
    this.waveform_file = json.waveform_file;
    this.wav_file = json.wav_file;
    this.calculated_bpm = json.calculated_bpm;
    this.calculated_key = json.calculated_key;
    this.duration = json.duration;
    this.is_full_version = !!json.is_full_version;
    this.is_instrumental = !!json.is_instrumental;
    this.favorited_by_me = !!json.favorited_by_me;
    this.waveform_file_data = json.waveform_file_data
    if (json.created_at) {
      this.created_at = new Date(json.created_at);
    } else {
      this.created_at = null;
    }
    if (json.updated_at) {
      this.updated_at = new Date(json.updated_at);
    } else {
      this.updated_at = null;
    }
  }
  /**
   * Returns a JSON representation of our TrackVersion
   * @return {any} - The JSON representation of our TrackVersion
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'title': this.title,
      'mp3_file': this.mp3_file,
      'waveform_file': this.waveform_file,
      'wav_file': this.wav_file,
      'calculated_bpm': this.calculated_bpm,
      'calculated_key': this.calculated_key,
      'duration': this.duration,
      'is_full_version': this.is_full_version,
      'is_instrumental': this.is_instrumental,
      'favorited_by_me': this.favorited_by_me,
      'waveform_file_data': this.waveform_file_data
    };
    if (this.created_at) {
      json['created_at'] = this.created_at.toJSON();
    }
    if (this.updated_at) {
      json['updated_at'] = this.updated_at.toJSON();
    }
    return json;
  }
}
