import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, RouterLinkActive } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { HistoryService } from '../shared/services/history.service';
import { ArtistService } from '../shared/services/artist.service';
import { Artist } from '../shared/models/artist.model';
import { Subscription } from 'rxjs';
import { matTabsRouterTransition } from '../shared/animations/mat-tabs-router-transition.animation';
import { UserService } from '../shared/services/user.service';
import { MatDialogModule } from '@angular/material';
import { DialogService } from '../shared/services/dialog.service';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.scss'],
  animations: [
    matTabsRouterTransition
  ]
})
export class ArtistComponent implements OnInit, OnDestroy {
  public track:boolean = false;
  public about:boolean = false;
  public album:boolean = false;
  @ViewChild('rlaAbout', { static: false }) rlaAbout: RouterLinkActive;
  @ViewChild('rlaAlbums', { static: false }) rlaAlbums: RouterLinkActive;
  @ViewChild('rlaTracks', { static: false }) rlaTracks: RouterLinkActive;
  get tabsState(): number {
    if (this.rlaAlbums && this.rlaAlbums.isActive) {
      this.track = true
      return 1;
    }
    if (this.rlaAbout && this.rlaAbout.isActive) {
      this.about = true
      return 2;
    }
    if (this.rlaTracks && this.rlaTracks.isActive) {
      this.album = true
      return 3;
    }
    return 0;
  }
  get artist(): Artist {
    return this._artist;
  }
  set artist(a: Artist) {
    this._artist = a;
    this._artistService.updateCurrentlyBrowsingArtist(a);
  }
  get previousHistoryItem() {
    return this._historyService.previousItem();
  }

  loading = true;
  followingLoading = false;
  loggedIn = false;
  private _artist: Artist;
  private _subscriptions: Subscription[] = [];
  private _getArtistSubscription: Subscription;
  constructor(
    private _artistService: ArtistService,
    private _activatedRoute: ActivatedRoute,
    private _platform: Platform,
    private _historyService: HistoryService,
    private _userService: UserService,
    private _dialogService: DialogService,  ) { }

  ngOnInit() {
    this._subscriptions.push(
      this._activatedRoute.paramMap.subscribe(params => {
        // Load artist from routing data or URL
        let artist: Artist;
        if (this._platform.isBrowser && window.history.state && window.history.state.data) {
          artist = new Artist(window.history.state.data);
          if (!artist.id) { artist = null; }
          this.artist = artist;
          // console.log(artist)
        }
        let artistId = params.get('artistId');
        let artistIdN: number;
        if (artistId) {
          artistIdN = parseInt(artistId);
        }
        if (this.artist && artistIdN == this.artist.id) {
          this.loading = false;
          return;
        }
        this.loadArtist(artistIdN);

        this._historyService.addItemToHistory('Artist');
      })
    );
    this._subscriptions.push(
      this._userService.currentUserStream.subscribe(u => {
        this.loggedIn = !!u;
      })
    );
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  loadArtist(artistId: number) {
    this.loading = true;
    if (this._getArtistSubscription && !this._getArtistSubscription.closed) {
      this._getArtistSubscription.unsubscribe();
    }
    this._getArtistSubscription = this._artistService.getArtist(artistId).subscribe(a => {
      this.artist = a;
      this.loading = false;
    });
  }

  followArtist() {
    if (!this._artist) { return; }
    this.followingLoading = true;
    this._artistService.favoriteArtist(this._artist)
      .subscribe(a => {
        this.followingLoading = false;
        this.artist = a;
      });
  }

  unfollowArtist() {
    if (!this._artist) { return; }
    this.followingLoading = true;
    this._artistService.unfavoriteArtist(this._artist)
      .subscribe(a => {
        this.followingLoading = false;
        this.artist = a;
      });
  }

  navigateToPreviousItem() {
    this._historyService.navigateToPrevious();
  }

  sharePlaylist(artist) {
    this._dialogService.displayShareArtistForm(artist);
  }
}
