import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { User } from '../models/user.model';
import { UserPRO } from '../models/user-pro.model';
import { TrackComposer } from '../models/trackcomposer.model';
import { Observable, ReplaySubject, BehaviorSubject, throwError } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { DataLayerCustomDimensionsService } from './gtm/data-layer-custom-dimensions.service';
import { Router, Route, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription } from '../models/subscription.model';
import { CookieService } from './cookie.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {
public user_id:any;
  get isLoggedIn(): boolean {
    return !!this._currentUser;
  }
  public currentUserStream: Observable<User>;
  public _currentUserSubject: ReplaySubject<User> = new ReplaySubject<User>(1);
  private _currentUser: User;
  private _refreshCurrentUser: Observable<User>;
  public nextState:any = null
  private _subscriptions: Subscription[] = [];
  currentUser:any;

  constructor(
    private http: HttpClient,
    private _transferState: TransferState,
    private _platform: Platform,
    private _dataLayerCustomDimensions: DataLayerCustomDimensionsService,
    public router: Router,
    public route: ActivatedRoute,
    public _cookieService: CookieService
  ) {
   
    this.currentUserStream = this._currentUserSubject.asObservable();
    // Try refreshing the current user, or fail if we're not logged in
    this.refreshCurrentUser().subscribe(
      _ => { /* We need to subscribe to this event, to cause it to happen */ },
      err => {
        // User isn't logged in
      }
    );
    this.currentUserStream.subscribe(u => {
      if (!u) {
        this._dataLayerCustomDimensions.dimensions = {
          userId: undefined
        };
      } else {
        this._dataLayerCustomDimensions.dimensions = {
          userId: `${u.id}`
        };
      }
      this._dataLayerCustomDimensions.trigger();
    });
  
  
  }

  getCurrentUserPRO(): Observable<UserPRO> {
    if (!this._currentUser) { return; }
    // Check if moods are cached from server
    const PRO_KEY = makeStateKey<Object>('currentuserpro');
    if (this._transferState.hasKey(PRO_KEY)) {
      const pro = this._transferState.get<Object>(PRO_KEY, null);
      this._transferState.remove(PRO_KEY);
      return of(new UserPRO(pro));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/user/pros/' + this._currentUser.id + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the mood
        if (!this._platform.isBrowser) {
          this._transferState.set(PRO_KEY, res);
        }
      }),
      map((res) => new UserPRO(res))
    );
  }

  updateCurrentUserPRO(pro: UserPRO): Observable<UserPRO> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/user/pros/' + this._currentUser.id + '/',
      pro.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new UserPRO(res)));
  }

  updateCurrentUser(u: User, password: string = null): Observable<User> {
    let payload = u.toJSON();
    if (password) {
      payload['password'] = password;
    }
    payload['birth_date'] = this.formatDate(payload.birth_date)
    let url = ""
    let headers = new HttpHeaders();
    const csrfToken = this._cookieService.get('csrftoken');
    if(csrfToken){
      url = environment.apiURL + '/api/v1/user/current/'
    }
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<Object>(
     url,
      payload,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      // console.log(res)
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }
  updateCurrentUserAccount(u: User, password: string = null): Observable<User> {
    let payload = u.toJSON();
    if (password) {
      payload['password'] = password;
    }
    payload['birth_date'] = this.formatDate(payload.birth_date)
    let headers = new HttpHeaders();
     let url = environment.apiURL + '/api/v1/user/current/'

    headers = headers.append("Content-Type", "application/json");
    return this.http.put<Object>(
     url,
      payload,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      // console.log(res)
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }
  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [year, month, day].join('-');
  }
  getCurrentUser(): Observable<User> {
    if (this._currentUser) {
      // console.log('current user');
      return of(this._currentUser);
    }
    // console.log('refresh user');
    return this.refreshCurrentUser();
  }

  refreshCurrentUser(): Observable<User> {
    // Check if user is cached from server
    const USER_KEY = makeStateKey<Object>('current-user');
    if (this._transferState.hasKey(USER_KEY)) {
      const user = this._transferState.get<Object>(USER_KEY, null);
      // console.log(user)
      this._transferState.remove(USER_KEY);
      this._currentUser = new User(user);
      this._currentUserSubject.next(this._currentUser);
      return of(this._currentUser);
    }
    if (this._refreshCurrentUser) {
      return this._refreshCurrentUser;
    }
    let headers = new HttpHeaders();
    let url = ""
    const csrfToken = this._cookieService.get('csrftoken');
    if(csrfToken){
      url = environment.apiURL + '/api/v1/user/current/'
    }
    this._refreshCurrentUser = this.http.get<Object>(
      url,
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genre
        if (!this._platform.isBrowser) {
          this._transferState.set(USER_KEY, res);
        }
        this._refreshCurrentUser = null;
      }, _ => {
        this._currentUserSubject.next(null);
        this._refreshCurrentUser = null;
      }),
      map((res) => {
      
        // console.log(res)
        this._currentUser = new User(res);
        this._currentUserSubject.next(this._currentUser);
        
        return this._currentUser;
      })
    );
  
    return this._refreshCurrentUser;
  }

  forgotPassword(email: string): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/forgot/',
      {
        email: email
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      return true;
    }));
  }

  resetPassword(uidb64: string, token: string, password: string): Observable<User> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/reset/',
      {
        password: password,
        token: token,
        uidb64: uidb64
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }
loginCheck(email: string, password: string, rememberMe: boolean): Observable<any>{
  let headers = new HttpHeaders();
  headers = headers.append("Content-Type", "application/json");
  var payLoad:any;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(regexEmail)) {
    payLoad =  {
      email: email,
     
    }
  } else {
    payLoad =  {
      username: email,
     
    }
  }
  return this.http.post<Object>(
    environment.apiURL + '/api/v1/admin/users/check-last-login/',
   payLoad,
    {
      headers: headers
    }
)
}
setPassword(email: string, password: string,){
  let headers = new HttpHeaders();
  headers = headers.append("Content-Type", "application/json");
  return this.http.put<Object>(
    environment.apiURL + '/api/v1/admin/users/set-new-password/',
    {
      email: email,
      password: password,
    },
    {
      headers: headers
    }
)
}
  loginUser(email: string, password: string, rememberMe: boolean): Observable<User> {
    // console.log(this.router.url)
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");

var payLoad:any;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(regexEmail)) {
    payLoad =  {
      email: email,
      password: password,
      rememberMe: rememberMe
    }
  } else {
    payLoad =  {
      username: email,
      password: password,
      rememberMe: rememberMe
    }
  }


    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/login/',
      payLoad,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      // if (res['is_client'] === false) {
      //   return null
      // }
     
      if(!res['is_client'] && !this.router.url.includes('playlist')){
        return null
      }
      
      if(this.nextState && !res['is_client'] && !this.nextState.includes('playlist')){
        // this.nextState = null
        return null
      }

      localStorage.setItem('user', JSON.stringify(res))
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));
  }

  registerUser(u: User, password: string, subscribe: boolean = true, message: string = '', portfolio_link: string = ''): Observable<User> {
    let data = u.toJSON();
    data['subscribe'] = subscribe;
    data['password'] = password;
    data['is_client'] = true
    data['shows'] = []
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/client/signup/',
      data,
      {
        headers: headers
      }
    ).pipe(map((res) => {
      this.loginUser(
        data.email,
        data.password,
        false
      )
        .subscribe(x => {
          this.getAuthToken(data.email,
            data.password,
            false).subscribe(x => {

            })
        })
      this._currentUser = new User(res);
      this._currentUserSubject.next(this._currentUser);
      return this._currentUser;
    }));

  }

  logout(): Observable<boolean> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/user/logout/',
      {
        headers: headers
      }
    ).pipe(
      map(() => {
        this._currentUser = null;
        this._currentUserSubject.next(this._currentUser);
        return true;
      })
    );
  }

  getCoWriter(email: string): Observable<TrackComposer> {
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/user/cowriters/',
      {
        params: { 'email': email },
        headers: headers
      }
    ).pipe(
      map((res) => new TrackComposer(res))
    );
  }
  getAboutDetails(): Observable<any> {
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/admin/users/about/',
    )
  }
  getAccount(){
    const csrfToken = this._cookieService.get('csrftoken');
    let url = ""
    if(csrfToken){
      url = environment.apiURL + '/api/v1/user/current/'
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      url,
      {
        headers: headers
      }
    )
  }
  public tokenSubject = new BehaviorSubject(null);
  token: any;
  getAuthToken(email: string, password: string, rememberMe: boolean): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    var payLoad:any;
    let regexEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  if (email.match(regexEmail)) {
    payLoad =  {
      email: email,
      password: password,
      rememberMe: rememberMe
    }
  } else {
    payLoad =  {
      username: email,
      password: password,
      rememberMe: rememberMe
    }
  }
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/token/',
      payLoad,
      {
        headers: headers
      }
    ).pipe(map((res: any) => {
      console.log("Login Response", res);
      this.tokenSubject.next(res.access)
      this.token = res.access
      localStorage.setItem("token", res.access);
      localStorage.setItem("refresh", res.refresh);
      let tok = JSON.parse(localStorage.getItem('user'))
      if(!tok.is_client){
        
        if(this.router.url.includes('playlist')){
          location.href = `https://qaweb.mibemusic.xyz/login?client=${res.access}&playlist=${this.user_id}`;
          // localStorage.clear();
        }
       
        
      }
     
     res['tok'] = tok
      return res;
    }));
  }
}
