import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Meta, Title } from '@angular/platform-browser';
import { PageTitleService } from '../shared/services/page-title.service';
import { Subscription } from 'rxjs';
import { UserService } from '../shared/services/user.service';
import { ConfirmPasswordStateMatcher } from '../account/account-profile/account-profile.component';
import { AnalyticsService } from '../shared/services/gtm/analytics.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit, OnDestroy {

  resetModel = {
    password: '',
    confirmPassword: ''
  };
  get errors(): string[] {
    return this._errors;
  }
  passwordInputType = 'password';
  confirmPasswordInputType = 'password';
  confirmPasswordMatcher: ConfirmPasswordStateMatcher;
  saving = false;

  private _errors: string[] = [];
  private _subscriptions: Subscription[] = [];
  private _uidb64: string;
  private _token: string;
  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _userService: UserService,
    private _title: Title,
    private _meta: Meta,
    private _pageTitleService: PageTitleService,
    private _analyticsService: AnalyticsService
  ) {
    this.confirmPasswordMatcher = new ConfirmPasswordStateMatcher(this.resetModel);
  }

  ngOnInit() {
    this._subscriptions.push(
      this._activatedRoute.queryParamMap.subscribe(params=> {
        this._uidb64 = params.get('uidb64');
        this._token = params.get('token');
        // console.log(this._uidb64)
      })
    );
    const title = this._pageTitleService.createPageTitle(['Reset Password']);
    this._title.setTitle(title);
    const description = this._pageTitleService.mainSiteDescription;
    this._meta.addTags([
      {name: 'description', content: description}
    ]);
    this._analyticsService.pageView();
  }

  ngOnDestroy() {
    this._subscriptions.forEach(s => s.unsubscribe());
    this._subscriptions = [];
  }

  togglePasswordInputType() {
    if (this.passwordInputType == 'password') {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }

  toggleConfirmPasswordInputType() {
    if (this.confirmPasswordInputType == 'password') {
      this.confirmPasswordInputType = 'text';
    } else {
      this.confirmPasswordInputType = 'password';
    }
  }

  resetPassword() {
    if (this.resetModel.password && this.resetModel.password != this.resetModel.confirmPassword) {
      return;
    }
    this._errors = [];
    this.saving = true;
    this._userService.resetPassword(this._uidb64, this._token, this.resetModel.password)
      .subscribe((u) => {
       
        this._userService.loginUser(u.email,this.resetModel.password,true).subscribe(x=>{
          this._userService.getAuthToken(
            u.email,
            this.resetModel.password,
            true
          )
          .subscribe(x=>{
            window.location.reload();
          })
        })
        this._router.navigate(['/']);
      }, () => {
        this._errors = [
          'There was an error resetting your password, you may need to get a new link if yours is expired!'
        ];
        this.saving = false;
      });
  }

}
