import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { LoginComponent } from '../login/login.component';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { RegisterComponent } from '../register/register.component';
import { ShareTrackComponent } from '../sharetrack/sharetrack.component';
import { SharePlaylistComponent } from '../shareplaylist/shareplaylist.component';
import { TrackInfoComponent } from '../trackinfo/trackinfo.component';
import { CreatePlaylistComponent } from '../createplaylist/createplaylist.component';
import { ChangeSubscriptionComponent } from '../change-subscription/change-subscription.component';
import { CompleteAccountComponent } from '../complete-account/complete-account.component';
import { DownloadDialogComponent } from '../downloaddialog/downloaddialog.component';
import { CreateTrackComponent } from '../create-track/create-track.component';
import { UploadVersionComponent } from '../upload-version/upload-version.component';
import { CreateAlbumComponent } from '../create-album/create-album.component';
import { VideoComponent } from '../video/video.component';
import { User } from '../models/user.model';
import { Track } from '../models/track.model';
import { TrackVersion } from '../models/trackversion.model';
import { Playlist } from '../models/playlist.model';
import { Subscription } from '../models/subscription.model';
import { Artist } from '../models/artist.model';
import { Album } from '../models/album.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DeleteplaylistComponent } from '../deleteplaylist/deleteplaylist.component';
import { ShareArtistComponent } from '../share-artist/share-artist.component';
import { ShareAlbumComponent } from '../share-album/share-album.component';
import { DisplayerrorpopComponent } from '../displayerrorpop/displayerrorpop.component';
import { DownloadprogressbarComponent } from '../downloadprogressbar/downloadprogressbar.component';
import { RatetrackComponent } from '../ratetrack/ratetrack.component';
import { DownloadPlaylistPopComponent } from '../download-playlist-pop/download-playlist-pop.component';
import { DownloadTrackComponent } from '../download-track/download-track.component';
import { DownloadAlertComponent } from '../download-alert/download-alert.component';
import { DeleteTrackComponent } from '../delete-track/delete-track.component';


@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    public dialog: MatDialog
  ) {
  }

  displayLoginForm(): Observable<User> {
    const dialog = this.dialog.open(LoginComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '465px'
    });
    dialog.componentInstance.openRegister.subscribe(() => {
      this.displayRegisterForm().subscribe(u => {
        dialog.close(u);
      });
    });

    dialog.componentInstance.openForgotPassword.subscribe(() => {
      this.displayForgotPasswordForm(dialog.componentInstance.loginModel.email);
    });
    return dialog.afterClosed();
  }

  displayForgotPasswordForm(email: string = null): Observable<User> {
    const dialog = this.dialog.open(ForgotPasswordComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '465px',
      data: {email: email}
    });
    return dialog.afterClosed();
  }

  displayRegisterForm(): Observable<User> {
    const dialog = this.dialog.open(RegisterComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '465px'
    });
    dialog.componentInstance.openLogin.subscribe(() => {
      this.displayLoginForm().subscribe(u => {
        dialog.close(u);
      });
    });
    const observable = dialog.afterClosed();
    observable.subscribe((regResults) => {
      if (regResults && regResults.registered && !regResults.isArtist) {
        this.displayCompleteAccount();
      }
    });
    return observable.pipe(
      map(r => {
        if (!r || r.id) {
          return r;
        }
        return r.user;
      })
    );
  }

  displayCompleteAccount(subscription_only: boolean = false): Observable<Subscription> {
    const dialog = this.dialog.open(CompleteAccountComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '704px',
      data: {subscription_only: subscription_only}
    });
    return dialog.afterClosed();
  }

  displayShareTrackForm(track: Track): Observable<void> {
    const dialog = this.dialog.open(ShareTrackComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {track: track}
    });
    return dialog.afterClosed();
  }

  displaySharePlaylistForm(playlist: Playlist): Observable<void> {
    const dialog = this.dialog.open(SharePlaylistComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {playlist: playlist}
    });
    return dialog.afterClosed();
  }
  displayShareArtistForm(playlist: Playlist): Observable<void> {
    const dialog = this.dialog.open(ShareArtistComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {playlist: playlist}
    });
    return dialog.afterClosed();
  }
  displayShareAlbumForm(playlist: Playlist): Observable<void> {
    const dialog = this.dialog.open(ShareAlbumComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {playlist: playlist}
    });
    return dialog.afterClosed();
  }
  displaydeleteplaylistForm(row): Observable<void> {
    const dialog = this.dialog.open(DeleteplaylistComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {content: row}
    });
    return dialog.afterClosed();
  }

  displaydeleteplaylistFormDowload(row): Observable<void> {
    console.log(row._original_data.approved_track_count)
    if(row._original_data.approved_track_count === 0){
      this.displaydisplayAlet('This playlist has no approved tracks',false)
      return
    }
   
    const dialog = this.dialog.open(DownloadPlaylistPopComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {content: row}
    });
    return dialog.afterClosed();
  }
  trackDowload(row): Observable<void> {
    const dialog = this.dialog.open(DownloadTrackComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {content: row}
    });
    return dialog.afterClosed();
  }
  displaydisplayerror(message,log): Observable<void> {
    const dialog = this.dialog.open(DisplayerrorpopComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {message : message,logout: log},
      disableClose: true
    });
    return dialog.afterClosed();
  }
  displaydisplayAlet(message,log): Observable<void> {
    const dialog = this.dialog.open(DownloadAlertComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {message : message,logout: log},
      disableClose: true
    });
    return dialog.afterClosed();
  }
  displayratetrack(track, version): Observable<void> {
    // console.log(track)
    const dialog = this.dialog.open(RatetrackComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {rate : track,version: version}
    });
    return dialog.afterClosed();
  }
  displayprogressbarForm(percentDone): Observable<void> {
    const dialog = this.dialog.open(DownloadprogressbarComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
     data: {percentDone : percentDone}
    });
    return dialog.afterClosed();
  }


  displayTrackInfo(track: any): Observable<void> {

    const dialog = this.dialog.open(TrackInfoComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {track: track}
    });
    return dialog.afterClosed();
  }

  displayDownloadDialog(type: 'track' | 'playlist', content: {track:Track, version:TrackVersion} | Playlist): Observable<void> {
    const dialog = this.dialog.open(DownloadDialogComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {type: type, content: content}
    });
    return dialog.afterClosed();
  }

  displayCreatePlaylist(playlistToDuplicate: Playlist = null, playlistToRename: Playlist = null): Observable<Playlist> {
    const dialog = this.dialog.open(CreatePlaylistComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      panelClass: 'no-padding-dialog',
      width: '465px',
      data: {playlist: playlistToDuplicate, playlistToRename: playlistToRename}
    });
    return dialog.afterClosed();
  }

  displayChangeSubscription(): Observable<Subscription> {
    const dialog = this.dialog.open(ChangeSubscriptionComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      panelClass: 'no-padding-dialog',
      width: '725px',
      data: {}
    });
    return dialog.afterClosed();
  }

  displayCreateTrack(track:Track = null, uploadCb:Function = null): Observable<{track:Track, uploadVersion:boolean}> {
    const dialog = this.dialog.open(CreateTrackComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '465px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {track: track}
    });
    const observable = dialog.afterClosed();
    observable.subscribe((result) => {
      if (result && result.uploadVersion) {
        this.displayUploadVersion(result.track, null, uploadCb);
      }
    });
    return observable;
  }

  displayUploadVersion(track:Track, version:TrackVersion = null, uploadCb:Function = null): Observable<void>  {
    const dialog = this.dialog.open(UploadVersionComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '465px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {track: track, version: version}
    });
    const observable = dialog.afterClosed();
    observable.subscribe((result) => {
      if (uploadCb) {
        uploadCb(result);
      }
    });
    return observable;
  }

  displayCreateAlbum(featuredArtists:Artist[], album:Album = null): Observable<Album>  {
    const dialog = this.dialog.open(CreateAlbumComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {album: album, featuredArtists: featuredArtists}
    });
    return dialog.afterClosed();
  }

  displayVideo(videoUrl:string=null) {
    const dialog = this.dialog.open(VideoComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '1000px',
      maxHeight: '100%',
      panelClass: ['no-padding-dialog', 'transparent-dialog-background'],
      data: {videoUrl: videoUrl}
    });
    return dialog.afterClosed();
  }

  // Delete track component
  deleteTrack(row, isFullVersion): Observable<void> {
    const dialog = this.dialog.open(DeleteTrackComponent, {
      autoFocus: true,
      closeOnNavigation: true,
      width: '708px',
      maxHeight: '100%',
      panelClass: 'no-padding-dialog',
      data: {content: row, fullVersion:isFullVersion}
    });
    return dialog.afterClosed();
  }
}
