import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
  HttpClient
} from '@angular/common/http';
import { TokenService } from '../services/token.service';
import { Observable, throwError } from 'rxjs';
import { catchError, first } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { UserService } from '../services/user.service';
import { DialogService } from '../services/dialog.service';
import { MatDialog } from '@angular/material';

const urlBlacklist = [
  environment.apiURL + '/api/v1/token/',
  environment.apiURL + '/api/v1/token/refresh/',
  environment.apiURL + '/api/v1/user/login/',

  environment.apiURL + 'api/v1/admin/genres/?is_featured=true',
  environment.apiURL + '/api/v1/menus/',
];

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  token = localStorage.getItem("token");
  count: number = 0;
  private _currentTokenObservable: Observable<string> = null;
  constructor(private _tokenService: TokenService,public inj: Injector, public UserService: UserService,    private http: HttpClient,private _dialogService: DialogService) {

  }
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    
    const auth = this.inj.get(UserService);
    if (this.token && this.token !== "null") {

      let expiry = this.token && (JSON.parse(atob(this.token.split('.')[1]))).exp;
      let currentTime = (Date.now()/1000); // returns time in milliseconds
      let timeToRefresh = (Date.now()/1000) +  250; //7200; // 2hs in secs

      console.log("----------------------------------")
      console.log("current Time       ", currentTime);
      console.log("expiry Time        ", expiry);
      console.log("time before refresh", timeToRefresh);
      console.log("----------------------------------")

      if (expiry < timeToRefresh && expiry > currentTime && this.count === 0) {
        let refreshT = localStorage.getItem("refresh");
        this.refreshAccessToken(refreshT).subscribe(
          (res) => {
            // console.log("refresh happend", res);

            localStorage.setItem("token", res.access);
            localStorage.setItem("refresh", res.refresh);
            
            // console.log("token got refreshed")
            // console.log("new access token", res);
            
            this.token = res.access;
            expiry = this.token && (JSON.parse(atob(this.token.split('.')[1]))).exp;
            this.count = 0;
          }, (err) => {
            console.log("ERROR in Refresh Token", err)
          }
        );
      }
      else if (expiry < currentTime) {
          const prevViewdUrl = location.href;
          auth.logout();
          debugger
          localStorage.setItem("token", null);
          localStorage.setItem("userdata", null);
          localStorage.clear();
          localStorage.setItem("PrevViewedURL", prevViewdUrl);
          // location.href = location.origin;
          this.token = null
          auth._currentUserSubject.next(null)
          this._dialogService.displayLoginForm().pipe(first()).subscribe(u => {
           
      });
        }
    }

    if(!this.token){
      return next.handle(request.clone());
    }
let url = request.url.match('https://s3.amazonaws.com/mibe-subscribe-dev/media/uploads/original_audio_files')
    if(url){
      return next.handle(request.clone());
    }
    if (urlBlacklist.indexOf(request.url) != -1) {
      return next.handle(request.clone());
    }
    // We use this construct with the current token observable to avoid duplicate refresh requests
    if (!this._currentTokenObservable) {
      this._currentTokenObservable = this._tokenService.getCurrentAccessToken();
    }
    return next.handle(
      request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.token}`
        }
      })
    )
   
  }

  refreshAccessToken(refreshToken: string): Observable<any> {
    this.count++;
    // let headers = new HttpHeaders();
    // headers = headers.append("Content-Type", "application/json");
    return this.http.post(
      environment.apiURL + '/api/v1/token/refresh/',
      {
        "refresh": refreshToken
      }
    );
  }
}
@Injectable()

export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(public inj: Injector,public UserService: UserService, public dialogpop: MatDialog) {

  }
  errorData: boolean = false;
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const auth = this.inj.get(UserService);
    const dialog = this.inj.get(DialogService);
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let errorMsg = '';
          if (error.error instanceof ErrorEvent) {
            errorMsg = `Error: ${error.error.message}`;
          }
          else {          
            if(error.status === 500){
              
              if(error.error.Error && !this.errorData){
                this.errorData = true
                this.dialogpop.closeAll();
                // if(this.dialogpop.openDialogs)
                dialog.displaydisplayerror(error.error.Error,true)
               
              }
             
             
              return
            }
            errorMsg = `Error Code: ${error.status},  Message: ${error.message}`;
          }
          return throwError(error);
        })
      )
  }
}
