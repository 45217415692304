import { MibeModel } from './mibemodel.model';

export class MenuItem implements MibeModel {
  id: number;
  text: string;
  url: string;
  is_local: boolean;

  /**
   * Create a HomePageSlide.
   * @param {any} json - The JSON object with which to construct our HomePageSlide.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a HomePageSlide.
   * @param {any} json - The JSON object with which to load our HomePageSlide.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.text = json.text;
    this.url = json.url;
    this.is_local = !!json.is_local;
  }
  /**
   * Returns a JSON representation of our HomePageSlide
   * @return {any} - The JSON representation of our HomePageSlide
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'text': this.text,
      'url': this.url,
      'is_local': this.is_local
    };
    return json;
  }
}
