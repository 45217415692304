import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
export interface DialogData {
  animal: string;
  name: string;
}
@Component({
  selector: 'app-ratetrack',
  templateUrl: './ratetrack.component.html',
  styleUrls: ['./ratetrack.component.scss']
})
export class RatetrackComponent implements OnInit {
  musicRate: number = 0;
  moodRate: number = 0;
  loading:boolean = false;
  rate1 = [
    {
    no: 1,
    Color: false
  },
  {
    no: 2,
    Color: false
  },
  {
    no: 3,
    Color: false
  },
  {
    no: 4,
    Color: false
  },
  {
    no: 5,
    Color: false
  },
]
rate2 = [
  {
  no: 1,
  Color: false
},
{
  no: 2,
  Color: false
},
{
  no: 3,
  Color: false
},
{
  no: 4,
  Color: false
},
{
  no: 5,
  Color: false
},
]
dialogueData:any;
  constructor(public dialogRef: MatDialogRef<RatetrackComponent>,public http: HttpClient,
    @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
    // console.log(this.data)
    this.dialogueData = this.data['rate']
    // console.log()
    let requestURL = environment.apiURL + `/api/v1/trackrating/${this.data['version'].id}/get-rating`
    this.http.get(requestURL).subscribe(x=>{
      let track = Number(x['rating'])
      let gm = Number(x['gm_rating'])
      this.rateTrack(track)
      this.rateTrack2(gm)
    })
  }
 
  rateTrack(rate){
    this.musicRate = rate;
    for (let index = 0; index < this.rate1.length; index++) {
     if(rate >= this.rate1[index].no){
       this.rate1[index].Color = true
     }       
     else{
      this.rate1[index].Color = false
     }
      
    }
   
   
  }

  rateTrack2(rate){
    this.moodRate = rate;
    for (let index = 0; index < this.rate2.length; index++) {
     if(rate >= this.rate2[index].no){
       this.rate2[index].Color = true
     }
     else{
      this.rate2[index].Color = false
     }
      
    }
    
  }
  submit(){
    let data = {
      track: this.data['version'].id,
    rating: this.musicRate,
    gm_rating:this.moodRate
  }
  this.loading = true;
  let requestURL = environment.apiURL + `/api/v1/trackrating/`
  this.http.post(requestURL,data).subscribe(x=>{
    this.loading = false;
    this.close()
  })
  }
  close() {
    this.dialogRef.close();
  }
}
