import {
  trigger,
  transition,
  query,
  style,
  group,
  animate
} from '@angular/animations';

export const matTabsRouterTransition = trigger('routerTransition', [
  transition(':decrement', [
    group([
      query(':enter', [
        style({ transform: 'translateX(-100%)', position: 'relative', display: 'block', width: '100%' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)', position: 'absolute', display: 'block', top: '0', left: '0', width: '100%' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(100%)', top: '0', left: '0', width: '100%' }))
      ], { optional: true }),
    ])
  ]),
  transition(':increment', [
    group([
      query(':enter', [
        style({ transform: 'translateX(100%)', position: 'relative', display: 'block', width: '100%' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(0%)' }))
      ], { optional: true }),
      query(':leave', [
        style({ transform: 'translateX(0%)', position: 'absolute', display: 'block', top: '0', left: '0', width: '100%' }),
        animate('0.5s ease-in-out', style({ transform: 'translateX(-100%)', top: '0', left: '0', width: '100%' }))
      ], { optional: true }),
    ])
  ])
]);
