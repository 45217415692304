import { Component, OnInit, OnDestroy, ViewChild, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../services/user.service';
import { ProductService } from '../services/product.service';
import { PaymentSourceService } from '../services/paymentsource.service';
import { SubscriptionService } from '../services/subscription.service';
import { Subscription as Sub } from '../models/subscription.model';
import { StripeService, StripeCardComponent, ElementOptions, ElementsOptions } from "ngx-stripe";
import { User } from '../models/user.model';
import { Product } from '../models/product.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { Subscription, forkJoin } from 'rxjs';

export interface DialogData {subscription_only: boolean}


@Component({
  selector: 'app-complete-account',
  templateUrl: './complete-account.component.html',
  styleUrls: ['./complete-account.component.scss']
})
export class CompleteAccountComponent implements OnInit, OnDestroy {

  @ViewChild(StripeCardComponent, {static: false})
  card: StripeCardComponent;

  loadingCurrentUser = true;
  loading = true;
  saving = false;
  error = '';
  hasPaymentSources = false;
  currentUser: User;
  products: Product[] = [];
  selectedProduct: Product = null;

  cardOptions: ElementOptions = {};

  elementsOptions: ElementsOptions = {
    locale: 'en'
  };

  private _subscriptions: Subscription[] = [];
  constructor(
    private _userService: UserService,
    private _productService: ProductService,
    private _paymentSourceService: PaymentSourceService,
    public dialogRef: MatDialogRef<CompleteAccountComponent>,
    private _stripeService: StripeService,
    private _subscriptionService: SubscriptionService,
    private _router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) { }

  ngOnInit() {
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
      this.loadingCurrentUser = false;
    }));
    this.loadData();
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
    this._subscriptions = [];
  }

  productTrackBy(i: number, product: Product) {
    return i + '-' + product.id;
  }

  loadData() {
    this.loading = true;
    forkJoin(
      this._productService.getAllProducts(),
      // this._paymentSourceService.getAllPaymentSources()
    ).subscribe(results => {
      this.loading = false;
      this.products = results[0];
      // this.hasPaymentSources = results[1].length > 0;
    });
  }

  close(subscription:Sub=null) {
    this.dialogRef.close(subscription);
    window.location.reload()
  }

  signUp() {
    this.saving = true;
    if (!this.hasPaymentSources) {
      this._stripeService.createToken(this.card.getCard(), {})
        .subscribe(result => {
          if (result.token) {
            this._paymentSourceService.createPaymentSource(result.token.id)
              .subscribe(_ => {
                this._signUp();
              });
          } else if (result.error) {
            // console.log(result.error);
            this.error = result.error.message;
            this.saving = false;
          }
        });
      return;
    }
    this._signUp();
  }

  private _signUp() {
    // TODO
    this._subscriptionService.createSubscription(this.selectedProduct.id).subscribe((s)=>{
      this.saving = false;
      this._router.navigate(['/account/subscription']);
      this.close(s);
    }, (response) => {
      // console.log(response);
      if (response && response.error && response.error.detail) this.error = response.error.detail;
      else this.error = "An Unexpected Error Occurred. Please Try Again Later.";
      this.saving = false;
    })

  }

}
