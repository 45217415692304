import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit {

  @Output()
  toggle = new EventEmitter<boolean>();

  @Input()
  isActive = false;

  constructor() { }

  ngOnInit() {
  }

  toggleActive() {
    this.isActive = !this.isActive;
    this.toggle.emit(this.isActive);
  }

}
