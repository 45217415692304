import {
  Component,
  OnInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter
 } from '@angular/core';
 import { Router } from '@angular/router';
 import {
   Observable,
   Subscription
 } from 'rxjs';
 import {
   trigger,
   style,
   animate,
   transition
 } from '@angular/animations';

 import { Playlist } from '../models/playlist.model';
 import { PlaylistService } from '../services/playlist.service';
import { User } from '../models/user.model';
import { UserService } from '../services/user.service';
import { DialogService } from '../services/dialog.service';
import { environment } from '../../../environments/environment';
import { HttpHeaders, HttpClient, HttpEventType } from '@angular/common/http';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { saveAs, JSZip } from 'file-saver';





 declare type PlaylistListDataSource = Observable<ReadonlyArray<Playlist>>;

@Component({
  selector: 'app-playlistlist',
  templateUrl: './playlistlist.component.html',
  styleUrls: ['./playlistlist.component.scss'],
  animations: [
    trigger('rowInOut', [
      transition(':enter', [
        style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          overflow: 'hidden',
          paddingBottom: '0'
        }),
        animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)', style({
          opacity: '1',
          height: '*'
        }))
      ]),
      transition(':leave', [
        style({
          opacity: '1',
          height: '*',
          overflow: 'hidden',
        }),
        animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)', style({
          opacity: '0',
          height: '0',
          paddingTop: '0',
          paddingBottom: '0'
        }))
      ])
    ])
  ]
})
export class PlaylistListComponent implements OnInit, OnDestroy {

  
  @Input()
  set dataSource(data: PlaylistListDataSource) {
    this._dataSource = data;
  }

  get listDataSource(): PlaylistListDataSource {
   
    return this._dataSource;
  }

  get apiURL(): string {
    return environment.apiURL;
  }

  @Output()
  deletePlaylist = new EventEmitter();

  currentUser: User;

  private _dataSource: PlaylistListDataSource;
  private _subscriptions: Subscription[] = [];

  constructor(
    private _playlistService: PlaylistService,
    private _userService: UserService,
    private _dialogService: DialogService,
    private _router: Router,
    public http: HttpClient,
    
  ) {}

  ngOnInit() {
    this._subscriptions.push(this._userService.currentUserStream.subscribe(u => {
      this.currentUser = u;
    }));
   
   
  }

  ngOnDestroy() {
    this._subscriptions.forEach((s) => {
      s.unsubscribe();
    });
  }

  listTrackBy(index, item: Playlist) {
    return item.id + '-' + item.name + '-' + item.track_count;
  }

  sharePlaylist(playlist: Playlist) {
    this._dialogService.displaySharePlaylistForm(playlist);
  }

  deleteplay(row){
    this._dialogService.displaydeleteplaylistForm(row);
  }
  

//   displayDownloadDialog(playlist: Playlist) {
//     if (this.currentUser) {
//       this._dialogService.displayDownloadDialog('playlist', playlist);
//       return;
//     }
//     this._dialogService.displayLoginForm().subscribe(u => {
//       if (u) {
//         this._dialogService.displayDownloadDialog('playlist', playlist);
//       }
//     });
//   }
downloadcue(playlist) {


  let requestURL = environment.apiURL + `/api/v1/playlists/${playlist.id}/cuesheet/`

  let headers = new HttpHeaders();
  this.http.get(`${requestURL}`, {
    responseType: 'text', headers: headers
  }
  ).subscribe(response => this.downLoadFile(playlist,response, "application/pdf"));


}
  downLoadFile(playlist, data: any, type: string) {
    let filename = `${playlist.name.trim()}.pdf`;
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let result = filename.substring(0,4);
    let tr = filename.substring(4);
    if(result.toLowerCase() === 'mibe'){
        filename = result.toUpperCase() + tr
    }
    else{
      filename = 'MIBE ' + filename
    }
    saveAs(url, filename);
  }
  dowloadWav(row){
// console.log(row)
  }
  percentDone:any;
  index:any;
  downloadPlaylistFlag:any;
  openDownloadPop(row){
    this.http.get(environment.apiURL + '/api/v1/user/current/').subscribe((x: User)=>{
        if(x.can_download){
          this.downloadPlaylistFlag = row
          this._dialogService.displaydeleteplaylistFormDowload(row)
        }
        else{
            this._dialogService.displaydisplayerror('Access Denied',false)
        }
      })
      

  }
 
  downloadFileZip(data: any, type: string, filename: any) {
  //   let fileName = `${filename}.zip`;
  //   let blob = new Blob([data], { type: 'application/audio'});
  //   let url = window.URL.createObjectURL(blob);
  //   saveAs(url, fileName);

  //   const blob1 = new Blob([data], { type: 'application/audio' });
  // var zip = new JSZip();
  // zip.file("Track.wav", blob1);
  // zip.generateAsync({ type: "blob" })
  //   .then(blob => saveAs(blob, 'file.zip'));
    // let pwa = window.open(url);
    // if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
    //     //alert( 'Please disable your Pop-up blocker and try again.');
    // }
  }
}
