import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Menu } from '../models/menu.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class MenuService {
  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { 
    this.getSubSlideOutMenu().subscribe(x=>{
      // console.log(x)
    })
  }

  getMainSlideOutMenu(): Observable<Menu> {
    return this.getMenu({location: '0'});
  }

  getSubSlideOutMenu(): Observable<Menu> {
    return this.getMenu({location: '1'});
  }

  getLeftFooterMenu(): Observable<Menu> {
    return this.getMenu({location: '2'});
  }

  getCenterFooterMenu(): Observable<Menu> {
    return this.getMenu({location: '3'});
  }

  getRightFooterMenu(): Observable<Menu> {
    return this.getMenu({location: '4'});
  }

  private getMenu(params: {[param: string]: string | string[]}): Observable<Menu> {
    let p = new HttpParams({ fromObject: params });
    // Check if menu is cached from server
    const MENU_KEY = makeStateKey<APIListResponse<Object>>('menus-' + p.toString());
    if (this._transferState.hasKey(MENU_KEY)) {
      const menu = this._transferState.get<APIListResponse<Object>>(MENU_KEY, null);
      this._transferState.remove(MENU_KEY);
      return of(menu.results.map((s) => new Menu(s)).shift());
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/menus/',
      {
        headers: headers,
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genres
        if (!this._platform.isBrowser) {
          this._transferState.set(MENU_KEY, res);
        }
      }),
      map((res) => {
        for(let x of res.results){
          if(params.location == x['location']){
            return new Menu(x)
          }
        }
        
      })
    );
  }
}
