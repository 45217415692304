import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Subscription } from '../models/subscription.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class SubscriptionService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) {
  }

  getCurrentSubscription(): Observable<Subscription> {
    // Check if subscription is cached from server
    const SUBSCRIPTION_KEY = makeStateKey<Object>('current-subscription');
    if (this._transferState.hasKey(SUBSCRIPTION_KEY)) {
      const subscription = this._transferState.get<Object>(SUBSCRIPTION_KEY, null);
      this._transferState.remove(SUBSCRIPTION_KEY);
      return of(new Subscription(subscription));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/user/subscription/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the genre
        if (!this._platform.isBrowser) {
          this._transferState.set(SUBSCRIPTION_KEY, res);
        }
      }),
      map((res) => new Subscription(res))
    );
  }

  createSubscription(product_id: number): Observable<Subscription> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/user/subscription/',
      {
        product_id: product_id
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Subscription(res)));
  }

  updateSubscription(product_id: number): Observable<Subscription> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/user/subscription/',
      {
        product_id: product_id
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Subscription(res)));
  }

  removeSubscription(): Observable<Subscription> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/user/subscription/',
      {
        headers: headers
      }
    ).pipe(map((res) => new Subscription(res)));
  }

}
