import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { AlbumTracks } from '../models/albumtracks.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

export interface GetAlbumTracksOptions {
  albumId: number;
  limit?: number;
  offset?: number;
}

@Injectable({
  providedIn: 'root'
})
export class AlbumTracksService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }

  getAlbumTracks(options: GetAlbumTracksOptions): Observable<APIListResponse<AlbumTracks>> {
    const params: {[param: string]: string | string[]} = {};
    if (options.albumId) {
      params['album_id'] = `${options.albumId}`;
    }
    if (options.limit) {
      params['limit'] = `${options.limit}`;
    }
    if (options.offset) {
      params['offset'] = `${options.offset}`;
    }
    // Check if album is cached from server
    const PLAYLIST_TRACKS_KEY = makeStateKey<APIListResponse<Object>>('albumtracks-' + options.albumId);
    if (this._transferState.hasKey(PLAYLIST_TRACKS_KEY)) {
      const albumTracks = this._transferState.get<APIListResponse<Object>>(PLAYLIST_TRACKS_KEY, null);
      this._transferState.remove(PLAYLIST_TRACKS_KEY);
      return of({
        next: albumTracks.next,
        previous: albumTracks.previous,
        count: albumTracks.count,
        results: albumTracks.results.map(pt => new AlbumTracks(pt))
      });
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse<Object>>(
      environment.apiURL + '/api/v1/albumtracks/',
      {
        headers: headers,
        params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_TRACKS_KEY, res);
        }
      }),
      map((res) => {
        for(let album of res.results){
          

            let sortTrack = []
            // console.log(track)
           
            for(let version of album['track']['versions']){
             
              if(version.is_full_version === true){
                sortTrack.unshift(version)
              }
              else{
                sortTrack.push(version)
              }
            }
            album['track']['versions'] = sortTrack;
    

          
        }
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map(pt => new AlbumTracks(pt))
        };
      })
    );
  }

  reorderTracks(albumTracks: AlbumTracks[]): Observable<Object> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/albumtracks/reorder/',
      {
        albumtracks: albumTracks.map(pt => { return {id: pt.id, order: pt.order}; })
      },
      {
        headers: headers
      }
    );
  }
}
