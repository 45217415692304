import { MibeModel } from './mibemodel.model';

export class SupportPage implements MibeModel {
  id: number;
  title: string;
  slug: string;
  content: string;

  /**
   * Create a TrackType.
   * @param {any} json - The JSON object with which to construct our TrackType.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a TrackType.
   * @param {any} json - The JSON object with which to load our TrackType.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.title = json.title;
    this.slug = json.slug;
    this.content = json.content;
  }
  /**
   * Returns a JSON representation of our TrackType
   * @return {any} - The JSON representation of our TrackType
   */
  toJSON() {
    var json: any = {
      'id': this.id,
      'title': this.title,
      'slug': this.slug,
      'content': this.content
    };
    return json;
  }
}
