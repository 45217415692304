import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { NgScrollbarModule } from 'ngx-scrollbar';

import { NgxStripeModule } from 'ngx-stripe';

import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatDialogModule } from '@angular/material/dialog';
import { CdkTableModule } from '@angular/cdk/table';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { LayoutModule } from '@angular/cdk/layout';
import { PlatformModule } from '@angular/cdk/platform';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule, MatTableModule } from '@angular/material';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';

import { ClipboardModule } from 'ngx-clipboard';

import { DurationPipe, TruncatePipe } from './pipes/duration.pipe';

import { HeaderComponent } from './header/header.component';
import { HamburgerComponent } from './hamburger/hamburger.component';
import { GenreMoodPickerComponent } from './genremoodpicker/genremoodpicker.component';
import { FooterComponent } from './footer/footer.component';
import { SliderComponent } from './slider/slider.component';
import { MenuComponent } from './menu/menu.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { RegisterComponent } from './register/register.component';
import { ShareTrackComponent } from './sharetrack/sharetrack.component';
import { TrackInfoComponent } from './trackinfo/trackinfo.component';
import { GenrecoverComponent } from './genrecover/genrecover.component';
import { PlaylistcoverComponent } from './playlistcover/playlistcover.component';
import { PlaylistPopoverComponent } from './playlistpopover/playlistpopover.component';
import { TracklistComponent } from './tracklist/tracklist.component';
import { MusicplayerComponent } from './musicplayer/musicplayer.component';

import { CSRFInterceptor } from './interceptors/csrf.interceptor';
import { LoaderComponent } from './loader/loader.component';
import { WaveformComponent } from './waveform/waveform.component';
import { PlaylistPopoverTriggerForDirective } from './directives/playlistpopovertriggerfor/playlistpopovertriggerfor.directive';
import { AlbumPopoverTriggerForDirective } from './directives/albumpopovertriggerfor/albumpopovertriggerfor.directive';
import { PageMarqueeComponent } from './pagemarquee/pagemarquee.component';
import { PaginationComponent } from './pagination/pagination.component';
import { PageFiltersComponent } from './pagefilters/pagefilters.component';
import { TracksPageComponent } from './trackspage/trackspage.component';
import { SearchComponent } from './search/search.component';
import { CreatePlaylistComponent } from './createplaylist/createplaylist.component';
import { PlaylistListComponent } from './playlistlist/playlistlist.component';
import { SharePlaylistComponent } from './shareplaylist/shareplaylist.component';
import { TracksPageListComponent } from './tracks-page-list/tracks-page-list.component';
import { ArtistAlbumTracksComponent } from './artist-album-tracks/artist-album-tracks.component';
import { ChangeSubscriptionComponent } from './change-subscription/change-subscription.component';
import { CompleteAccountComponent } from './complete-account/complete-account.component';
import { DownloadDialogComponent } from './downloaddialog/downloaddialog.component';
import { UploadVersionComponent } from './upload-version/upload-version.component';
import { CreateTrackComponent } from './create-track/create-track.component';
import { CreateAlbumComponent } from './create-album/create-album.component';
import { AlbumPopoverComponent } from './albumpopover/albumpopover.component';
import { VideoComponent } from './video/video.component';
import { PrevNextArrowComponent } from './prev-next-arrow/prev-next-arrow.component';
import { TokenInterceptor, HttpErrorInterceptor } from './interceptors/token.interceptor';
import { MatSelectFilterModule } from 'mat-select-filter';
import { DeleteplaylistComponent } from './deleteplaylist/deleteplaylist.component';
import { ShareArtistComponent } from './share-artist/share-artist.component';
import { ShareAlbumComponent } from './share-album/share-album.component';
import { DisplayerrorpopComponent } from './displayerrorpop/displayerrorpop.component';
import { DownloadprogressbarComponent } from './downloadprogressbar/downloadprogressbar.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';

import { NgCircleProgressModule } from 'ng-circle-progress';
import { DragTestComponent } from './drag-test/drag-test.component';
import { RatetrackComponent } from './ratetrack/ratetrack.component';
import { AngularWavesurferServiceModule } from 'angular-wavesurfer-service';
import { WaveNewComponent } from './wave-new/wave-new.component';
import { DownloadPlaylistPopComponent } from './download-playlist-pop/download-playlist-pop.component';
import { SAVER, getSaver } from './services/saver.provider';
import { DownloadTrackComponent } from './download-track/download-track.component';
import { DownloadAlertComponent } from './download-alert/download-alert.component';
import { DeleteTrackComponent } from './delete-track/delete-track.component';

@NgModule({
  declarations: [
    HeaderComponent,
    GenreMoodPickerComponent,
    HamburgerComponent,
    FooterComponent,
    SliderComponent,
    MenuComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    ShareTrackComponent,
    TrackInfoComponent,
    GenrecoverComponent,
    PlaylistcoverComponent,
    PlaylistPopoverComponent,
    TracklistComponent,
    DurationPipe,
    TruncatePipe,
    MusicplayerComponent,
    LoaderComponent,
    WaveformComponent,
    PlaylistPopoverTriggerForDirective,
    AlbumPopoverTriggerForDirective,
    PageMarqueeComponent,
    PaginationComponent,
    PageFiltersComponent,
    TracksPageComponent,
    SearchComponent,
    CreatePlaylistComponent,
    PlaylistListComponent,
    SharePlaylistComponent,
    TracksPageListComponent,
    ArtistAlbumTracksComponent,
    ChangeSubscriptionComponent,
    CompleteAccountComponent,
    DownloadDialogComponent,
    UploadVersionComponent,
    CreateTrackComponent,
    CreateAlbumComponent,
    AlbumPopoverComponent,
    VideoComponent,
    PrevNextArrowComponent,
    DeleteplaylistComponent,
    ShareArtistComponent,
    ShareAlbumComponent,
    DisplayerrorpopComponent,
    DownloadprogressbarComponent,
    DragTestComponent,
    RatetrackComponent,
    WaveNewComponent,
    DownloadPlaylistPopComponent,
    DownloadTrackComponent,
    DownloadAlertComponent,
    SliderComponent,
    DeleteTrackComponent
  ],
  entryComponents: [
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    CompleteAccountComponent,
    ShareTrackComponent,
    SharePlaylistComponent,
    TrackInfoComponent,
    PlaylistPopoverComponent,
    CreatePlaylistComponent,
    ChangeSubscriptionComponent,
    DownloadDialogComponent,
    CreateTrackComponent,
    UploadVersionComponent,
    CreateAlbumComponent,
    AlbumPopoverComponent,
    VideoComponent,
    DeleteplaylistComponent,
    ShareArtistComponent,
    ShareAlbumComponent,
    DisplayerrorpopComponent,
    DownloadprogressbarComponent,
    RatetrackComponent,
    DownloadPlaylistPopComponent,
    DownloadTrackComponent,
    DownloadAlertComponent,
    SliderComponent,
    DeleteTrackComponent
  ],
  imports: [
    CommonModule,
    MatInputModule,
    MatCheckboxModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatMenuModule,
    MatTabsModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule,
    CdkTableModule,
    DragDropModule,
    OverlayModule,
    LayoutModule,
    PlatformModule,
    MatDialogModule,
    RouterModule,
    ClipboardModule,
    NgxStripeModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    NgScrollbarModule,
    NgxMatSelectSearchModule,
    MatSelectFilterModule,
    NgxMatSelectSearchModule,
    MatProgressBarModule,
    MatTableModule,
    AngularWavesurferServiceModule,
    NgCircleProgressModule.forRoot({
      radius: 100,
      outerStrokeWidth: 16,
      innerStrokeWidth: 8,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
    })
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SliderComponent,
    GenrecoverComponent,
    GenreMoodPickerComponent,
    DownloadprogressbarComponent,
    PlaylistcoverComponent,
    LoginComponent,
    ForgotPasswordComponent,
    RegisterComponent,
    TracklistComponent,
    LoaderComponent,
    MusicplayerComponent,
    PageMarqueeComponent,
    PageFiltersComponent,
    PaginationComponent,
    TracksPageComponent,
    PlaylistListComponent,
    TracksPageListComponent,
    ArtistAlbumTracksComponent,
    DurationPipe,
    TruncatePipe,
    MatSelectModule,
    MatInputModule,
    MatMenuModule,
    MatTabsModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    NgxStripeModule,
    MatDatepickerModule,
    NgxMatSelectSearchModule,
    MatSelectFilterModule,
    MatProgressBarModule,
    DisplayerrorpopComponent,
    AngularWavesurferServiceModule,
    PlaylistPopoverComponent,
    MatDialogModule,
    SliderComponent
  ],
  providers: [
   
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {provide: SAVER, useFactory: getSaver}
  ]
})
export class SharedModule { }
