import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { Platform } from '@angular/cdk/platform';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Artist } from '../models/artist.model';
import { Album } from '../models/album.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AlbumService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState,
  ) { }

  favoriteAlbum(album: Album): Observable<Album> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/albums/' + album.id + '/favorite/',
      {},
      {
        headers: headers
      }
    ).pipe(map((res) => {
      album.loadWithJSON(res);
      return album;
    }));
  }

  unfavoriteAlbum(album: Album): Observable<Album> {
    let headers = new HttpHeaders();
    return this.http.delete<Object>(
      environment.apiURL + '/api/v1/albums/' + album.id + '/unfavorite/',
      {
        headers: headers
      }
    ).pipe(map((res) => {
      album.loadWithJSON(res);
      return album;
    }));
  }

  getFavoriteAlbums(): Observable<Album[]> {
    let params = {is_favorite:'true'};
    return this.getAlbums(params);
  }

  getEditableAlbums(artist:Artist = null, check_for_track:number = null): Observable<Album[]> {
    let params = {is_editable:'true'};
    if (artist) {
      params['artist'] = artist.id;
    }
    if (check_for_track != null) {
      params['contains_track_id'] = `${check_for_track}`;
    }
    return this.getAlbums(params);
  }

  updateAlbum(album:Album, file:File = null): Observable<Album> {
    let formData = this._albumToFormData(album, file);
    let headers = new HttpHeaders();
    return this.http.patch<any>(
      environment.apiURL + '/api/v1/albums/' + album.id + '/',
      formData,
      {
        headers: headers
      }
    ).pipe(map((res)=> new Album(res)));
  }

  createAlbum(artistId:number, album:Album, file:File = null): Observable<Album> {
    let formData = this._albumToFormData(album, file);
    formData.append('artist', artistId.toString());
    let headers = new HttpHeaders();
    return this.http.post<Object>(
      environment.apiURL + '/api/v1/albums/',
      formData,
      {
        headers: headers
      }
    ).pipe(map((res) => new Album(res)));
  }

  addTracksToAlbum(albumId: number, trackIds: number[]): Observable<Album> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/albums/' + albumId + '/addtracks/',
      {
        trackIds: trackIds
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Album(res)));
  }

  removeTracksFromAlbum(albumId: number, trackIds: number[]): Observable<Album> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/albums/' + albumId + '/removetracks/',
      {
        headers: headers,
        params: {
          trackIds: trackIds.join(',')
        }
      }
    ).pipe(map((res) => new Album(res)));
  }

  private getAlbums(params: {[param: string]: string | string[]}): Observable<Album[]> {
    let p = new HttpParams({ fromObject: params });
    // Check if albums are cached from server
    const ALBUMS_KEY = makeStateKey<APIListResponse<Object>>('albums-' + p.toString());
    if (this._transferState.hasKey(ALBUMS_KEY)) {
      const albums = this._transferState.get<APIListResponse<Object>>(ALBUMS_KEY, null);
      this._transferState.remove(ALBUMS_KEY);
      return of(albums.results.map((s) => new Album(s)));
    }
    let headers = new HttpHeaders();
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/albums/',
      {
        headers: headers,
        params: p
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the albums
        if (!this._platform.isBrowser) {
          this._transferState.set(ALBUMS_KEY, res);
        }
      }),
      map((res) => {
         return res.results.map((a) => new Album(a));
      })
    );
  }

  private _albumToFormData(album:Album, file:File=null):FormData {
    let form = new FormData();
    if (file) form.append('image', file);
    if (album) {
      if (album.name) form.append('name', album.name);
      if (album.released_at) form.append('released_at', album.released_at.toISOString());
      if (album.description) form.append('description', album.description);
      form.append('is_active', album.is_active ? 'true':'false');
    }
    return form;
  }
}
