import { Injectable } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Platform } from '@angular/cdk/platform';
import { environment } from '../../../environments/environment';
import { Playlist } from '../models/playlist.model';
import { APIListResponse } from '../models/apiresponse.model';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { map, tap } from 'rxjs/operators';


export interface GetPlaylistsOptions {
  ordering?: string;
  search?: string;
  page?: string;
}

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {

  constructor(
    private http: HttpClient,
    private _platform: Platform,
    private _transferState: TransferState
  ) { }
  public playlistload = new BehaviorSubject(null);
  public deleteLoad = this.playlistload.asObservable();

  public playlistloadDownload = new BehaviorSubject(null);
  public deleteLoadDownload = this.playlistloadDownload.asObservable();

  public trackDownloadSubject = new BehaviorSubject(null);
  public trackDownload = this.trackDownloadSubject.asObservable();

  public PlaylistRemoveSubject = new BehaviorSubject(null);
  public PlaylistRemove = this.PlaylistRemoveSubject.asObservable();

  public addRemovePlaylistSubject = new BehaviorSubject(null);
  public currentPlaylistData = this.addRemovePlaylistSubject.asObservable()
  
  public isSharedplaylist:Boolean = false; //This flag is used to detect whether the playlist is shared or not
  public currentPlaylistID:number;

  getPlaylist(playlistId: number): Observable<Playlist> {
    // Check if playlist is cached from server
    const PLAYLIST_KEY = makeStateKey<APIListResponse<Object>>('playlist-' + playlistId);
    if (this._transferState.hasKey(PLAYLIST_KEY)) {
      const playlist = this._transferState.get<APIListResponse<Object>>(PLAYLIST_KEY, null);
      this._transferState.remove(PLAYLIST_KEY);
      return of(new Playlist(playlist));
    }
    let headers = new HttpHeaders();
    return this.http.get<Object>(
      environment.apiURL + '/api/v1/playlists/' + playlistId + '/',
      {
        headers: headers
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLIST_KEY, res);
        }
      }),
      map((res) => new Playlist(res))
    );
  }

  getFeaturedPlaylists(limit: number=null): Observable<APIListResponse<Playlist>> {

    return this.getPlaylists({
      'is_featured': 'true',
      'limit': (limit!==null? limit.toString():'3')
    });
  }

  getEditablePlaylists(check_for_track_version: number = null, options: GetPlaylistsOptions = null, type:string=''): Observable<APIListResponse<Playlist>> {
    let params: { [param: string]: string | string[]; } = {
      'is_editable': 'true'
    };
    if (options) {
      if (options.ordering) {
        params['ordering'] = options.ordering;
      }
      if (options.search) {
        params['search'] = options.search;
      }
      if (options.page) {
        params['page'] = options.page;
      }
    }
    if (check_for_track_version != null) {
      params['contains_track_version_id'] = `${check_for_track_version}`;
    }
    
    if(type =='track-client'){
    return this.getPlaylistsNew(params);
    }
    else{
      return this.getPlaylists(params);
    }
  }

  getSharedPlaylists(options: GetPlaylistsOptions = null): Observable<APIListResponse<Playlist>> {
    const params = {
      'is_shared': 'true'
    };
    if (options) {
      if (options.ordering) {
        params['ordering'] = options.ordering;
      }
      if (options.search) {
        params['search'] = options.search;
      }
      if (options.page) {
        params['page'] = options.page;
      }
    }
    return this.getPlaylists(params);
  }

  sharePlaylistViaEmail(playlist: Playlist, emails: string[], message: string): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/playlists/' + playlist.id + '/shareemail/',
      {
        'emails': emails,
        'message': message
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      playlist.loadWithJSON(res);
      return playlist;
    }));
  }
  shareArtistViaEmail(playlist: Playlist, emails: string[], message: string): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/artists/' + playlist.id + '/shareemail/',
      {
        'emails': emails,
        'message': message
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      playlist.loadWithJSON(res);
      return playlist;
    }));
  }
  shareAlbumViaEmail(playlist: Playlist, emails: string[], message: string): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/albumtracks/' + playlist.id + '/shareemail/',
      {
        'emails': emails,
        'message': message
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
     
      return res;
    }));
  }

  duplicatePlaylist(playlist: Playlist, name: string): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/playlists/' + playlist.id + '/duplicate/',
      {
        'name': name
      },
      {
        headers: headers
      }
    ).pipe(map((res) => {
      let playlist = new Playlist(res);
      return playlist;
    }));
  }

  createPlaylist(name: string, description: string = ''): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/playlists/',
      {
        name: name,
        description: description
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Playlist(res)));
  }

  updatePlaylist(playlist: Playlist): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.put<any>(
      environment.apiURL + '/api/v1/playlists/' + playlist.id + '/',
      playlist.toJSON(),
      {
        headers: headers
      }
    ).pipe(map((res) => new Playlist(res)));
  }

  download(playlist):any {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.get<any>(
      environment.apiURL + '/api/v1/playlists/' + playlist + '/cuesheet/',
      {
        headers: headers
      }
    );
  }

  deletePlaylist(playlistId: number): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/playlists/' + playlistId + '/',
      {
        headers: headers
      }
    );
  }

  addTrackVersionsToPlaylist(playlistId: number, trackVersionIds: number[]): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.post<any>(
      environment.apiURL + '/api/v1/playlists/' + playlistId + '/addtrackversions/',
      {
        trackVersionIds: trackVersionIds
      },
      {
        headers: headers
      }
    ).pipe(map((res) => new Playlist(res)));
  }

  removeTrackVersionsFromPlaylist(playlistId: number, trackVersionIds: number[]): Observable<Playlist> {
    let headers = new HttpHeaders();
    headers = headers.append("Content-Type", "application/json");
    return this.http.delete<any>(
      environment.apiURL + '/api/v1/playlists/' + playlistId + '/removetrackversions/',
      {
        headers: headers,
        params: {
          trackVersionIds: trackVersionIds.join(',')
        }
      }
    ).pipe(map((res) => new Playlist(res)));
  }

  downloadPlaylist(playlistId: number): Observable<{download_url:string}> {
    let headers = new HttpHeaders();
    return this.http.get<any>(
      environment.apiURL + '/api/v1/playlists/' + playlistId + '/download/',
      {
        headers:headers
      }
    ).pipe(map((res)=>{
      return res;
    }));
  }

  private getPlaylists(params: {[param: string]: string | string[]}): Observable<APIListResponse<Playlist>> {
    let p = new HttpParams({ fromObject: params });
    // Check if playlists are cached from server
    const PLAYLISTS_KEY = makeStateKey<APIListResponse<Object>>('playlists-' + p.toString());
    if (this._transferState.hasKey(PLAYLISTS_KEY)) {
      const playlists = this._transferState.get<APIListResponse<Object>>(PLAYLISTS_KEY, null);
      this._transferState.remove(PLAYLISTS_KEY);
      return of({
        next: playlists.next,
        previous: playlists.previous,
        count: playlists.count,
        results: playlists.results.map((p) => new Playlist(p))
      });
    }
    let headers = new HttpHeaders();
    // console.log(params)
    let url;
    url = '/api/v1/playlists/'
   
    return this.http.get<APIListResponse>(
      environment.apiURL + url,
      {
        headers: headers,
        params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLISTS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((p) => new Playlist(p))
        };
      })
    );
  }
  private getPlaylistsNew(params: {[param: string]: string | string[]}): Observable<APIListResponse<Playlist>> {
    let p = new HttpParams({ fromObject: params });
    // Check if playlists are cached from server
    const PLAYLISTS_KEY = makeStateKey<APIListResponse<Object>>('playlists-' + p.toString());
    if (this._transferState.hasKey(PLAYLISTS_KEY)) {
      const playlists = this._transferState.get<APIListResponse<Object>>(PLAYLISTS_KEY, null);
      this._transferState.remove(PLAYLISTS_KEY);
      return of({
        next: playlists.next,
        previous: playlists.previous,
        count: playlists.count,
        results: playlists.results.map((p) => new Playlist(p))
      });
    }
    let headers = new HttpHeaders();
    // console.log(params)
    return this.http.get<APIListResponse>(
      environment.apiURL + '/api/v1/playlists/clientplaylist/',
      {
        headers: headers,
        params: params
      }
    ).pipe(
      tap(res => {
        // If we're on the server cache the moods
        if (!this._platform.isBrowser) {
          this._transferState.set(PLAYLISTS_KEY, res);
        }
      }),
      map((res) => {
        return {
          next: res.next,
          previous: res.previous,
          count: res.count,
          results: res.results.map((p) => new Playlist(p))
        };
      })
    );
  }

  // Delete playlist track
  deletePlaylistTrack(trackId: number, fullVersion:boolean): Observable<void> {
    let headers = new HttpHeaders();
    return this.http.delete<any>(
      `${environment.apiURL}/api/v1/playlists/${this.currentPlaylistID}/removetrackversions/?trackVersionIds=${trackId}&is_remove_versions=${fullVersion}`,
      {
        headers: headers
      }
    );
  }
}
