import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  HostBinding
} from '@angular/core';
import {
  trigger,
  style,
  query,
  animate,
  stagger,
  transition,
  group
} from '@angular/animations';
import { Subscription } from 'rxjs';
import { GenreService } from '../services/genre.service';
import { MoodService } from '../services/mood.service';
import { map } from 'rxjs/operators';


export interface PickerOption {
  label: string;
  value: Object;
}
export enum PickerType {
  Genre = 1,
  SubGenre,
  Mood
}

@Component({
  selector: 'app-genremoodpicker',
  templateUrl: './genremoodpicker.component.html',
  styleUrls: ['./genremoodpicker.component.scss'],
  animations: [
    trigger('optionsInOut', [
      transition('* => *', [
        query(':enter', [
          style({
            opacity: '0',
            transform: 'scale(0.5)'
          }),
          stagger('50ms', [
            animate('0.3s ease-in-out', style({
              opacity: '1',
              transform: 'scale(1)'
            }))
          ])
        ], { optional: true })
      ])
    ]),
    trigger('pickerInOut', [
      transition(':enter', [
        style({
          opacity: '0'
        }),
        query('.picker', [
          style({
            opacity: '0',
            transform: 'translateY(-100%)'
          })
        ]),
        group([
          animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)'),
          query('.picker', [
            animate('0.7s cubic-bezier(0.19, 1, 0.22, 1)')
          ])
        ])
      ]),
      transition(':leave', [
        style({'overflow': 'hidden'}),
        group([
          animate('0.7s cubic-bezier(0.78, 0, 0.81, 0)', style({
            opacity: '0'
          })),
          query('.picker', [
            animate('0.7s cubic-bezier(0.78, 0, 0.81, 0)', style({
              transform: 'translateY(-100%)'
            }))
          ])
        ])
      ])
    ])
  ]
})
export class GenreMoodPickerComponent implements OnInit {

  @HostBinding('@pickerInOut')
  public animatePicker = true;

  @Input()
  set pickerType(t: PickerType) {
    this._pickerType = t;
    switch (t) {
      case PickerType.Genre:
        this.title = 'Select a Genre';
        break;
      case PickerType.SubGenre:
        this.title = 'Select a SubGenre';
        break;
      case PickerType.Mood:
        this.title = 'Select a Mood';
        break;
    }
    this.loadOptions();
  }
  get pickerType(): PickerType {
    return this._pickerType;
  }

  @Input()
  set selectedItemId(id: number) {
    this._selectedItemId = id;
  }
  get selectedItemId(): number {
    return this._selectedItemId;
  }

  @Input()
  set parentGenreId(id: number) {
    this._parentGenreId = id;
    this.loadOptions();
  }
  get parentGenreId(): number {
    return this._parentGenreId;
  }

  @Output()
  optionSelected = new EventEmitter<PickerOption>();

  options: PickerOption[] = [];
  title: string = 'Select a Genre';
  loading = true;

  list: number[] = [];

  private _pickerType: PickerType = PickerType.Genre;
  private _parentGenreId: number;
  private _selectedItemId: number;
  private _getOptionsSubscription: Subscription;

  constructor(
    private _genreService: GenreService,
    private _moodService: MoodService
  ) { }

  ngOnInit() {
  }

  optionClicked(option: PickerOption) {
    this.optionSelected.emit(option);
  }

  optionsTrackBy(i: number, option: PickerOption) {
    return option.value['id'] + option.label;
  }

  private loadOptions() {
    switch (this.pickerType) {
      case PickerType.Genre:
        this.loadGenres();
        break;
      case PickerType.SubGenre:
        this.loadSubGenres();
        break;
      case PickerType.Mood:
        this.loadMoods();
        break;
      default:
        this.options = [];
    }
  }
  private loadGenres() {
    this.loading = true;
    if (this._getOptionsSubscription && !this._getOptionsSubscription.closed) {
      this._getOptionsSubscription.unsubscribe();
    }
    this._getOptionsSubscription = this._genreService.getParentGenres().pipe(
      map(genres => genres.map(g => { return {label: g.name, value: g}; }))
    ).subscribe(options => {
      this.loading = false;
      this.options = options;
    });
  }
  private loadSubGenres() {
    if (!this.parentGenreId) {
      return;
    }
    this.loading = true;
    if (this._getOptionsSubscription && !this._getOptionsSubscription.closed) {
      this._getOptionsSubscription.unsubscribe();
    }
    this._getOptionsSubscription = this._genreService.getSubGenres(this.parentGenreId).pipe(
      map(genres => genres.map(g => { return {label: g.name, value: g}; }))
    ).subscribe(options => {
      this.loading = false;
      this.options = options;
      this._genreService._currentlyBrowsingSubGenreSubject.next(this.options)
    });
  }
  private loadMoods() {
    this.loading = true;
    let moodSubscription = this._moodService.getAllMoods();
    if (this.parentGenreId) {
      moodSubscription = this._moodService.getAvailableMoodsForGenre(this.parentGenreId);
    }
    if (this._getOptionsSubscription && !this._getOptionsSubscription.closed) {
      this._getOptionsSubscription.unsubscribe();
    }
    this._getOptionsSubscription = moodSubscription.pipe(
      map(moods => moods.map(m => { return {label: m.name, value: m}; }))
    ).subscribe(options => {
      this.loading = false;
      this.options = options;
    });
  }

}
