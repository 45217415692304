import { MibeModel } from './mibemodel.model';

export class Message implements MibeModel {
  id: number;
  url: string;
  title: string;
  message: string;
  unread: boolean;
  created_at: Date;

  /**
   * Create a Mood.
   * @param {any} json - The JSON object with which to construct our Album.
   */
  constructor(json:any) {
    this.loadWithJSON(json);
  }
  /**
   * Load a Mood.
   * @param {any} json - The JSON object with which to load our Album.
   */
  loadWithJSON(json: any) {
    if (!json) {
      return;
    }
    this.id = json.id;
    this.url = json.url;
    this.title = json.title;
    this.message = json.message;
    this.unread = json.unread;
    if (json.created_at) {
      this.created_at = new Date(json.created_at);
    }
  }
  /**
   * Returns a JSON representation of our Album
   * @return {any} - The JSON representation of our Album
   */
  toJSON() {
    let json = {};
    json['id'] = this.id;
    json['url'] = this.url;
    json['title'] = this.title;
    json['message'] = this.message;
    json['unread'] = this.unread;
    if (this.created_at) {
      json['created_at'] = this.created_at.toJSON();
    }
    return json;
  }
}
